import React, { useEffect } from 'react'
import PageContentContainer from './PageContentContainer'
import Flexbox from './Flexbox'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import styled from 'styled-components'
import anime from 'animejs'
import reactimg from '../images/react.svg'
import node from '../images/node.svg'
import mongodb from '../images/mongodb2.svg'
const Nuc2 = styled.div`
    width: 60px;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: ${({ color }) => color};
    background: ${({ color }) => `radial-gradient(hsla(${color}, 100%, 50%, 0%),hsla(${color}, 100%, 50%, 0%) 50%,hsla(${color}, 100%, 50%, 70%) 90%,hsla(${color}, 100%, 50%, 100%))`};
    border-radius: 50%;
    flex-shrink: 0;
    opacity: 0;
    img {
        position: relative;
        height: 100%;
        width: 100%;
    }
    /* background: radial-gradient(rgba(var(--formBlue2Raw), 0),rgba(var(--formBlue2Raw), 1)); */
`;

const Nucleodide = styled.div`
    position: relative;
    animation: 2.1s cubic-bezier(0.42 , 0, 0.58, 1);
    width: ${({ size }) => `${size * 2}px`};
    height: ${({ size }) => `${size * 5}px`};
    display: inline-block;
    /* background: #ff0000; */
    &:before, &:after
    {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: ${({ size }) => `${size}px`};
        height: ${({ size }) => `${size}px`};
        margin-left: ${({ marginLeft }) => marginLeft};
        background: ${({ color }) => color};
        border-radius: 50%;
    }
    &:after{
        margin-left: ${({ marginLeft2 }) => marginLeft2};
    }
    /* span{
        position: absolute;
        top: 7px;
        bottom: -15px;
        left: 50%;
        width: 1px;
        z-index: -2;
    } */
`;

const Line = styled.line`
position: relative;
flex-grow: 1;
height: 2px;
background: linear-gradient(90deg,rgba(var(--formBlue2Raw), 0),rgba(var(--formBlue2Raw), 1), rgba(var(--formBlue2Raw), 0));
margin: 0 20px;
transform: scaleX(0);
`;

const GridContainer2 = styled.div`
    display: grid;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-items: center;
`;

const Helix = () => {
    const helix = Array(15).fill(0)
    const rowOffset = 6
    const waveFunction = (helixLength, index) => {
        return Math.round(Math.abs(2 * 380 * Math.sin(Math.PI * 1.45 * (index) / helixLength - Math.PI / 6)))
        // return Math.round(Math.abs(2 * 380 * Math.sin((helixLength - index) / helixLength * (Math.PI * 1.6) )))
    }

    const imgGroup = [
        { img: reactimg, tech: 'React' },
        { img: node, tech: 'Node' },
        { img: mongodb, tech: 'MongoDB' },
    ]
    useEffect(() => {
        anime({
            targets: '.nucleotide',
            opacity: 1,
            duration: 500,
            delay: function (el, i, l) {
                return i * 500 / helix.length;
            },
        })
        anime({
            targets: 'line',
            scaleX: '100%',
            duration: 2000,
            startDelay: 1000,
            delay: function (el, i, l) {
                return i * 2000 / helix.length;
            },
        })
    }, [])
    return (
        <PageContentContainer
            minHeight="100vh"
            margin="calc(var(--navBarHeight) * -1) 0 0"
            height="100%"
            column
            color="#fff"
            background="#000"
            // background="linear-gradient(var(--darkBlue),var(--deepBlue))"
            >
            <GridContainer2 column position="relative" height="100%" width="100vw" justifyItems="center">
                {
                    helix.map((it, index) => {
                        let width = waveFunction(helix.length, index)
                        return (
                            <>
                                <GridItem align="center" display="flex" justifyContent="center" height="70px" width={`${width > 70 ? width : 60}px`} col="1 / span 1" row={index + 1}>
                                    <Nuc2
                                        color={(201 - 128) / helix.length * (helix.length - index) + 128}
                                        // color={`hsl(${360 / helix.length * (helix.length - index)}, 100%, 50%)`}
                                        className="nucleotide">
                                        {
                                            (index > rowOffset && index < ((rowOffset + 1) + imgGroup.length)) && <img src={imgGroup[index - (rowOffset + 1)]['img']} />
                                        }
                                    </Nuc2>
                                    {
                                        width > 70 &&
                                        <>
                                            <Flexbox width="100%" height="100%" justifyContent="center">
                                                <Line />
                                                {
                                                    (index > rowOffset && index < ((rowOffset + 1) + imgGroup.length)) &&
                                                    <>
                                                        <Flexbox>{imgGroup[index - (rowOffset + 1)]['tech']}</Flexbox>
                                                        <Line />
                                                    </>
                                                }
                                                {index === 4 &&
                                                    <>
                                                        <Flexbox position="relative" fontSize="33px">
                                                            eCommerce
                                                        </Flexbox>
                                                        <Line />
                                                    </>
                                                }
                                            </Flexbox>
                                            <Nuc2
                                                // color={`hsl(${360 / helix.length * (helix.length - index)}, 100%, 50%)`}
                                                // color={360 / helix.length * (helix.length - index)}
                                                color={(201 - 128) / helix.length * (helix.length - index) + 128}
                                                className="nucleotide">
                                                {
                                                    (index > rowOffset && index < ((rowOffset + 1) + imgGroup.length)) && <img src={imgGroup[index - (rowOffset + 1)]['img']} />
                                                }
                                            </Nuc2>
                                        </>
                                    }
                                </GridItem>
                            </>
                        )
                    })
                }
                <>
                    <GridItem fontSize="22px" display="flex" align="center" position="relative" row={`${rowOffset} / span 3`} zIndex="1" col="1 / span 1" width={`calc(${waveFunction(helix.length, rowOffset)}px - 70px * 2)`}>
                        Customized a Shopify site to allow for region-specific product sales. Developed a product recommendation quiz to provide visitors with personalized results.
                    </GridItem>
                </>

            </GridContainer2>
            <Flexbox column position="relative" height="100vh" width="100vw">
                {
                    helix.map((it, index) => {
                        const marginLeft = `${100 * Math.cos((helix.length - index) / helix.length * (Math.PI * 2))}px`
                        const marginLeft2 = `${-1 * 100 * Math.cos((helix.length - index) / helix.length * (Math.PI * 2))}px`
                        // console.log("marginLeft", marginLeft)
                        return (<Nucleodide size={20}
                            color={`hsl(${360 / helix.length * (helix.length - index)}, 100%, 50%)`}
                            marginLeft={marginLeft}
                            marginLeft2={marginLeft2}
                        // marginTop={-`${(index + 1) * 5 / 2}px`}
                        >
                            <div style={{ height: '2px', background: '#fff', margin: 'auto 0', position: 'relative', top: `${20 / 2}px`, width: '100%' }}></div>
                            {/* <span /> */}
                        </Nucleodide>)
                    })
                }
            </Flexbox>

        </PageContentContainer>
    )
}

export default Helix