import React, { useState, useEffect, useRef } from "react";
import Flexbox from "../Flexbox";
import styled from "styled-components";
import anime from "animejs";
import nextArrow from "../../images/navArrow2.svg";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";

const Span = styled.span`
  white-space: nowrap;
  position: absolute;
`;
const IntegratedForm = () => {
  const animationPlayed = useRef(false);
  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    },
    true
  );
  //   useEffect(() => {
  //     anime({
  //       targets: "#dreamingOfContent",
  //       update: function () {
  //         document.querySelector("#dreamingOfContent").innerHTML = "Mobile";
  //       },
  //     });
  //   }, []);
  //   useEffect(() => {
  //     var tl = anime
  //       .timeline({
  //         easing: "linear",
  //         duration: 3000,
  //         //   autoplay: false,
  //         loop: true,
  //         //   direction: 'alternate',
  //       })
  //       .add({
  //         targets: ".dreamingOf span",
  //         keyframes: [
  //             {opacity: 0},
  //             {opacity:1},
  //             {opacity:0},
  //           ],
  //         easing: "linear",

  //         delay: anime.stagger(2500, {start: 0}),
  //       })

  //     tl.play();
  //     if (isVisible) tl.play();
  //   }, [isVisible]);
  useEffect(() => {
    anime.set(".dreamingOf span", { opacity: 0 });
    anime({
      targets: ".dreamingOf span",
      keyframes: [
        { opacity: 0, duration: 50 },
        { opacity: 1 },
        { opacity: 0, delay: 1000 },
      ],
      easing: "linear",
      loop: true,
      duration: 4000,
      delay: anime.stagger(4000, { start: 0 }),
    });
  }, []);
  return (
    <Flexbox>
      <Flexbox
        color="#fff"
        width="100%"
        className="dreamingOf"
        refID={containerRef}
        justifyContent="center"
      >
        <h1>
          I'm dreaming of building{" "}
          <div
            style={{
              position: "relative",
              display: "inline",
              textIndent: "8px",
            }}
          >
            <Span>a mobile app</Span>
            <Span>a website</Span>
            <Span>something else</Span>
          </div>
        </h1>
        <img src={nextArrow} height="30px" width="30px" />
      </Flexbox>
    </Flexbox>
  );
};

export default IntegratedForm;
