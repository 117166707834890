import React, { useEffect, useState, useRef } from 'react'
import Flexbox from '../Flexbox'
import { useNavigate, useLocation } from 'react-router-dom'
import GridC from '../GridContainer'
import { GridContainer } from './Navigation.styles'
import Dropdown from '../Dropdown'
import { fixSvgElementCoordinates } from '../../utils'
import anime from 'animejs'
import logo1 from '../../images/logo-1.png'
import logo2 from '../../images/logo-2.png'
import logo3 from '../../images/logo-3.png'
import logo4 from '../../images/logo-4.png'
import logo5 from '../../images/logo-5.png'
import logo6 from '../../images/logo-6.png'
import logo7 from '../../images/logo-7.png'
import logo8 from '../../images/logo-8.png'
import logo9 from '../../images/logo-9.png'
import logo10 from '../../images/logo-10.png'
import logo11 from '../../images/logo-11.png'
import logo12 from '../../images/logo-12.png'
import logo13 from '../../images/logo-13.png'
import logo14 from '../../images/logo-14.png'
import logo15 from '../../images/logo-15.png'
import logo16 from '../../images/logo-16.png'
import logo17 from '../../images/logo-17.png'
import logo18 from '../../images/logo-18.png'
import logo19 from '../../images/logo-19.png'
import logo20 from '../../images/logo-20.png'
import logo21 from '../../images/logo-21.png'

const Navigation = () => {
    const gridContainerRef = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const [showDropdown, setShowDropdown] = useState(false)

    useEffect(() => {
        if (gridContainerRef.current) {
            fixSvgElementCoordinates('.hamburgerMenu svg .upperLine', 0, 2, 5, 4)
            fixSvgElementCoordinates('.hamburgerMenu svg .lowerLine', 0, 67, 5, 67)
        }

    }, [gridContainerRef.current])
    // useEffect(() => { 

    // }, [showDropdown])
    const links = ['Home', 'Contact', 'Get Started', 'About',
        // 'Portfolios'
    ]
    // const links = ['Contact', 'About', 'Get Started']
    // const fillColor = location.pathname === '/' || location.pathname.includes('company') ? "#000" : '#fff'
    const fillColor = (defaultColor = 'var(--formBlue1)') => !location.pathname.includes('privacy') && !location.pathname.includes('terms')
        && !location.pathname.includes('do-not-sell-my-info') && !showDropdown ? "#fff" : defaultColor
    if (location.pathname.includes('form'))
        return (
            <GridContainer height="var(--navBarHeight)" padding="var(--navBarPadding)" zIndex="3" relative boxShadow="none" background="transparent">
                <Flexbox color="#fff" className='closeOrRemoveTag' onClick={() => navigate('/')} fontWeight={location.pathname === '/' || location.pathname.includes('company') ? 'var(--navBarFontWeightActive)' : undefined}>
                    &#10006;</Flexbox>

            </GridContainer>
        )
    return (
        <GridContainer ref={gridContainerRef} height="var(--navBarHeight)" gridTemp={{ cols: 'min-content 1fr min-content' }} padding="var(--navBarPadding)"
            // boxShadow={location.pathname === '/'  || location.pathname.includes('company') ? "var(--navBarBoxShadow)" : "none" }
            // boxShadow="var(--navBarBoxShadow)" 
            zIndex="3" relative >
            <Flexbox

                // fontFamily="'Crimson Text', serif"
                // fontFamily="'Amiri', serif"
                // fontFamily="'Lusitana', serif"
                // fontFamily="'Playfair Display', serif"
                fontWeight={'var(--navBarFontWeightActive)'}
                // fontWeight={location.pathname === '/' ? 'var(--navBarFontWeightActive)' : undefined}
                onClick={() => navigate('/')}
                width="min-content"
                zIndex="5"
                color={fillColor()}
                cursor="pointer"
                whiteSpace={"nowrap"}
                fontSize="19px"
            // lineHeight="1em"
            >
                {/* <img src={logo7} height="20px" width="19px" objectFit="contain" style={{marginBottom: '2px'}} /> */}
                <img src={logo20}
                    height="35px"
                    // height="55px" 
                    width="auto"
                    style={{
                        marginBottom: '2px',
                        maxWidth: '10vw',
                        maxHeight: '100%',
                        objectFit: 'contain'
                    }} />
                <span
                    // className={location.pathname === '/'  || location.pathname.includes('company') ? "gradientText" : ''}
                    style={{
                        margin: '0 0 0 .2em', fontWeight: '700',
                        // fontFamily: 'Poiret One', textTransform: "uppercase"
                    }}
                >Best Software Devs</span></Flexbox>
            {/* <Flexbox
                className="desktopMenu"
                gridCol="2"
                justifySelf="end"
                cursor="pointer"
                padding="10px"
                borderRadius="3px"
                fontWeight="700"
                color="var(--formBlue1)"
                fontSize="12px"
                height="min-content"
                alignSelf="center"
                boxShadow="0 0 5px rgba(0,0,0,0.35)"
                background="#ffffff"
                onClick={() => {
                    navigate('/contact')
                }}>Contact Us</Flexbox>
            <Flexbox className="desktopMenu" gridCol="3" gap="20px"
                justifySelf="end" alignSelf="center">

                <Flexbox
                    whiteSpace="nowrap"
                    gridCol="3"
                    justifySelf="end"
                    cursor="pointer"
                    padding="10px"
                    borderRadius="3px"
                    fontWeight="700"
                    color="#fff"
                    fontSize="12px"
                    height="min-content"
                    alignSelf="center"
                    boxShadow="0 0 5px rgba(0,0,0,0.35)"
                    background="var(--formBackgroundAngled)"
                    onClick={() => {
                        navigate('/form')
                    }}>
                    Connect to a Development Team</Flexbox>
            </Flexbox> */}
            <Flexbox column alignItems="end" className="hamburgerMenu" justifyContent="center" alignContent="end" justifyItems="end"
                onClick={() => {
                    let bodyEl = document.querySelector("body");
                    if (!showDropdown) {
                        bodyEl.style.overflow = "hidden";
                    } else {
                        bodyEl.style.overflow = "visible";
                    }
                    setShowDropdown(!showDropdown);
                }}>

                <svg viewBox="0 0 100 80" width="20px" height="20px" style={{
                    zIndex: '6'
                }}>
                    <rect y="30" width="90" x="5" height="10" style={{ fill: showDropdown ? "none" : fillColor() }}></rect>
                    <rect className="upperLine" width="90" height="10" x="5" style={{ transform: showDropdown ? "rotate(45deg)" : 'none', fill: showDropdown ? '#ffffff' : fillColor() }}></rect>
                    <rect className="lowerLine" y="60" width="90" x="5" height="10" style={{ transform: showDropdown ? "rotate(-45deg)" : 'none', fill: showDropdown ? '#ffffff' : fillColor() }}></rect>
                </svg>
                <Flexbox position="fixed"
                    overflow="hidden"
                    zIndex="5"
                    // height="100vh"
                    // height={window.innerHeight + 'px'}
                    height={showDropdown ? window.innerHeight + 'px' : '0px'}
                    transition={`0.5s height `}
                    width="100%" left="0" top="0" alignItems="start" flexDirection="row"  flexDirectionMobile="column">
                    {
                        links.map((it, index) => {
                            //color={(360 - 128) / helix.length * (helix.length - index) + 128}
                            const path = it === 'Get Started' ? 'form' : it === 'Home' ? "/" : it.replace(' ', '-').toLowerCase()
                            return (<Flexbox
                                color="#fff"
                                cursor="pointer"
                                overflow={showDropdown ? "visible" : "hidden"}
                                transition={`0.5s height ${0.1 * index}s,0.5s background`}
                                fontSize="33px"
                                className={showDropdown ? "showDropdown hamburgerMenuOption" : "hamburgerMenuOption"}
                                fontFamily="'Poiret One'"
                                justifyContent="center"
                                bgdHover={`hsla(${(235 - 217) / links.length * (index + 1) + 217},100%,0%, 100%)`}
                                onClick={() => navigate(path)}
                                background={`hsla(${(235 - 217) / links.length * (index + 1) + 217},100%,22%, 90%)`} width="100%"><span style={{ fontFamily: 'inherit' }}>{it}</span></Flexbox>)
                        })
                    }
                </Flexbox>
                {/* {showDropdown &&
                    <Flexbox position="fixed" height="100%" width="100%" right="0" top="0" zIndex="5">
                        <Dropdown
                            label='hamburger'
                            headerHeight="49px"
                            fontSize="1.3rem"
                            width="100%"
                            itemHeight="min-content"
                            itemPadding="20px 50px"
                            height="100%"
                            justifyContentList="end"
                            maxHeight={'100%'}
                            showHeader={false}
                            name={'Suggested Tags'}
                            marginBottom={'1rem'}
                            options={[
                                { value: 'Home', innerText: 'Home', onClick: () => navigate('/') },
                                ...links.map(it => {
                                    const path = it === 'Get Started' ? 'form' : it.replace(' ', '-').toLowerCase()
                                    return ({ value: it, innerText: it, onClick: () => { navigate(path) } })
                                })]}
                            menuState={showDropdown}
                            handleStateChange={() => setShowDropdown(!showDropdown)}
                            valueX={[]}
                            id={'suggested-tags'}
                            padding="0 0 100px"
                            setValidContinue={() => { }}
                            justifyContent="end"
                            textAlign="left"
                        ></Dropdown>
                    </Flexbox>} */}

            </Flexbox>
        </GridContainer>
    )
}

export default Navigation