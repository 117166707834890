import styled from 'styled-components'

export const SVG = styled.svg`
fill: ${(fill) => fill};
transition: all 1.5s ease-in-out;
max-width: ${({ maxWidth }) => maxWidth};
path {
    stroke-linejoin: round;
    cursor: pointer;
}
.selected{
    fill: rgba(255,255,255,0.25) !important;
    transition: all 0.35s ease-in-out;
    
}
`;