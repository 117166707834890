import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import anime from 'animejs'


const SVG = styled.svg`
height: 100%;
max-width: 345px;
width: 31vw;
position: relative;
.hiw-step1-1{letter-spacing:-.02em;}
.hiw-step1-2{stroke:#ccc;}
.hiw-step1-2,.hiw-step1-3,.hiw-step1-4,.hiw-step1-5{stroke-miterlimit:10;}
.hiw-step1-2,.hiw-step1-3,.hiw-step1-6{fill:none;}
.hiw-step1-2,.hiw-step1-3,.hiw-step1-5{stroke-width:4px;}
.hiw-step1-7,.hiw-step1-8,.hiw-step1-9,.hiw-step1-10,.hiw-step1-4{fill:#fff;}
.hiw-step1-11{fill:url(#hiw-step1-linear-gradient-13);}
.hiw-step1-11,.hiw-step1-12,.hiw-step1-13,.hiw-step1-14,.hiw-step1-15,.hiw-step1-16,.hiw-step1-17,.hiw-step1-18{opacity:.44;}
.hiw-step1-19{fill:url(#hiw-step1-linear-gradient-17);}
.hiw-step1-12{fill:url(#hiw-step1-linear-gradient-19);}
.hiw-step1-20{fill:url(#radial-gradient-9);}
.hiw-step1-20,.hiw-step1-21,.hiw-step1-22,.hiw-step1-23,.hiw-step1-24,.hiw-step1-25,.hiw-step1-26,.hiw-step1-27,.hiw-step1-28,.hiw-step1-29,.hiw-step1-30,.hiw-step1-31{opacity:.75;}
.hiw-step1-32,.hiw-step1-33,.hiw-step1-27{fill:#7da2fe;}
.hiw-step1-21{fill:url(#radial-gradient);}
.hiw-step1-34{letter-spacing:0em;}
.hiw-step1-35{letter-spacing:0em;}
.hiw-step1-36{fill:url(#hiw-step1-linear-gradient-28);}
.hiw-step1-37{fill:#2c75fb;}
.hiw-step1-13{fill:url(#hiw-step1-linear-gradient-18);}
.hiw-step1-38{fill:url(#hiw-step1-linear-gradient-20);}
.hiw-step1-39{fill:url(#hiw-step1-linear-gradient-6);}
.hiw-step1-22{fill:url(#radial-gradient-10);}
.hiw-step1-23{fill:url(#radial-gradient-11);}
.hiw-step1-40{letter-spacing:-.02em;}
.hiw-step1-24{fill:url(#radial-gradient-4);}
.hiw-step1-41{fill:url(#hiw-step1-linear-gradient-32);}
.hiw-step1-42{fill:url(#hiw-step1-linear-gradient-11);}
.hiw-step1-25{fill:url(#radial-gradient-8);}
.hiw-step1-14{fill:url(#hiw-step1-linear-gradient-2);}
.hiw-step1-43{letter-spacing:0em;}
.hiw-step1-44{letter-spacing:0em;}
.hiw-step1-15{fill:url(#hiw-step1-linear-gradient-3);}
.hiw-step1-45{fill:url(#hiw-step1-linear-gradient-10);}
.hiw-step1-46{fill:url(#hiw-step1-linear-gradient-16);}
.hiw-step1-47{fill:url(#hiw-step1-linear-gradient-31);}
.hiw-step1-33{opacity:.5;}
.hiw-step1-16{fill:url(#hiw-step1-linear-gradient-15);}
.hiw-step1-3{stroke-linecap:round;}
.hiw-step1-3,.hiw-step1-4{stroke:#fff;}
.hiw-step1-3,.hiw-step1-48{opacity:.25;}
.hiw-step1-8{font-size:21px;}
.hiw-step1-8,.hiw-step1-9{font-family:AlbertSans-ExtraBold, 'Albert Sans';}
.hiw-step1-49{fill:url(#hiw-step1-linear-gradient-21);}
.hiw-step1-26{fill:url(#radial-gradient-6);}
.hiw-step1-50{fill:black;fill-opacity:0.01;filter: url(#outer-shadow);opacity:0.25;}
.hiw-step1-51{fill:url(#hiw-step1-linear-gradient-25);}
.hiw-step1-52{fill:url(#hiw-step1-linear-gradient-5);}
.hiw-step1-53{letter-spacing:-.04em;}
.hiw-step1-54{letter-spacing:-.02em;}
.hiw-step1-9{font-size:14px;}
.hiw-step1-10{opacity:.2;}
.hiw-step1-55{fill:url(#hiw-step1-linear-gradient-34);}
.hiw-step1-4{filter:url(#outer-glow-1);fill:rgba(255,255,255,0.2);}
.hiw-step1-17{fill:url(#hiw-step1-linear-gradient-12);}
.hiw-step1-56{fill:url(#hiw-step1-linear-gradient-7);}
.hiw-step1-5{fill:rgba(249,249,249,.15);stroke:rgba(249,249,249,.75);}
.hiw-step1-57{fill:#ccc;}
.hiw-step1-28{fill:url(#radial-gradient-5);}
.hiw-step1-58{fill:#1e83f7;}
.hiw-step1-59{letter-spacing:-.02em;}
.hiw-step1-60{fill:url(#hiw-step1-linear-gradient-24);}
.hiw-step1-61{fill:url(#hiw-step1-linear-gradient-9);}
.hiw-step1-62{fill:url(#hiw-step1-linear-gradient-22);}
.hiw-step1-63{letter-spacing:0em;}
.hiw-step1-64{fill:#2280f8;}
.hiw-step1-65{fill:url(#hiw-step1-linear-gradient);}
.hiw-step1-66{fill:url(#hiw-step1-linear-gradient-27);}
.hiw-step1-67{letter-spacing:0em;}
.hiw-step1-68{fill:url(#hiw-step1-linear-gradient-33);}
.hiw-step1-69{fill:url(#hiw-step1-linear-gradient-29);}
.hiw-step1-70{fill:url(#hiw-step1-linear-gradient-30);}
.hiw-step1-71{fill:url(#hiw-step1-linear-gradient-23);}
.hiw-step1-72{letter-spacing:-.1em;}
.hiw-step1-73{fill:url(#hiw-step1-linear-gradient-4);}
.hiw-step1-29{fill:url(#radial-gradient-7);}
.hiw-step1-74{fill:url(#hiw-step1-linear-gradient-8);}
.hiw-step1-18{fill:url(#hiw-step1-linear-gradient-14);}
.hiw-step1-30{fill:url(#radial-gradient-3);}
.hiw-step1-31{fill:url(#radial-gradient-2);}
.hiw-step1-75{fill:url(#hiw-step1-linear-gradient-26);}
@media only screen and (max-width: 550px),
        screen and (max-device-width: 550px) {
            /* height: 30vh; */
            width: 100%;
            max-height: 50vh;
        }

`

const AnimatedStep1 = ({ className }) => {
    return (
        <SVG id="Layer_2" xmlns="http://www.w3.org/2000/svg" className={className.replace('.', '')}  viewBox="0 0 462.19 935.45">
            <defs>

                <linearGradient id="hiw-step1-linear-gradient" x1="-80.5" y1="780.32" x2="544.7" y2="155.13" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#386aff" />
                    <stop offset="1" stopColor="#1b86f6" />
                </linearGradient>
                <linearGradient id="hiw-step1-linear-gradient-2" x1="149.04" y1="253.38" x2="158.38" y2="253.38" />
                <linearGradient id="hiw-step1-linear-gradient-3" x1="120.86" y1="253.38" x2="130.2" y2="253.38" />
                <linearGradient id="hiw-step1-linear-gradient-4" x1="162.9" y1="226.74" x2="167.43" y2="226.74" />
                <linearGradient id="hiw-step1-linear-gradient-5" x1="162.9" y1="237.76" x2="167.43" y2="237.76" />
                <linearGradient id="hiw-step1-linear-gradient-6" x1="168.41" y1="232.25" x2="172.94" y2="232.25" />
                <linearGradient id="hiw-step1-linear-gradient-7" x1="157.4" y1="232.25" x2="161.92" y2="232.25" />
                <radialGradient id="radial-gradient" cx="343.86" cy="240.9" fx="343.86" fy="240.9" r="2.92" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f6f6f6" />
                    <stop offset="1" stopColor="#52a2f6" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="hiw-step1-linear-gradient-8" x1="315.82" y1="408.93" x2="315.82" y2="372.5" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fff" stopOpacity=".2" />
                    <stop offset="1" stopColor="#fff" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="hiw-step1-linear-gradient-9" x1="1659.93" y1="408.93" x2="1659.93" y2="372.5" gradientTransform="translate(1960.23) rotate(-180) scale(1 -1)" />
                <linearGradient id="hiw-step1-linear-gradient-10" x1="300.3" y1="417.89" x2="300.3" y2="381.46" />
                <linearGradient id="hiw-step1-linear-gradient-11" x1="1644.42" y1="417.89" x2="1644.42" y2="381.46" gradientTransform="translate(1960.23) rotate(-180) scale(1 -1)" />
                <linearGradient id="hiw-step1-linear-gradient-12" x1="292.74" y1="410.25" x2="308.23" y2="417.31" />
                <linearGradient id="hiw-step1-linear-gradient-13" x1="307.37" y1="391.99" x2="308.67" y2="407.51" />
                <radialGradient id="radial-gradient-2" cx="-2399.64" cy="755.91" fx="-2399.64" fy="755.91" r="47.91" gradientTransform="translate(1175.65 142.72) scale(.36)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#5aa6f6" />
                    <stop offset=".53" stopColor="#1b86f6" />
                    <stop offset="1" stopColor="#386aff" />
                </radialGradient>
                <linearGradient id="hiw-step1-linear-gradient-14" x1="367.74" y1="413.69" x2="353.61" y2="413.69" />
                <linearGradient id="hiw-step1-linear-gradient-15" x1="351.54" y1="391.99" x2="352.83" y2="407.51" />
                <radialGradient id="radial-gradient-3" cx="4440.44" cy="755.92" fx="4440.44" fy="755.92" r="47.91" gradientTransform="translate(1935.64 142.72) rotate(-180) scale(.36 -.36)" />
                <radialGradient id="radial-gradient-4" cx="-2365.31" cy="772.78" fx="-2365.31" fy="772.78" r="4.73" gradientTransform="translate(1175.65 142.72) scale(.36)" />
                <linearGradient id="hiw-step1-linear-gradient-16" x1="1806.87" y1="417.94" x2="1820.27" y2="417.94" gradientTransform="translate(2151.47) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#8cc0f6" />
                    <stop offset="1" stopColor="#1b86f6" />
                </linearGradient>
                <linearGradient id="hiw-step1-linear-gradient-17" x1="315.69" y1="417.12" x2="331.92" y2="417.12" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset=".14" stopColor="#8cc0f6" />
                    <stop offset=".39" stopColor="#1b86f6" />
                    <stop offset=".65" stopColor="#1b86f6" />
                    <stop offset=".77" stopColor="#8cc0f6" />
                    <stop offset=".79" stopColor="#6db0f6" />
                    <stop offset=".82" stopColor="#4fa1f6" />
                    <stop offset=".85" stopColor="#3895f6" />
                    <stop offset=".88" stopColor="#278cf6" />
                    <stop offset=".92" stopColor="#1e87f6" />
                    <stop offset=".96" stopColor="#1b86f6" />
                </linearGradient>
                <radialGradient id="radial-gradient-5" cx="-2320.23" cy="757.84" fx="-2320.23" fy="757.84" r="4.73" gradientTransform="translate(1175.65 142.72) scale(.36)" />
                <radialGradient id="radial-gradient-6" cx="-2391.18" cy="757.84" fx="-2391.18" fy="757.84" r="4.73" gradientTransform="translate(1175.65 142.72) scale(.36)" />
                <radialGradient id="radial-gradient-7" cx="-2391.18" cy="757.84" fx="-2391.18" fy="757.84" r="9.26" gradientTransform="translate(1175.65 142.72) scale(.36)" />
                <linearGradient id="hiw-step1-linear-gradient-18" x1="108.8" y1="400.22" x2="120.3" y2="400.22" />
                <linearGradient id="hiw-step1-linear-gradient-19" x1="120.3" y1="385.38" x2="131.8" y2="385.38" />
                <linearGradient id="hiw-step1-linear-gradient-20" x1="117.15" y1="395.87" x2="123.45" y2="395.87" />
                <linearGradient id="hiw-step1-linear-gradient-21" x1="105.65" y1="404.57" x2="111.94" y2="404.57" />
                <linearGradient id="hiw-step1-linear-gradient-22" x1="128.66" y1="374.88" x2="134.95" y2="374.88" />
                <linearGradient id="hiw-step1-linear-gradient-23" x1="140.16" y1="378.02" x2="146.45" y2="378.02" />
                <linearGradient id="hiw-step1-linear-gradient-24" x1="151.66" y1="359.55" x2="157.96" y2="359.55" />
                <linearGradient id="hiw-step1-linear-gradient-25" x1="163.17" y1="366.99" x2="169.46" y2="366.99" />
                <radialGradient id="radial-gradient-8" cx="121.69" cy="727.56" fx="121.69" fy="727.56" r="4.81" />
                <radialGradient id="radial-gradient-9" cx="153.15" cy="729.53" fx="153.15" fy="729.53" r="4.81" />
                <radialGradient id="radial-gradient-10" cx="144.57" cy="720.69" fx="144.57" fy="720.69" r="4.81" />
                <radialGradient id="radial-gradient-11" cx="139.34" cy="713.07" fx="139.34" fy="713.07" r="4.77" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f6f6f6" />
                    <stop offset=".21" stopColor="#d0e2f6" stopOpacity=".77" />
                    <stop offset=".6" stopColor="#8dc0f6" stopOpacity=".36" />
                    <stop offset=".87" stopColor="#62aaf6" stopOpacity=".1" />
                    <stop offset="1" stopColor="#52a2f6" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="hiw-step1-linear-gradient-26" x1="140.93" y1="734.23" x2="144.57" y2="734.23" />
                <linearGradient id="hiw-step1-linear-gradient-27" x1="151.33" y1="737.87" x2="154.97" y2="737.87" />
                <linearGradient id="hiw-step1-linear-gradient-28" x1="155.24" y1="724.57" x2="158.87" y2="724.57" />
                <linearGradient id="hiw-step1-linear-gradient-29" x1="119.87" y1="727.56" x2="123.51" y2="727.56" />
                <linearGradient id="hiw-step1-linear-gradient-30" x1="137.56" y1="725.98" x2="141.19" y2="725.98" />
                <linearGradient id="hiw-step1-linear-gradient-31" x1="128.22" y1="732.61" x2="131.86" y2="732.61" />
                <linearGradient id="hiw-step1-linear-gradient-32" x1="302.89" y1="732.45" x2="308.06" y2="732.45" />
                <linearGradient id="hiw-step1-linear-gradient-33" x1="296.84" y1="737.35" x2="300.6" y2="737.35" />
                <linearGradient id="hiw-step1-linear-gradient-34" x1="310.93" y1="564.38" x2="324.46" y2="564.38" />
                <filter id="outer-glow-1" filterUnits="userSpaceOnUse">
                    <feOffset dx="0" dy="0" />
                    <feGaussianBlur result="blur" stdDeviation="3" />
                    <feFlood floodColor="#000" floodOpacity=".29" />
                    <feComposite in2="blur" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="outer-shadow" filterUnits="userSpaceOnUse">
                    <feColorMatrix type="matrix"
                                values="1 0 0 0 0 
                                        0 1 0 0 0 
                                        0 0 1 0 0 
                                        0 0 0 100 0"
                        result="boostedInput" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite operator="out" in2="boostedInput" />
                </filter>
            </defs>
            <g id="Layer_1-2">
                <g>
                    <path className="hiw-step1-65" d="M439.1,66.1V869.36c0,22.35-18.44,40.48-41.2,40.48H66.3c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48Z" />
                    <g id="highlightbox">
                        <rect className="hiw-step1-50" x="247.84" y="624" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <g id="highlightbox-2">
                        <rect className="hiw-step1-50" x="57.42" y="624" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <g id="highlightbox-3">
                        <rect className="hiw-step1-50" x="247.84" y="466.89" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <g id="highlightbox-4">
                        <rect className="hiw-step1-50" x="57.42" y="466.89" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <g id="highlightbox-5">
                        <rect className="hiw-step1-50" x="247.84" y="309.78" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <g id="highlightbox-6">
                        <rect className="hiw-step1-50" x="57.42" y="309.78" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <g id="highlightbox-7">
                        <rect className="hiw-step1-50" x="247.84" y="152.67" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <g id="highlightbox-8">
                        <rect className="hiw-step1-50" x="57.42" y="152.67" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                    </g>
                    <path className="hiw-step1-57" d="M396.03,9c31.52,0,57.16,25.53,57.16,56.9V869.56c0,31.37-25.64,56.89-57.16,56.89H68.16c-31.51,0-57.15-25.52-57.15-56.89V65.9c0-31.37,25.64-56.9,57.15-56.9H396.03M139.82,50.96c-4.67,0-9.07-1.78-12.36-5.02-3.27-3.21-5.07-7.48-5.07-12,0-7.9-6.49-14.32-14.47-14.32h-41.27c-26.03,0-47.2,20.85-47.2,46.48V869.36c0,25.63,21.17,46.48,47.2,46.48H398.25c26.03,0,47.2-20.85,47.2-46.48V66.1c0-25.63-21.17-46.48-47.2-46.48h-41.27c-3.86,0-7.48,1.48-10.19,4.16-2.76,2.71-4.28,6.32-4.28,10.16,0,9.38-7.82,17.02-17.43,17.02H139.82M396.03,3H68.16C33.28,3,5.01,31.16,5.01,65.9V869.56c0,34.74,28.27,62.89,63.15,62.89H396.03c34.88,0,63.16-28.15,63.16-62.89V65.9c0-34.74-28.28-62.9-63.16-62.9h0ZM139.82,56.96h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48V869.36c0,22.35-18.44,40.48-41.2,40.48H66.65c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h0Z" />
                    <path className="hiw-step1-57" d="M285.86,35.9c-2.94,0-5.32,2.38-5.32,5.32s2.38,5.32,5.32,5.32,5.32-2.38,5.32-5.32-2.38-5.32-5.32-5.32h0Z" />
                    <line className="hiw-step1-2" x1="2" y1="118.24" x2="2" y2="152.97" />
                    <line className="hiw-step1-2" x1="2" y1="192.32" x2="2" y2="262.56" />
                    <line className="hiw-step1-2" x1="2" y1="277.48" x2="2" y2="347.72" />
                    <text className="hiw-step1-8" transform="translate(51.16 127.52)">
                        <tspan x="0" y="0">Sele</tspan>
                        <tspan className="hiw-step1-35" x="41.5" y="0">c</tspan>
                        <tspan x="52.83" y="0">t a</tspan>
                        <tspan className="hiw-step1-1" x="77.68" y="0">n</tspan>
                        <tspan x="89.1" y="0">y that apply to </tspan>
                        <tspan className="hiw-step1-40" x="236.98" y="0">y</tspan>
                        <tspan className="hiw-step1-34" x="248.66" y="0">our p</tspan>
                        <tspan className="hiw-step1-63" x="299.31" y="0">r</tspan>
                        <tspan className="hiw-step1-54" x="307.35" y="0">o</tspan>
                        <tspan x="319.07" y="0">je</tspan>
                        <tspan className="hiw-step1-35" x="336.84" y="0">c</tspan>
                        <tspan className="hiw-step1-34" x="348.18" y="0">t.</tspan>
                    </text>
                    <line className="hiw-step1-3" x1="172.6" y1="898.6" x2="292.3" y2="898.6" />
                    <g id="nextArrow">
                        <path className="hiw-step1-7" d="M368.78,824.09c-4.38-4.38-6.89-10.46-6.87-16.67,0-13.06,10.63-23.69,23.69-23.69,6.38,0,12.34,2.54,16.88,7.08,4.42,4.42,6.88,10.29,6.92,16.55,.05,6.33-2.37,12.3-6.82,16.8-9.15,9.3-24.16,9.43-33.47,.28l-.27-.27-.07-.07Zm31.7-31.56c-3.98-3.98-9.26-6.17-14.87-6.17-11.61,0-21.05,9.45-21.05,21.05-.02,5.52,2.2,10.91,6.1,14.81l.06,.06,.27,.27c8.26,8.13,21.61,8.01,29.74-.26,3.95-4,6.1-9.3,6.06-14.93-.04-5.63-2.21-10.78-6.29-14.84h0Z" />
                        <polygon className="hiw-step1-7" points="394.65 807.48 386.81 815.31 385.01 813.51 389.76 808.76 377.95 808.76 377.95 806.2 389.75 806.18 385.01 801.45 386.81 799.64 394.65 807.48" />
                    </g>
                    <g id="backArrow" className="hiw-step1-48">
                        <path className="hiw-step1-7" d="M96.12,790.85c4.38,4.38,6.89,10.46,6.87,16.67,0,13.06-10.63,23.69-23.69,23.69-6.38,0-12.34-2.54-16.88-7.08-4.42-4.42-6.88-10.29-6.92-16.55-.05-6.33,2.37-12.3,6.82-16.8,9.15-9.3,24.16-9.43,33.47-.28l.27,.27,.07,.07Zm-31.7,31.56c3.98,3.98,9.26,6.17,14.87,6.17,11.61,0,21.05-9.45,21.05-21.05,.02-5.52-2.2-10.91-6.1-14.81l-.06-.06-.27-.27c-8.26-8.13-21.61-8.01-29.74,.26-3.95,4-6.1,9.3-6.06,14.93,.04,5.63,2.21,10.78,6.29,14.84h0Z" />
                        <polygon className="hiw-step1-7" points="70.25 807.46 78.08 799.63 79.89 801.43 75.14 806.18 86.95 806.18 86.95 808.74 75.15 808.75 79.89 813.49 78.08 815.29 70.25 807.46" />
                    </g>
                    <g>
                        <g>
                            <g>
                                <path className="hiw-step1-32" d="M152.56,561.85h-25.68c-10.5,0-19.09,8.59-19.09,19.09v6.97c0,1.49,1.22,2.71,2.71,2.71h58.44c1.49,0,2.71-1.22,2.71-2.71v-6.97c0-10.5-8.59-19.09-19.09-19.09Z" />
                                <path className="hiw-step1-7" d="M168.94,591.24h-58.44c-1.83,0-3.33-1.49-3.33-3.33v-6.97c0-10.87,8.84-19.71,19.71-19.71h25.68c10.87,0,19.71,8.84,19.71,19.71v6.97c0,1.84-1.49,3.33-3.33,3.33Zm-42.06-28.77c-10.19,0-18.48,8.29-18.48,18.48v6.97c0,1.15,.94,2.09,2.09,2.09h58.44c1.15,0,2.09-.94,2.09-2.09v-6.97c0-10.19-8.29-18.48-18.48-18.48h-25.68Z" />
                            </g>
                            <g>
                                <ellipse className="hiw-step1-32" cx="139.72" cy="543.29" rx="18.78" ry="21.44" />
                                <path className="hiw-step1-7" d="M139.72,565.35c-10.69,0-19.39-9.89-19.39-22.06s8.7-22.06,19.39-22.06,19.39,9.89,19.39,22.06-8.7,22.06-19.39,22.06Zm0-42.88c-10.01,0-18.16,9.34-18.16,20.82s8.15,20.82,18.16,20.82,18.16-9.34,18.16-20.82-8.15-20.82-18.16-20.82Z" />
                            </g>
                            <rect className="hiw-step1-58" x="118.25" y="533.21" width="42.95" height="15.91" rx="4.77" ry="4.77" />
                            <path className="hiw-step1-7" d="M156.43,549.74h-33.41c-2.97,0-5.39-2.42-5.39-5.39v-6.38c0-2.97,2.42-5.39,5.39-5.39h33.41c2.97,0,5.39,2.42,5.39,5.39v6.38c0,2.97-2.42,5.39-5.39,5.39Zm-33.41-15.91c-2.29,0-4.15,1.86-4.15,4.15v6.38c0,2.29,1.86,4.15,4.15,4.15h33.41c2.29,0,4.15-1.86,4.15-4.15v-6.38c0-2.29-1.86-4.15-4.15-4.15h-33.41Z" />
                            <circle className="hiw-step1-7" cx="152.87" cy="539.79" r="1.81" />
                        </g>
                        <g>
                            <path className="hiw-step1-7" d="M96.41,482.75h-.49l-2.8,7.99h-2.23l3.54-9.8h3.5l3.53,9.8h-2.23l-2.83-7.99Zm-3.15,4.07h5.71v1.81h-5.71v-1.81Z" />
                            <path className="hiw-step1-7" d="M108.68,490.74h-1.86l-.14-.77c-.23,.26-.51,.47-.82,.63-.31,.16-.66,.24-1.03,.24-.52,0-.98-.12-1.36-.35s-.68-.58-.9-1.03c-.21-.45-.32-1-.32-1.63v-4.09h2.04v3.84c0,.47,.1,.82,.29,1.06,.19,.24,.48,.36,.88,.36s.66-.13,.87-.38c.21-.25,.31-.61,.31-1.08v-3.81h2.04v7Z" />
                            <path className="hiw-step1-7" d="M115.15,483.74h1.85v5.99c0,.79-.2,1.47-.59,2.04-.39,.56-.96,1-1.69,1.31-.74,.31-1.64,.46-2.7,.46l-.29-1.69c1.05,0,1.86-.18,2.41-.53s.83-.83,.83-1.48v-.03l.29-.17c-.21,.32-.5,.59-.88,.83-.38,.24-.84,.36-1.37,.36-.6,0-1.13-.15-1.59-.44s-.82-.71-1.08-1.25c-.26-.54-.39-1.18-.39-1.9s.13-1.37,.39-1.91c.26-.54,.62-.95,1.08-1.25s.99-.44,1.59-.44c.3,0,.58,.04,.84,.13s.5,.21,.7,.35c.21,.15,.37,.31,.49,.5l.13-.88Zm-3.02,3.5c0,.51,.13,.93,.39,1.25,.26,.32,.6,.48,1.02,.48,.25,0,.49-.07,.7-.21,.21-.14,.39-.34,.52-.59s.2-.56,.2-.92c0-.37-.07-.69-.2-.94-.13-.25-.3-.44-.52-.57s-.44-.2-.69-.2c-.43,0-.77,.16-1.04,.47-.26,.31-.39,.73-.39,1.24Z" />
                            <path className="hiw-step1-7" d="M118.55,483.74h1.85l.14,.71c.22-.24,.49-.44,.8-.59,.31-.15,.64-.22,1.01-.22,.43,0,.83,.09,1.2,.28,.37,.19,.67,.46,.89,.81,.26-.33,.59-.59,.97-.79,.39-.2,.81-.3,1.28-.3,.55,0,1.03,.12,1.43,.35,.41,.23,.72,.57,.95,1.02,.22,.45,.34,.99,.34,1.64v4.09h-2.04v-3.84c0-.45-.1-.79-.31-1.03-.21-.24-.5-.36-.87-.36-.39,0-.7,.12-.91,.36-.21,.24-.32,.6-.32,1.06v3.79h-2.04v-3.84c0-.45-.09-.79-.28-1.03-.19-.24-.47-.36-.85-.36s-.69,.12-.88,.36c-.2,.24-.29,.59-.29,1.04v3.82h-2.04v-7Z" />
                            <path className="hiw-step1-7" d="M134.13,490.84c-.72,0-1.34-.15-1.88-.46-.53-.3-.95-.73-1.24-1.28s-.44-1.2-.44-1.93c0-.68,.14-1.29,.43-1.82,.29-.53,.7-.95,1.22-1.25,.52-.3,1.12-.46,1.78-.46,.7,0,1.31,.14,1.82,.43,.51,.29,.92,.69,1.2,1.21s.43,1.13,.43,1.82c0,.11,0,.23-.01,.35,0,.12-.02,.26-.04,.41h-4.65c.05,.25,.13,.47,.27,.65,.13,.18,.3,.32,.5,.42,.2,.1,.43,.15,.69,.15,.29,0,.55-.06,.78-.18,.23-.12,.41-.29,.52-.52l1.83,.5c-.28,.63-.7,1.11-1.26,1.45-.56,.34-1.21,.51-1.96,.51Zm1.11-4.31c0-.23-.07-.44-.18-.62-.11-.18-.26-.32-.43-.42-.18-.1-.37-.15-.59-.14-.21,0-.41,.06-.58,.16-.17,.1-.32,.23-.44,.41-.12,.17-.2,.38-.22,.61h2.45Z" />
                            <path className="hiw-step1-7" d="M138.71,483.74h1.85l.14,.76c.23-.25,.51-.46,.83-.62,.32-.16,.66-.24,1.04-.24,.55,0,1.02,.12,1.39,.35s.67,.57,.87,1.02c.2,.45,.3,.99,.3,1.64v4.09h-2.04v-3.84c0-.47-.1-.82-.29-1.06-.19-.24-.48-.36-.86-.36s-.69,.12-.88,.37c-.2,.25-.29,.6-.29,1.07v3.82h-2.04v-7Z" />
                            <path className="hiw-step1-7" d="M147.14,483.74v-1.96h2.09v1.96h1.44v1.81h-1.44v2.52c0,.32,.07,.54,.22,.67s.33,.2,.55,.2c.22,0,.45-.01,.67-.05v1.78c-.48,.08-.93,.11-1.36,.08-.43-.03-.8-.15-1.13-.34s-.58-.49-.76-.87c-.19-.38-.28-.87-.28-1.47v-2.52h-.98v-1.81h.98Z" />
                            <path className="hiw-step1-7" d="M155.06,490.84c-.72,0-1.34-.15-1.88-.46-.53-.3-.95-.73-1.24-1.28s-.44-1.2-.44-1.93c0-.68,.14-1.29,.43-1.82,.29-.53,.7-.95,1.22-1.25,.52-.3,1.12-.46,1.78-.46,.7,0,1.31,.14,1.82,.43,.51,.29,.92,.69,1.2,1.21s.43,1.13,.43,1.82c0,.11,0,.23-.01,.35,0,.12-.02,.26-.04,.41h-4.65c.05,.25,.13,.47,.27,.65,.13,.18,.3,.32,.5,.42,.2,.1,.43,.15,.69,.15,.29,0,.55-.06,.78-.18,.23-.12,.41-.29,.52-.52l1.83,.5c-.28,.63-.7,1.11-1.26,1.45-.56,.34-1.21,.51-1.96,.51Zm1.11-4.31c0-.23-.07-.44-.18-.62-.11-.18-.26-.32-.43-.42-.18-.1-.37-.15-.59-.14-.21,0-.41,.06-.58,.16-.17,.1-.32,.23-.44,.41-.12,.17-.2,.38-.22,.61h2.45Z" />
                            <path className="hiw-step1-7" d="M164.64,484.82h-.28v-4.16h2.04v10.08h-1.85l-.13-.88c-.19,.27-.46,.5-.83,.69-.36,.19-.77,.29-1.22,.29-.59,0-1.11-.15-1.58-.44s-.82-.71-1.08-1.25c-.26-.54-.39-1.18-.39-1.9s.13-1.37,.39-1.91c.26-.54,.62-.95,1.08-1.25,.46-.29,.98-.44,1.58-.44,.53,0,.99,.11,1.37,.34,.38,.23,.68,.51,.88,.83Zm-3.11,2.42c0,.51,.13,.93,.39,1.24,.26,.31,.6,.47,1.02,.47,.25,0,.49-.07,.7-.2,.21-.13,.39-.32,.52-.57,.13-.25,.2-.57,.2-.94s-.07-.69-.2-.94c-.13-.25-.3-.45-.52-.58-.21-.14-.45-.2-.7-.2-.43,0-.77,.16-1.03,.48s-.38,.73-.38,1.25Z" />
                            <path className="hiw-step1-7" d="M175.12,490.84c-.68,0-1.29-.14-1.83-.43-.54-.29-.96-.71-1.27-1.25-.3-.54-.46-1.18-.46-1.92s.16-1.37,.47-1.91c.31-.54,.74-.95,1.29-1.25,.55-.29,1.15-.44,1.83-.44s1.31,.15,1.86,.44,.98,.71,1.29,1.25c.32,.54,.48,1.17,.48,1.9s-.16,1.39-.48,1.93-.76,.96-1.32,1.25c-.55,.29-1.17,.43-1.85,.43Zm0-1.85c.24,0,.48-.07,.7-.2,.22-.13,.41-.32,.55-.58,.14-.26,.21-.58,.21-.97s-.07-.72-.21-.97c-.14-.26-.32-.45-.53-.58s-.44-.2-.69-.2c-.26,0-.49,.07-.7,.2-.21,.13-.37,.32-.5,.58s-.19,.58-.19,.97,.06,.72,.18,.97c.12,.26,.28,.45,.49,.58,.21,.13,.43,.2,.69,.2Z" />
                            <path className="hiw-step1-7" d="M180.01,483.74h1.86l.14,.92c.18-.24,.39-.45,.64-.61,.25-.16,.52-.27,.83-.33,.3-.06,.64-.05,1,.01v1.93c-.24-.08-.5-.11-.78-.08-.28,.03-.55,.13-.8,.28-.25,.15-.46,.38-.61,.68-.15,.3-.23,.68-.23,1.13v3.05h-2.04v-7Z" />
                            <path className="hiw-step1-7" d="M100.68,497.74h2.31l-3.53,9.8h-2.69l-3.54-9.8h2.32l2.56,7.6,2.56-7.6Z" />
                            <path className="hiw-step1-7" d="M105.36,499.83c-.38,0-.69-.11-.93-.34-.24-.23-.36-.51-.36-.83s.12-.62,.36-.85c.24-.23,.55-.34,.93-.34s.68,.11,.92,.34c.24,.23,.36,.51,.36,.85s-.12,.6-.36,.83c-.24,.23-.54,.34-.92,.34Zm-1.02,.71h2.04v7h-2.04v-7Z" />
                            <path className="hiw-step1-7" d="M108.19,500.54h1.86l.14,.92c.18-.24,.39-.45,.64-.61,.25-.16,.52-.27,.83-.33,.3-.06,.64-.05,1,.01v1.93c-.24-.08-.5-.11-.78-.08-.28,.03-.55,.13-.8,.28-.25,.15-.46,.38-.61,.68-.15,.3-.23,.68-.23,1.13v3.05h-2.04v-7Z" />
                            <path className="hiw-step1-7" d="M114.23,500.54v-1.96h2.09v1.96h1.44v1.81h-1.44v2.52c0,.32,.07,.54,.22,.67s.33,.2,.55,.2c.22,0,.45-.01,.67-.05v1.78c-.48,.08-.93,.11-1.36,.08-.43-.03-.8-.15-1.13-.34s-.58-.49-.76-.87c-.19-.38-.28-.87-.28-1.47v-2.52h-.98v-1.81h.98Z" />
                            <path className="hiw-step1-7" d="M125.25,507.54h-1.86l-.14-.77c-.23,.26-.51,.47-.82,.63-.31,.16-.66,.24-1.03,.24-.52,0-.98-.12-1.36-.35s-.68-.58-.9-1.03c-.21-.45-.32-1-.32-1.63v-4.09h2.04v3.84c0,.47,.1,.82,.29,1.06,.19,.24,.48,.36,.88,.36s.66-.13,.87-.38c.21-.25,.31-.61,.31-1.08v-3.81h2.04v7Z" />
                            <path className="hiw-step1-7" d="M130.85,507.54l-.14-.87c-.24,.3-.5,.53-.78,.7-.28,.17-.65,.25-1.12,.25-.44,0-.83-.08-1.18-.23-.35-.15-.62-.38-.82-.68-.2-.3-.3-.66-.3-1.08,0-.58,.21-1.06,.62-1.44,.42-.38,1.06-.62,1.94-.71l1.64-.18v-.46c0-.16-.08-.31-.23-.47s-.39-.23-.72-.23c-.29,0-.54,.08-.75,.22s-.35,.36-.43,.64l-1.75-.63c.21-.62,.57-1.1,1.09-1.43,.52-.33,1.16-.5,1.92-.5,.95,0,1.67,.24,2.17,.71,.49,.48,.74,1.12,.74,1.92v4.47h-1.9Zm-.14-2.98l-1.3,.15c-.26,.04-.46,.12-.59,.25-.14,.13-.2,.28-.2,.47s.08,.33,.22,.45c.15,.12,.35,.18,.62,.18s.49-.05,.67-.16c.19-.11,.33-.26,.43-.46,.1-.2,.15-.45,.15-.75v-.13Z" />
                            <path className="hiw-step1-7" d="M134.23,497.46h2.04v10.08h-2.04v-10.08Z" />
                            <path className="hiw-step1-7" d="M146.1,497.74c.79,0,1.45,.14,1.98,.43,.53,.29,.92,.66,1.18,1.13,.26,.47,.39,.99,.39,1.55,0,.38-.07,.76-.2,1.13-.14,.37-.35,.71-.63,1.01-.29,.3-.65,.54-1.11,.72s-.99,.27-1.62,.27h-2.11v3.57h-2.16v-9.8h4.27Zm1.36,3.11c0-.4-.12-.72-.36-.95-.24-.23-.61-.35-1.11-.35h-2v2.62h2c.34,0,.61-.05,.83-.15,.21-.1,.38-.25,.48-.46,.11-.2,.16-.44,.16-.71Zm-.22,2.38l2.62,4.31h-2.39l-2.49-4.31h2.27Z" />
                            <path className="hiw-step1-7" d="M154.32,507.64c-.72,0-1.34-.15-1.88-.46-.53-.3-.95-.73-1.24-1.28s-.44-1.2-.44-1.93c0-.68,.14-1.29,.43-1.82,.29-.53,.7-.95,1.22-1.25,.52-.3,1.12-.46,1.78-.46,.7,0,1.31,.14,1.82,.43,.51,.29,.92,.69,1.2,1.21s.43,1.13,.43,1.82c0,.11,0,.23-.01,.35,0,.12-.02,.26-.04,.41h-4.65c.05,.25,.13,.47,.27,.65,.13,.18,.3,.32,.5,.42,.2,.1,.43,.15,.69,.15,.29,0,.55-.06,.78-.18,.23-.12,.41-.29,.52-.52l1.83,.5c-.28,.63-.7,1.11-1.26,1.45-.56,.34-1.21,.51-1.96,.51Zm1.11-4.31c0-.23-.07-.44-.18-.62-.11-.18-.26-.32-.43-.42-.18-.1-.37-.15-.59-.14-.21,0-.41,.06-.58,.16-.17,.1-.32,.23-.44,.41-.12,.17-.2,.38-.22,.61h2.45Z" />
                            <path className="hiw-step1-7" d="M162.94,507.54l-.14-.87c-.24,.3-.5,.53-.78,.7-.28,.17-.65,.25-1.12,.25-.44,0-.83-.08-1.18-.23-.35-.15-.62-.38-.82-.68-.2-.3-.3-.66-.3-1.08,0-.58,.21-1.06,.62-1.44,.42-.38,1.06-.62,1.94-.71l1.64-.18v-.46c0-.16-.08-.31-.23-.47s-.39-.23-.72-.23c-.29,0-.54,.08-.75,.22s-.35,.36-.43,.64l-1.75-.63c.21-.62,.57-1.1,1.09-1.43,.52-.33,1.16-.5,1.92-.5,.95,0,1.67,.24,2.17,.71,.49,.48,.74,1.12,.74,1.92v4.47h-1.9Zm-.14-2.98l-1.3,.15c-.26,.04-.46,.12-.59,.25-.14,.13-.2,.28-.2,.47s.08,.33,.22,.45c.15,.12,.35,.18,.62,.18s.49-.05,.67-.16c.19-.11,.33-.26,.43-.46,.1-.2,.15-.45,.15-.75v-.13Z" />
                            <path className="hiw-step1-7" d="M166.31,497.46h2.04v10.08h-2.04v-10.08Z" />
                            <path className="hiw-step1-7" d="M171.2,499.83c-.38,0-.69-.11-.93-.34-.24-.23-.36-.51-.36-.83s.12-.62,.36-.85c.24-.23,.55-.34,.93-.34s.68,.11,.92,.34c.24,.23,.36,.51,.36,.85s-.12,.6-.36,.83c-.24,.23-.54,.34-.92,.34Zm-1.02,.71h2.04v7h-2.04v-7Z" />
                            <path className="hiw-step1-7" d="M174.57,500.54v-1.96h2.09v1.96h1.44v1.81h-1.44v2.52c0,.32,.07,.54,.22,.67s.33,.2,.55,.2c.22,0,.45-.01,.67-.05v1.78c-.48,.08-.93,.11-1.36,.08-.43-.03-.8-.15-1.13-.34s-.58-.49-.76-.87c-.19-.38-.28-.87-.28-1.47v-2.52h-.98v-1.81h.98Z" />
                            <path className="hiw-step1-7" d="M184.07,500.54h2.21l-4.14,9.8h-2.09l1.39-3.12-2.77-6.68h2.28l1.55,4.19,1.57-4.19Z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path className="hiw-step1-10" d="M191.02,275.56c-6.7,5.08-19.52-8.16-26.17-15.95-1.56-1.83-4.28-2.02-6.13-.47-1.3,1.09-2.98,1.74-4.8,1.74-2.64,0-4.96-1.36-6.29-3.42-.93-1.44-2.52-2.32-4.23-2.32h-7.34c-1.71,0-3.3,.88-4.23,2.32-1.34,2.06-3.66,3.42-6.29,3.42-1.83,0-3.5-.65-4.8-1.74-1.85-1.54-4.57-1.36-6.13,.47-6.64,7.79-19.47,21.03-26.17,15.95-8.63-6.53,3.79-45.97,5.61-51.59,2.31-7.44,9.26-12.89,17.44-12.89h56.47c7.93,0,14.72,5.13,17.23,12.22,0,0,0,0,0,.02,.02,.05,.03,.09,.04,.14,.05,.11,.1,.28,.17,.51,1.83,5.63,14.24,45.06,5.61,51.59Z" />
                                <path className="hiw-step1-7" d="M187.55,277.02c-7.1,0-17.07-10.29-22.95-17.19-1.44-1.69-3.93-1.88-5.66-.43-1.4,1.17-3.19,1.82-5.02,1.82-2.66,0-5.12-1.34-6.57-3.57-.88-1.36-2.36-2.17-3.95-2.17h-7.34c-1.6,0-3.07,.81-3.95,2.17-1.45,2.24-3.91,3.57-6.57,3.57-1.83,0-3.61-.65-5.02-1.82-1.73-1.45-4.22-1.26-5.66,.43-7,8.21-19.77,21.2-26.62,16-8.69-6.58,3.05-44.44,5.5-51.96,2.39-7.72,9.7-13.12,17.76-13.12h56.47c7.87,0,14.92,5,17.54,12.45v.04s.04,.1,.05,.14c.04,.11,.09,.26,.15,.45,2.46,7.57,14.2,45.43,5.51,52.01h0c-1.09,.83-2.33,1.19-3.67,1.19Zm-25.99-19.25c1.32,0,2.63,.55,3.54,1.62,5.51,6.46,19.05,20.95,25.71,15.9h0c8.5-6.43-4.25-46.66-5.73-51.23-.08-.24-.12-.38-.16-.48l-.06-.19c-2.54-7.17-9.33-11.99-16.91-11.99h-56.47c-7.77,0-14.82,5.2-17.12,12.66-1.48,4.57-14.24,44.79-5.73,51.23,6.66,5.05,20.2-9.44,25.71-15.9,1.65-1.94,4.61-2.17,6.59-.51,1.28,1.07,2.91,1.66,4.59,1.66,2.44,0,4.68-1.22,6.01-3.27,1-1.55,2.69-2.47,4.51-2.47h7.34c1.82,0,3.51,.92,4.51,2.47,1.33,2.05,3.58,3.27,6.01,3.27,1.68,0,3.31-.59,4.59-1.66,.89-.74,1.97-1.11,3.05-1.11Z" />
                            </g>
                            <g>
                                <rect className="hiw-step1-37" x="112.03" y="207.35" width="15.54" height="3.72" rx="1.28" ry="1.28" />
                                <path className="hiw-step1-7" d="M126.29,211.41h-12.98c-.89,0-1.62-.73-1.62-1.62v-1.16c0-.89,.73-1.62,1.62-1.62h12.98c.89,0,1.62,.73,1.62,1.62v1.16c0,.89-.73,1.62-1.62,1.62Zm-12.98-3.72c-.52,0-.95,.43-.95,.95v1.16c0,.52,.43,.95,.95,.95h12.98c.52,0,.95-.43,.95-.95v-1.16c0-.52-.43-.95-.95-.95h-12.98Z" />
                            </g>
                            <g>
                                <rect className="hiw-step1-37" x="150.7" y="207.35" width="15.54" height="3.72" rx="1.28" ry="1.28" />
                                <path className="hiw-step1-7" d="M164.96,211.41h-12.98c-.89,0-1.62-.73-1.62-1.62v-1.16c0-.89,.73-1.62,1.62-1.62h12.98c.89,0,1.62,.73,1.62,1.62v1.16c0,.89-.73,1.62-1.62,1.62Zm-12.98-3.72c-.52,0-.95,.43-.95,.95v1.16c0,.52,.43,.95,.95,.95h12.98c.52,0,.95-.43,.95-.95v-1.16c0-.52-.43-.95-.95-.95h-12.98Z" />
                            </g>
                            <g>
                                <circle className="hiw-step1-14" cx="153.71" cy="253.38" r="4.67" />
                                <path className="hiw-step1-7" d="M153.71,258.38c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm0-9.34c-2.39,0-4.34,1.94-4.34,4.34s1.94,4.34,4.34,4.34,4.34-1.94,4.34-4.34-1.95-4.34-4.34-4.34Z" />
                            </g>
                            <g>
                                <circle className="hiw-step1-15" cx="125.53" cy="253.38" r="4.67" />
                                <path className="hiw-step1-7" d="M125.53,258.38c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm0-9.34c-2.39,0-4.34,1.94-4.34,4.34s1.94,4.34,4.34,4.34,4.34-1.94,4.34-4.34-1.94-4.34-4.34-4.34Z" />
                            </g>
                            <g>
                                <path className="hiw-step1-7" d="M113.99,241.02c-1.64,0-2.97-1.33-2.97-2.97v-2.53h-2.53c-1.64,0-2.97-1.33-2.97-2.97v-.58c0-1.64,1.33-2.97,2.97-2.97h2.53v-2.53c0-1.64,1.33-2.97,2.97-2.97h.58c1.64,0,2.97,1.33,2.97,2.97v2.53h2.53c1.64,0,2.97,1.33,2.97,2.97v.58c0,1.64-1.33,2.97-2.97,2.97h-2.53v2.53c0,1.64-1.33,2.97-2.97,2.97h-.58Z" />
                                <path className="hiw-step1-37" d="M114.57,224.48c1.09,0,1.97,.89,1.97,1.97v3.53h3.53c1.09,0,1.97,.89,1.97,1.97v.58c0,1.09-.89,1.97-1.97,1.97h-3.53v3.53c0,1.09-.89,1.97-1.97,1.97h-.58c-1.09,0-1.97-.89-1.97-1.97v-3.53h-3.53c-1.09,0-1.97-.89-1.97-1.97v-.58c0-1.09,.89-1.97,1.97-1.97h3.53v-3.53c0-1.09,.89-1.97,1.97-1.97h.58m0-2h-.58c-2.19,0-3.97,1.78-3.97,3.97v1.53h-1.53c-2.19,0-3.97,1.78-3.97,3.97v.58c0,2.19,1.78,3.97,3.97,3.97h1.53v1.53c0,2.19,1.78,3.97,3.97,3.97h.58c2.19,0,3.97-1.78,3.97-3.97v-1.53h1.53c2.19,0,3.97-1.78,3.97-3.97v-.58c0-2.19-1.78-3.97-3.97-3.97h-1.53v-1.53c0-2.19-1.78-3.97-3.97-3.97h0Z" />
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <circle className="hiw-step1-73" cx="165.17" cy="226.74" r="2.26" />
                                        <path className="hiw-step1-7" d="M165.17,229.34c-1.43,0-2.6-1.17-2.6-2.6s1.17-2.6,2.6-2.6,2.6,1.16,2.6,2.6-1.17,2.6-2.6,2.6Zm0-4.53c-1.06,0-1.93,.87-1.93,1.93s.87,1.93,1.93,1.93,1.93-.87,1.93-1.93-.87-1.93-1.93-1.93Z" />
                                    </g>
                                    <g>
                                        <circle className="hiw-step1-52" cx="165.17" cy="237.76" r="2.26" />
                                        <path className="hiw-step1-7" d="M165.17,240.36c-1.43,0-2.6-1.17-2.6-2.6s1.17-2.6,2.6-2.6,2.6,1.16,2.6,2.6-1.17,2.6-2.6,2.6Zm0-4.53c-1.06,0-1.93,.87-1.93,1.93s.87,1.93,1.93,1.93,1.93-.87,1.93-1.93-.87-1.93-1.93-1.93Z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <circle className="hiw-step1-39" cx="170.68" cy="232.25" r="2.26" />
                                        <path className="hiw-step1-7" d="M170.68,234.85c-1.43,0-2.6-1.17-2.6-2.6s1.16-2.6,2.6-2.6,2.6,1.16,2.6,2.6-1.17,2.6-2.6,2.6Zm0-4.53c-1.06,0-1.93,.87-1.93,1.93s.87,1.93,1.93,1.93,1.93-.87,1.93-1.93-.87-1.93-1.93-1.93Z" />
                                    </g>
                                    <g>
                                        <circle className="hiw-step1-56" cx="159.66" cy="232.25" r="2.26" />
                                        <path className="hiw-step1-7" d="M159.66,234.85c-1.43,0-2.6-1.17-2.6-2.6s1.16-2.6,2.6-2.6,2.6,1.16,2.6,2.6-1.17,2.6-2.6,2.6Zm0-4.53c-1.06,0-1.93,.87-1.93,1.93s.87,1.93,1.93,1.93,1.93-.87,1.93-1.93-.87-1.93-1.93-1.93Z" />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <text className="hiw-step1-9" transform="translate(120.65 176.52)">
                            <tspan x="0" y="0">Game</tspan>
                            <tspan x="-24.26" y="16.8">D</tspan>
                            <tspan className="hiw-step1-59" x="-13.97" y="16.8">ev</tspan>
                            <tspan x="1.26" y="16.8">elopment</tspan>
                        </text>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path className="hiw-step1-10" d="M330.16,214.32v53.64h0c0,4.84-3.91,8.75-8.73,8.75-3.34,0-6.24-1.87-7.7-4.62-.2,.03-.41,.05-.62,.06-4.14,.16-7.62-3.06-7.78-7.21-.01-.27,0-.53,.01-.78h-.01c-3.44,0-6.21-2.78-6.21-6.21,0-1.08,.28-2.11,.78-2.99,.1-.19,.2-.36,.32-.54,.05-.07,.11-.15,.15-.22,.62-.8,1.42-1.46,2.35-1.89-.23-.06-.47-.11-.7-.18-.17-.05-.35-.11-.52-.16-4.7-1.58-8.08-6.02-8.08-11.26,0-4.25,2.24-7.98,5.61-10.07,.45-.28,.91-.53,1.4-.74l-.06-.07c-.05-.07-.11-.14-.15-.22-.12-.17-.22-.35-.32-.54-.49-.88-.78-1.91-.78-2.99,0-3.44,2.78-6.21,6.21-6.21h.01c-.02-.25-.02-.51-.01-.78,.16-4.15,3.64-7.37,7.78-7.21,.21,0,.42,.02,.62,.06,1.11,.13,2.14,.5,3.04,1.07,.12-.76,.36-1.48,.72-2.13,1.13-2.12,3.36-3.56,5.94-3.56,3.72,0,6.74,3.02,6.74,6.74,0,.09,0,.17,0,.26Z" />
                                <path className="hiw-step1-7" d="M321.43,277.01c-3.28,0-6.27-1.75-7.87-4.59-.15,.02-.3,.03-.45,.03-2.1,.08-4.07-.65-5.6-2.07-1.53-1.42-2.42-3.35-2.5-5.43,0-.16,0-.32,0-.49-3.46-.17-6.21-3.03-6.21-6.51,0-1.1,.28-2.19,.82-3.14,.1-.19,.21-.38,.33-.56,.04-.06,.09-.13,.13-.19,.51-.67,1.12-1.22,1.79-1.64-.16-.05-.31-.1-.47-.15-4.97-1.67-8.3-6.31-8.3-11.55,0-4.23,2.15-8.1,5.75-10.34,.35-.22,.72-.43,1.09-.6-.12-.18-.23-.37-.33-.56-.53-.95-.81-2.04-.81-3.14,0-3.49,2.76-6.35,6.21-6.51,0-.17,0-.33,0-.49,.08-2.09,.97-4.01,2.5-5.43s3.53-2.16,5.61-2.07c.22,0,.44,.03,.66,.06,.98,.11,1.91,.41,2.77,.89,.14-.62,.36-1.22,.67-1.78,1.22-2.29,3.61-3.72,6.21-3.72,3.89,0,7.05,3.16,7.05,7.05,0,.09,0,.18,0,.27v53.63c0,5-4.05,9.06-9.04,9.06Zm-7.53-5.26l.1,.19c1.46,2.75,4.31,4.45,7.43,4.45,4.65,0,8.42-3.78,8.42-8.42v-53.66h0c0-.09,0-.17,0-.25,0-3.54-2.88-6.43-6.43-6.43-2.38,0-4.55,1.3-5.67,3.4-.34,.63-.57,1.32-.68,2.03l-.07,.47-.4-.25c-.88-.56-1.86-.9-2.91-1.02-.2-.03-.4-.05-.6-.05-1.92-.08-3.76,.6-5.16,1.91-1.41,1.3-2.22,3.08-2.3,5-.01,.24,0,.48,.01,.74l.02,.33h-.33c-3.27,0-5.91,2.65-5.91,5.9,0,.99,.26,1.98,.74,2.84,.1,.18,.2,.35,.31,.52,.04,.06,.08,.11,.12,.17l.31,.43-.35,.15c-.46,.2-.91,.44-1.36,.72-3.42,2.13-5.46,5.8-5.46,9.81,0,4.97,3.16,9.38,7.87,10.96,.17,.05,.34,.11,.51,.16,.22,.07,.44,.12,.66,.17l.88,.21-.8,.38c-.87,.4-1.64,1.02-2.24,1.79-.06,.09-.11,.14-.14,.2-.11,.17-.21,.34-.31,.51-.49,.87-.74,1.85-.74,2.85,0,3.25,2.65,5.9,5.9,5.9h.33v.33c-.03,.26-.04,.5-.02,.74,.07,1.93,.89,3.7,2.3,5.01,1.41,1.3,3.24,1.98,5.16,1.91,.2,0,.4-.02,.59-.05l.22-.03Z" />
                            </g>
                            <path className="hiw-step1-7" d="M342.65,214.38c-.17,0-.31-.14-.31-.31,0-3.54-2.88-6.43-6.43-6.43-2.59,0-4.53,1.43-5.76,4.25-.07,.16-.25,.23-.41,.16-.16-.07-.23-.25-.16-.41,1.34-3.07,3.47-4.63,6.33-4.63,3.89,0,7.05,3.16,7.05,7.05,0,.17-.14,.31-.31,.31Z" />
                            <path className="hiw-step1-7" d="M348.01,228.57c-.52,0-1.02-.16-1.44-.46-.11-.08-.22-.17-.31-.27l-.09-.1s-.07-.08-.1-.12c-.28-.36-.46-.78-.51-1.22v-.08s-.02-.14-.02-.24c0-.06,0-.13,0-.19,.06-.81,.51-1.54,1.21-1.95,.39-.23,.82-.34,1.25-.34,.94,0,1.77,.49,2.18,1.28,.41,.78,.41,1.66,0,2.4-.04,.07-.08,.14-.13,.21l-.05,.08c-.51,.65-1.22,1-1.99,1Zm0-4.34c-.33,0-.65,.09-.92,.25-.54,.32-.88,.87-.93,1.47,0,.05,0,.09,0,.14,0,.07,0,.13,.01,.19,.05,.38,.18,.69,.39,.96l.15,.17c.07,.07,.15,.13,.23,.2,.78,.56,1.94,.39,2.55-.37l.06-.08c.04-.05,.07-.11,.1-.16,.31-.56,.31-1.22,0-1.82-.3-.57-.94-.95-1.63-.95Z" />
                            <path className="hiw-step1-7" d="M312.81,222.42c-.64,0-1.24-.2-1.76-.57-.14-.1-.27-.21-.38-.33l-.22-.24c-.36-.46-.58-.97-.64-1.51v-.09c-.02-.06-.02-.18-.02-.3,0-.08,0-.16,0-.23,.07-.99,.63-1.88,1.48-2.39,.48-.28,1-.42,1.53-.42,1.68,0,3.04,1.36,3.04,3.04,0,.51-.13,1.02-.38,1.46-.05,.09-.1,.17-.16,.26l-.1,.14c-.59,.74-1.46,1.16-2.4,1.16Zm0-5.45c-.43,0-.85,.11-1.21,.33-.7,.41-1.14,1.12-1.2,1.91,0,.06,0,.12,0,.18,0,.09,0,.17,.01,.25,.06,.49,.23,.9,.5,1.24l.2,.22c.09,.09,.19,.17,.3,.26,.41,.29,.89,.45,1.4,.45,.75,0,1.44-.34,1.91-.93l.08-.11c.05-.07,.09-.14,.13-.21,.2-.36,.31-.76,.31-1.17,0-1.33-1.09-2.42-2.42-2.42Z" />
                            <path className="hiw-step1-7" d="M330.15,214.07v.25c0,.06,0,.12-.01,.19,0-.22,.01-.38,.01-.44Z" />
                            <path className="hiw-step1-7" d="M338.85,222.82c-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05,2.05,.92,2.05,2.05-.92,2.05-2.05,2.05Zm0-3.48c-.79,0-1.43,.64-1.43,1.43s.64,1.43,1.43,1.43,1.43-.64,1.43-1.43-.64-1.43-1.43-1.43Z" />
                            <path className="hiw-step1-7" d="M328.17,225.86c-.08,0-.16-.03-.22-.09l-2.33-2.32c-1.2-1.2-2.73-2.13-4.43-2.69l-.15-.05c-.48-.17-.94-.4-1.37-.67-.15-.1-.3-.2-.45-.31-1.44-1.06-2.41-2.62-2.74-4.37-.08-.41-.12-.84-.12-1.29,0-.25,.01-.48,.04-.71,.09-.92,.36-1.8,.8-2.61,1.22-2.3,3.6-3.72,6.21-3.72,3.89,0,7.05,3.16,7.05,7.05,0,.17-.14,.31-.31,.31s-.31-.14-.31-.31c0-3.54-2.88-6.43-6.43-6.43-2.38,0-4.55,1.3-5.67,3.4-.4,.74-.65,1.54-.73,2.38-.02,.22-.04,.43-.04,.65,0,.41,.04,.8,.11,1.17,.3,1.61,1.18,3.02,2.5,3.99,.13,.1,.27,.19,.41,.28,.39,.25,.81,.45,1.25,.61l.13,.05c1.79,.59,3.41,1.58,4.67,2.84l2.33,2.32c.12,.12,.12,.32,0,.44-.06,.06-.14,.09-.22,.09Z" />
                            <path className="hiw-step1-7" d="M334.2,225.86h-4.04v-.62h3.91l3.4-3.4,.44,.44-3.49,3.49c-.06,.06-.14,.09-.22,.09Z" />
                            <path className="hiw-step1-7" d="M300.21,229.93c-.1,0-.2-.05-.26-.14-.12-.18-.23-.37-.34-.56-.53-.95-.82-2.04-.82-3.14,0-3.6,2.93-6.52,6.52-6.52,1.16,0,2.28,.31,3.26,.88,1.88,1.11,3.07,3.03,3.23,5.15,0,.07,0,.14,.01,.21,0,.17-.12,.31-.3,.32-.17,0-.31-.12-.32-.3,0-.06,0-.13-.01-.19-.14-1.93-1.22-3.66-2.88-4.65-.92-.54-1.94-.82-2.98-.82-3.27,0-5.92,2.65-5.92,5.9,0,.99,.26,1.98,.74,2.84,.1,.18,.2,.35,.31,.52,.1,.14,.06,.33-.08,.43-.05,.04-.11,.05-.17,.05Z" />
                            <path className="hiw-step1-7" d="M321.03,264.23v-.62c.58,0,1.15-.1,1.7-.29,.34-.12,.67-.28,.98-.48,.11-.07,.21-.14,.32-.22,1.04-.76,1.73-1.88,1.96-3.14,.06-.3,.09-.61,.09-.93,0-.17-.01-.34-.03-.51-.06-.66-.26-1.29-.57-1.87l.54-.3c.36,.65,.57,1.36,.64,2.1,.02,.19,.03,.37,.03,.57,0,.36-.03,.71-.1,1.04-.26,1.41-1.04,2.66-2.2,3.52-.11,.09-.24,.17-.36,.24-.35,.22-.72,.4-1.1,.54-.62,.22-1.26,.33-1.91,.33Z" />
                            <path className="hiw-step1-7" d="M321.28,268.03c-1.22,0-2.21-.99-2.21-2.21s.99-2.21,2.21-2.21,2.21,.99,2.21,2.21-.99,2.21-2.21,2.21Zm0-3.79c-.88,0-1.59,.71-1.59,1.59s.71,1.59,1.59,1.59,1.59-.71,1.59-1.59-.71-1.59-1.59-1.59Z" />
                            <path className="hiw-step1-7" d="M321.43,277.02c-3.35,0-6.4-1.83-7.97-4.78-.57-1.04-.91-2.17-1.02-3.35-.03-.32-.05-.62-.05-.91,0-.58,.05-1.13,.16-1.66,.39-2.14,1.56-4.07,3.27-5.42,.03-.03,.02-.06,.02-.08-.2-.46-.33-.94-.4-1.43-.03-.2-.05-.35-.06-.51-.15-2.71,1.7-5.21,4.29-5.82,2.59-.61,5.15,.53,6.38,2.84l-.55,.29c-1.09-2.05-3.38-3.07-5.69-2.52-2.3,.54-3.94,2.77-3.81,5.18,0,.14,.02,.27,.04,.41,0,.03,.01,.06,.01,.09,.06,.4,.18,.83,.35,1.23,.12,.29,.04,.61-.2,.81-1.6,1.26-2.68,3.06-3.05,5.06-.1,.5-.15,1-.15,1.54,0,.27,.02,.55,.05,.85,.11,1.1,.43,2.15,.95,3.12,1.46,2.75,4.31,4.46,7.43,4.46,4.65,0,8.42-3.78,8.42-8.42h.62c0,4.99-4.06,9.04-9.04,9.04Z" />
                            <g>
                                <path className="hiw-step1-64" d="M305.29,242.84c-.65,0-1.26-.29-1.66-.81l-.07-.1c-.04-.06-.08-.12-.11-.18-.17-.31-.26-.66-.26-1.01,0-1.16,.95-2.11,2.11-2.11,.38,0,.74,.1,1.06,.29,.61,.36,.99,.98,1.04,1.66,0,.05,0,.11,0,.16,0,.08,0,.16-.01,.24-.05,.41-.2,.76-.44,1.07l-.08,.1s-.04,.05-.05,.05c-.12,.12-.21,.2-.3,.26-.36,.26-.79,.39-1.23,.39Zm0-3.6c-.83,0-1.49,.67-1.49,1.49,0,.25,.06,.5,.19,.72,.03,.05,.05,.09,.08,.13l.04,.05c.5,.63,1.42,.76,2.05,.31,.07-.05,.13-.1,.19-.16l.29,.15-.23-.2,.06-.08c.16-.21,.27-.46,.3-.73,.01-.09,.01-.14,.01-.19,0-.04,0-.08,0-.11-.04-.48-.31-.92-.73-1.17-.23-.14-.49-.21-.75-.21Z" />
                                <path className="hiw-step1-64" d="M307.11,246.29c-.11,0-.21-.05-.27-.15-.09-.15-.04-.34,.11-.42,.79-.46,1.47-1.12,1.97-1.88,.58-.9,.89-1.9,.94-2.94,.07-1.69-.58-3.32-1.78-4.49-.12-.12-.12-.31,0-.44,.12-.12,.31-.13,.44,0,1.33,1.29,2.05,3.09,1.97,4.95-.05,1.16-.4,2.27-1.02,3.22-.57,.89-1.33,1.61-2.2,2.12-.05,.03-.1,.04-.16,.04Z" />
                            </g>
                            <path className="hiw-step1-7" d="M305.33,264.47c-3.61,0-6.53-2.93-6.53-6.52,0-1.1,.28-2.19,.82-3.14,.1-.19,.21-.38,.33-.56,.04-.06,.09-.13,.13-.19,.69-.9,1.55-1.58,2.5-2.02,.85-.4,1.77-.6,2.73-.6,.17,0,.31,.14,.31,.31s-.14,.31-.31,.31c-.87,0-1.7,.18-2.47,.55-.87,.4-1.64,1.02-2.24,1.79-.06,.09-.11,.14-.14,.2-.11,.17-.21,.34-.31,.51-.49,.87-.74,1.85-.74,2.85,0,3.25,2.65,5.9,5.9,5.9,1.05,0,2.06-.28,2.94-.8,.01,0,.05-.02,.06-.03,.15-.06,.34,0,.41,.16,.07,.15,.02,.32-.13,.4l-.04,.02c-.96,.57-2.08,.87-3.23,.87Z" />
                            <path className="hiw-step1-7" d="M336.57,215.9c-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05,2.05,.92,2.05,2.05-.92,2.05-2.05,2.05Zm0-3.48c-.79,0-1.43,.64-1.43,1.43s.64,1.43,1.43,1.43,1.43-.64,1.43-1.43-.64-1.43-1.43-1.43Z" />
                            <path className="hiw-step1-7" d="M332.78,233.72h-2.62v-.62h2.5l7.23-7.23c.06-.06,.14-.09,.22-.09h5.74v.62h-5.61l-7.23,7.23c-.06,.06-.14,.09-.22,.09Z" />
                            <path className="hiw-step1-7" d="M350.31,257.12c-.44,0-.86-.14-1.22-.39-.1-.07-.19-.15-.27-.23l-.08-.09-.07-.08c-.25-.32-.4-.68-.45-1.06,0-.01,0-.06,0-.07,0-.03-.01-.11-.01-.2,0-.05,0-.11,0-.16,.05-.68,.44-1.3,1.03-1.66,.33-.19,.69-.29,1.07-.29,.77,0,1.46,.39,1.81,1.01,.38,.68,.4,1.47,.03,2.11-.03,.06-.07,.12-.11,.18l-.04,.06c-.44,.55-1.04,.85-1.69,.85Zm-1.05-1.06c.06,.06,.12,.11,.19,.16,.62,.44,1.56,.31,2.04-.3l.05-.07s.05-.08,.08-.13c.26-.47,.25-1.02-.03-1.51-.24-.43-.73-.7-1.28-.7-.26,0-.52,.07-.74,.2-.43,.25-.71,.69-.74,1.18,0,.04,0,.08,0,.11,0,.05,0,.1,0,.15,.04,.3,.14,.56,.31,.77l.06,.07-.16,.28,.22-.22Z" />
                            <path className="hiw-step1-7" d="M342.06,257.12c-.44,0-.86-.14-1.22-.39-.1-.07-.19-.15-.27-.23l-.08-.09s-.06-.06-.08-.1c-.24-.3-.39-.66-.43-1.04,0-.01,0-.06,0-.07,0-.03-.01-.11-.01-.2,0-.05,0-.11,0-.16,.05-.68,.44-1.3,1.03-1.66,1.01-.59,2.35-.21,2.88,.72,.38,.68,.4,1.47,.03,2.11-.03,.06-.07,.12-.11,.18l-.05,.08c-.43,.54-1.03,.84-1.68,.84Zm0-3.6c-.26,0-.52,.07-.74,.2-.43,.25-.7,.69-.74,1.18,0,.04,0,.08,0,.11,0,.05,0,.1,0,.15,.04,.3,.14,.56,.31,.77l.13,.14s.11,.1,.18,.15c.62,.44,1.56,.31,2.04-.3l.3,.11-.26-.17s.05-.08,.08-.13c.26-.47,.25-1.02-.03-1.51-.24-.43-.73-.7-1.28-.7Z" />
                            <g>
                                <g>
                                    <path className="hiw-step1-7" d="M352.76,237.32c-.44,0-.86-.14-1.22-.39-.1-.07-.19-.15-.27-.23l-.04-.05-.12-.14c-.24-.3-.39-.66-.43-1.04,0-.01,0-.06,0-.07,0-.03-.01-.11-.01-.2,0-.05,0-.11,0-.16,.05-.68,.44-1.3,1.03-1.66,.33-.19,.69-.29,1.07-.29,.76,0,1.46,.39,1.81,1.01,.38,.68,.4,1.47,.03,2.12-.03,.06-.07,.12-.11,.18,0,0-.05,.07-.06,.08-.42,.54-1.03,.83-1.68,.83Zm0-3.6c-.26,0-.52,.07-.74,.2-.43,.25-.7,.69-.74,1.18,0,.04,0,.08,0,.11,0,.05,0,.1,0,.15,.04,.3,.14,.56,.31,.77l.13,.14s.11,.1,.18,.15c.62,.45,1.56,.31,2.04-.3l.3,.11-.26-.17s.05-.08,.08-.13c.26-.47,.25-1.02-.03-1.52-.25-.43-.73-.7-1.28-.7Z" />
                                    <path className="hiw-step1-7" d="M354.08,248.9c-.44,0-.86-.14-1.22-.39-.1-.07-.19-.15-.27-.23l-.09-.09-.08-.09c-.24-.3-.39-.66-.43-1.04,0-.01,0-.06,0-.07,0-.03-.01-.11-.01-.19,0-.05,0-.11,0-.16,.05-.68,.44-1.3,1.03-1.66,.02,0,.04-.02,.06-.03,.27-.17,.64-.27,1.01-.27,.76,0,1.46,.39,1.81,1.01,.38,.68,.4,1.47,.03,2.12-.03,.06-.07,.12-.11,.18,0,0-.05,.07-.05,.08-.43,.54-1.03,.84-1.68,.84Zm-1.05-1.06c.06,.06,.12,.11,.19,.16,.62,.45,1.56,.31,2.04-.3l.3,.11-.26-.17s.05-.08,.08-.13c.26-.47,.25-1.02-.03-1.52-.25-.43-.73-.7-1.28-.7-.26,0-.52,.07-.74,.2-.43,.25-.7,.69-.74,1.18,0,.04,0,.08,0,.11,0,.05,0,.1,0,.15,.04,.3,.14,.55,.31,.77l.06,.08-.16,.28,.22-.22Z" />
                                    <path className="hiw-step1-7" d="M352.26,246.29c-.05,0-.11-.01-.16-.04-.87-.51-1.63-1.23-2.18-2.08,0-.01-.03-.05-.03-.06-.61-.93-.96-2.04-1-3.19-.08-1.87,.64-3.67,1.97-4.96,.12-.12,.32-.12,.44,0,.12,.12,.12,.32,0,.44-1.2,1.17-1.85,2.8-1.78,4.48,.04,1.05,.36,2.05,.92,2.91,0,.01,.03,.05,.03,.06,.49,.75,1.17,1.4,1.96,1.87,.15,.09,.2,.28,.11,.42-.06,.1-.16,.15-.27,.15Z" />
                                </g>
                                <rect className="hiw-step1-7" x="330.46" y="240.59" width="18.53" height=".62" />
                            </g>
                            <path className="hiw-step1-7" d="M348.77,254.34l-1.49-1.49c-2.23-2.23-5.2-3.46-8.35-3.46h-8.77v-.62h8.77c3.32,0,6.44,1.29,8.79,3.64l1.49,1.49-.44,.44Z" />
                            <rect className="hiw-step1-7" x="338" y="248.04" width=".62" height="7.13" transform="translate(-78.82 312.92) rotate(-45)" />
                            <g>
                                <path className="hiw-step1-7" d="M330.16,225.86h-1.99c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h1.99c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z" />
                                <path className="hiw-step1-7" d="M305.32,227.76c-.52,0-1-.23-1.32-.64l-.06-.08s-.06-.09-.09-.14c-.13-.24-.21-.52-.21-.8,0-.92,.75-1.68,1.68-1.68,.3,0,.59,.08,.84,.23,.48,.28,.79,.78,.83,1.32,0,.04,0,.08,0,.13,0,.06,0,.13-.01,.19-.04,.32-.16,.6-.35,.84l-.07,.08-.04,.05c-.09,.09-.16,.15-.23,.2-.29,.21-.62,.31-.97,.31Zm0-2.73c-.59,0-1.06,.47-1.06,1.06,0,.18,.05,.35,.13,.51,.02,.04,.04,.07,.06,.1,.4,.51,1.04,.57,1.48,.25,.05-.03,.09-.07,.13-.11l.09-.1c.12-.15,.19-.33,.21-.51,0-.06,0-.1,0-.14v-.08c-.03-.34-.22-.66-.52-.83-.17-.1-.35-.15-.53-.15Z" />
                                <path className="hiw-step1-7" d="M312.49,232.93c-.65,0-1.26-.29-1.66-.81l-.04-.05-.04-.05c-.04-.06-.08-.12-.11-.18-.17-.3-.26-.65-.26-1.01,0-1.16,.95-2.11,2.11-2.11,.37,0,.74,.1,1.06,.29,.61,.36,.99,.98,1.04,1.67,0,.05,0,.1,0,.16,0,.08,0,.16-.01,.24-.05,.4-.2,.76-.44,1.06-.03,.03-.05,.07-.08,.1l-.04,.05c-.12,.12-.21,.2-.3,.27-.36,.26-.79,.39-1.23,.39Zm-1.18-1.19c.48,.61,1.41,.75,2.04,.3,.06-.05,.13-.1,.19-.16l.29,.15-.23-.2,.06-.07c.17-.21,.27-.46,.3-.73,.01-.09,.01-.14,.01-.19,0-.04,0-.07,0-.11-.04-.49-.31-.93-.73-1.18-.23-.14-.49-.21-.75-.21-.83,0-1.49,.67-1.49,1.49,0,.25,.06,.5,.19,.72,.03,.05,.05,.09,.08,.13l-.21,.24,.26-.17Z" />
                                <path className="hiw-step1-7" d="M320.83,228.19c-.65,0-1.26-.29-1.66-.81l-.08-.11c-.04-.05-.07-.11-.11-.18-.17-.31-.26-.66-.26-1.01,0-1.16,.95-2.11,2.11-2.11,.38,0,.74,.1,1.06,.29,.61,.36,.99,.98,1.04,1.67,0,.05,0,.1,0,.16,0,.08,0,.16-.01,.24-.05,.4-.2,.76-.44,1.06l-.08,.1-.08,.09c-.08,.08-.17,.16-.26,.22-.36,.26-.78,.39-1.23,.39Zm-1.19-1.21c.5,.63,1.42,.76,2.05,.31,.07-.05,.13-.1,.19-.16l.29,.14-.23-.2,.05-.06c.18-.23,.28-.48,.32-.74,.01-.09,.01-.14,.01-.19,0-.04,0-.07,0-.11-.04-.49-.31-.93-.73-1.18-.23-.14-.49-.21-.75-.21-.83,0-1.49,.67-1.49,1.49,0,.25,.06,.5,.19,.72,.03,.05,.05,.09,.08,.13l-.22,.23,.26-.17Z" />
                                <path className="hiw-step1-7" d="M330.16,233.72h-4.71c-2.68,0-5.19-1.04-7.08-2.93l-1.73-1.73c-1.8-1.8-4.2-2.8-6.75-2.8h-2.77c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h2.77c2.72,0,5.27,1.06,7.19,2.98l1.73,1.73c1.78,1.78,4.14,2.75,6.65,2.75h4.71c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z" />
                                <path className="hiw-step1-7" d="M318.63,231.13h-4.34c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h4.34c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z" />
                                <path className="hiw-step1-7" d="M323.42,215.9c-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05,2.05,.92,2.05,2.05-.92,2.05-2.05,2.05Zm0-3.48c-.79,0-1.43,.64-1.43,1.43s.64,1.43,1.43,1.43,1.43-.64,1.43-1.43-.64-1.43-1.43-1.43Z" />
                                <path className="hiw-step1-7" d="M322.33,225.06c-.1,0-.19-.04-.25-.13-.1-.14-.07-.33,.07-.43l2.8-2.03c.14-.1,.33-.07,.43,.07,.1,.14,.07,.33-.07,.43l-2.8,2.03c-.05,.04-.12,.06-.18,.06Z" />
                                <path className="hiw-step1-7" d="M326.69,217.35c-.08,0-.16-.03-.22-.09l-2.01-2.01c-.12-.12-.12-.32,0-.44s.32-.12,.44,0l2.01,2.01c.12,.12,.12,.32,0,.44-.06,.06-.14,.09-.22,.09Z" />
                                <path className="hiw-step1-7" d="M330.16,217.35h-3.47c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h3.47c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z" />
                                <g>
                                    <path className="hiw-step1-7" d="M305.32,260.06c-.65,0-1.26-.29-1.66-.81l-.08-.1c-.04-.06-.07-.12-.11-.18-.17-.31-.26-.66-.26-1.01,0-1.16,.95-2.11,2.11-2.11,.38,0,.74,.1,1.06,.29,.61,.36,.99,.98,1.04,1.66,0,.05,0,.1,0,.16,0,.08,0,.16-.01,.24-.05,.4-.2,.76-.44,1.06l-.08,.1s-.04,.05-.05,.05c-.12,.12-.21,.2-.3,.27-.36,.26-.78,.39-1.23,.39Zm0-3.6c-.83,0-1.49,.67-1.49,1.49,0,.25,.06,.5,.19,.72,.03,.05,.05,.09,.08,.13l.04,.05c.5,.63,1.42,.76,2.05,.31,.07-.05,.13-.1,.19-.16l.29,.15-.23-.2,.07-.08c.16-.21,.27-.46,.3-.72,.01-.09,.01-.14,.01-.19,0-.04,0-.07,0-.11-.04-.49-.31-.93-.73-1.18-.23-.14-.49-.21-.75-.21Z" />
                                    <g>
                                        <path className="hiw-step1-7" d="M305.29,242.84c-.65,0-1.26-.29-1.66-.81l-.07-.1c-.04-.06-.08-.12-.11-.18-.17-.31-.26-.66-.26-1.01,0-1.16,.95-2.11,2.11-2.11,.38,0,.74,.1,1.06,.29,.61,.36,.99,.98,1.04,1.66,0,.05,0,.11,0,.16,0,.08,0,.16-.01,.24-.05,.41-.2,.76-.44,1.07l-.08,.1s-.04,.05-.05,.05c-.12,.12-.21,.2-.3,.26-.36,.26-.79,.39-1.23,.39Zm0-3.6c-.83,0-1.49,.67-1.49,1.49,0,.25,.06,.5,.19,.72,.03,.05,.05,.09,.08,.13l.04,.05c.5,.63,1.42,.76,2.05,.31,.07-.05,.13-.1,.19-.16l.29,.15-.23-.2,.06-.08c.16-.21,.27-.46,.3-.73,.01-.09,.01-.14,.01-.19,0-.04,0-.08,0-.11-.04-.48-.31-.92-.73-1.17-.23-.14-.49-.21-.75-.21Z" />
                                        <path className="hiw-step1-7" d="M307.11,246.29c-.11,0-.21-.05-.27-.15-.09-.15-.04-.34,.11-.42,.79-.46,1.47-1.12,1.97-1.88,.58-.9,.89-1.9,.94-2.94,.07-1.69-.58-3.32-1.78-4.49-.12-.12-.12-.31,0-.44,.12-.12,.31-.13,.44,0,1.33,1.29,2.05,3.09,1.97,4.95-.05,1.16-.4,2.27-1.02,3.22-.57,.89-1.33,1.61-2.2,2.12-.05,.03-.1,.04-.16,.04Z" />
                                        <rect className="hiw-step1-7" x="310.18" y="240.59" width="19.99" height=".62" />
                                    </g>
                                    <path className="hiw-step1-7" d="M306.4,256.71c-.08,0-.16-.03-.22-.09-.12-.12-.12-.32,0-.44l4.29-4.29c2.01-2.01,4.68-3.12,7.53-3.12h12.09c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31h-12.09c-2.68,0-5.2,1.04-7.09,2.94l-4.29,4.29c-.06,.06-.14,.09-.22,.09Z" />
                                </g>
                            </g>
                            <path className="hiw-step1-7" d="M302.72,252.63s-.05,0-.07,0c-.24-.06-.48-.11-.72-.18-.16-.05-.33-.1-.5-.15-4.99-1.68-8.32-6.32-8.32-11.56,0-4.23,2.15-8.1,5.75-10.34,.47-.29,.95-.55,1.44-.76,.33-.16,.7-.31,1.18-.46,.68-.22,1.36-.39,2.03-.48,.16-.02,.33,.09,.35,.26,.02,.17-.09,.32-.26,.35-.63,.09-1.27,.24-1.92,.45-.45,.15-.8,.29-1.11,.44-.47,.2-.93,.45-1.37,.72-3.42,2.13-5.46,5.8-5.46,9.81,0,4.97,3.16,9.38,7.87,10.96,.18,.06,.34,.11,.51,.16,.23,.07,.46,.12,.69,.18,.17,.04,.27,.21,.23,.37-.03,.14-.16,.24-.3,.24Z" />
                            <circle className="hiw-step1-21" cx="343.86" cy="240.9" r="2.92" />
                            <rect className="hiw-step1-6" x="293.15" y="221.47" width="74.03" height="38.86" />
                        </g>
                        <text className="hiw-step1-9" transform="translate(301.9 176.52)">
                            <tspan x="0" y="0">Artificial</tspan>
                            <tspan x="-9.58" y="16.8">Intelligence</tspan>
                        </text>
                    </g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <polygon className="hiw-step1-74" points="323.57 418.17 308.06 409.21 308.06 364.13 323.57 373.09 323.57 418.17" />
                                    <polygon className="hiw-step1-61" points="292.54 418.17 308.06 409.21 308.06 364.13 292.54 373.09 292.54 418.17" />
                                </g>
                                <polygon className="hiw-step1-45" points="308.06 427.13 292.54 418.17 292.54 373.09 308.06 382.04 308.06 427.13" />
                                <polygon className="hiw-step1-42" points="308.06 427.13 323.57 418.17 323.57 373.09 308.06 382.04 308.06 427.13" />
                            </g>
                            <g>
                                <polygon className="hiw-step1-33" points="367.74 424.81 352.23 433.77 336.71 424.81 352.23 415.85 367.74 424.81" />
                                <path className="hiw-step1-7" d="M352.23,434.13c-.06,0-.12-.02-.18-.05l-15.52-8.96c-.11-.06-.18-.18-.18-.31s.07-.25,.18-.31l15.52-8.96c.11-.06,.25-.06,.36,0l15.52,8.96c.11,.06,.18,.18,.18,.31s-.07,.25-.18,.31l-15.52,8.96c-.06,.03-.12,.05-.18,.05Zm-14.8-9.32l14.8,8.54,14.8-8.54-14.8-8.54-14.8,8.54Z" />
                            </g>
                            <g>
                                <polygon className="hiw-step1-33" points="323.57 424.81 308.06 433.77 292.54 424.81 308.06 415.85 323.57 424.81" />
                                <path className="hiw-step1-7" d="M308.06,434.13c-.06,0-.12-.02-.18-.05l-15.52-8.96c-.11-.06-.18-.18-.18-.31s.07-.25,.18-.31l15.52-8.96c.11-.06,.25-.06,.36,0l15.52,8.96c.11,.06,.18,.18,.18,.31s-.07,.25-.18,.31l-15.52,8.96c-.06,.03-.12,.05-.18,.05Zm-14.8-9.32l14.8,8.54,14.8-8.54-14.8-8.54-14.8,8.54Z" />
                            </g>
                            <polygon className="hiw-step1-27" points="323.57 418.17 308.06 427.13 292.54 418.17 308.06 409.21 323.57 418.17" />
                            <polygon className="hiw-step1-33" points="308.06 409.21 292.54 418.17 292.54 400.26 308.06 391.3 308.06 409.21" />
                            <polygon className="hiw-step1-33" points="323.57 418.17 308.06 409.21 308.06 391.3 323.57 400.26 323.57 418.17" />
                            <g>
                                <polygon className="hiw-step1-10" points="318.82 415.42 308.06 421.64 297.3 415.42 308.06 409.21 318.82 415.42" />
                                <polygon className="hiw-step1-10" points="308.06 409.21 297.3 415.42 297.3 403 308.06 396.79 308.06 409.21" />
                                <polygon className="hiw-step1-10" points="308.06 421.64 297.3 415.42 297.3 403 308.06 409.21 308.06 421.64" />
                                <polygon className="hiw-step1-10" points="318.82 415.42 308.06 409.21 308.06 396.79 318.82 403 318.82 415.42" />
                                <polygon className="hiw-step1-10" points="318.82 403 308.06 409.21 297.3 403 308.06 396.79 318.82 403" />
                                <polygon className="hiw-step1-10" points="318.82 415.42 308.06 421.64 308.06 409.21 318.82 403 318.82 415.42" />
                            </g>
                            <polygon className="hiw-step1-17" points="308.06 427.13 292.54 418.17 292.54 400.26 308.06 409.21 308.06 427.13" />
                            <polygon className="hiw-step1-11" points="323.57 400.26 308.06 409.21 292.54 400.26 308.06 391.3 323.57 400.26" />
                            <polygon className="hiw-step1-31" points="323.57 418.17 308.06 427.13 308.06 409.21 323.57 400.26 323.57 418.17" />
                            <g>
                                <polygon className="hiw-step1-27" points="367.74 418.17 352.23 427.13 336.71 418.17 352.23 409.21 367.74 418.17" />
                                <polygon className="hiw-step1-33" points="352.23 409.21 336.71 418.17 336.71 400.26 352.23 391.3 352.23 409.21" />
                                <polygon className="hiw-step1-33" points="367.74 418.17 352.23 409.21 352.23 391.3 367.74 400.26 367.74 418.17" />
                                <g>
                                    <polygon className="hiw-step1-10" points="362.98 415.42 352.23 421.64 341.47 415.42 352.23 409.21 362.98 415.42" />
                                    <polygon className="hiw-step1-10" points="352.23 409.21 341.47 415.42 341.47 403 352.23 396.79 352.23 409.21" />
                                    <polygon className="hiw-step1-10" points="352.23 421.64 341.47 415.42 341.47 403 352.23 409.21 352.23 421.64" />
                                    <polygon className="hiw-step1-10" points="362.98 415.42 352.23 409.21 352.23 396.79 362.98 403 362.98 415.42" />
                                    <polygon className="hiw-step1-10" points="362.98 403 352.23 409.21 341.47 403 352.23 396.79 362.98 403" />
                                    <polygon className="hiw-step1-10" points="362.98 415.42 352.23 421.64 352.23 409.21 362.98 403 362.98 415.42" />
                                </g>
                                <polygon className="hiw-step1-18" points="367.74 418.17 352.23 427.13 352.23 409.21 367.74 400.26 367.74 418.17" />
                                <polygon className="hiw-step1-16" points="367.74 400.26 352.23 409.21 336.71 400.26 352.23 391.3 367.74 400.26" />
                                <polygon className="hiw-step1-30" points="336.71 418.17 352.23 427.13 352.23 409.21 336.71 400.26 336.71 418.17" />
                            </g>
                            <circle className="hiw-step1-24" cx="328.09" cy="419.63" r="1.69" />
                            <g>
                                <g>
                                    <path className="hiw-step1-46" d="M331.56,421.96c-.12,0-.24-.06-.31-.18-.1-.17-.04-.39,.13-.49l12.68-7.32c.17-.1,.39-.04,.49,.13,.1,.17,.04,.39-.13,.49l-12.68,7.32c-.06,.03-.12,.05-.18,.05Z" />
                                    <path className="hiw-step1-19" d="M331.56,421.96c-.06,0-.12-.02-.18-.05l-15.52-8.96c-.17-.1-.23-.32-.13-.49,.1-.17,.32-.23,.49-.13l15.52,8.96c.17,.1,.23,.32,.13,.49-.07,.11-.19,.18-.31,.18Z" />
                                </g>
                                <circle className="hiw-step1-28" cx="344.24" cy="414.28" r="1.69" />
                            </g>
                            <circle className="hiw-step1-26" cx="318.82" cy="414.28" r="1.69" />
                            <circle className="hiw-step1-29" cx="318.82" cy="414.28" r="3.32" />
                            <g>
                                <polygon className="hiw-step1-33" points="367.74 390.82 352.23 399.78 336.71 390.82 352.23 381.86 367.74 390.82" />
                                <path className="hiw-step1-7" d="M352.23,400.13c-.06,0-.12-.02-.18-.05l-15.52-8.96c-.11-.06-.18-.18-.18-.31s.07-.25,.18-.31l15.52-8.96c.11-.06,.25-.06,.36,0l15.52,8.96c.11,.06,.18,.18,.18,.31s-.07,.25-.18,.31l-15.52,8.96c-.06,.03-.12,.05-.18,.05Zm-14.8-9.32l14.8,8.54,14.8-8.54-14.8-8.54-14.8,8.54Z" />
                            </g>
                            <g>
                                <polygon className="hiw-step1-33" points="364.34 383.07 352.23 390.06 340.11 383.07 352.23 376.08 364.34 383.07" />
                                <path className="hiw-step1-7" d="M352.23,390.42c-.06,0-.12-.02-.18-.05l-12.11-6.99c-.11-.06-.18-.18-.18-.31s.07-.25,.18-.31l12.11-6.99c.11-.06,.25-.06,.36,0l12.11,6.99c.11,.06,.18,.18,.18,.31s-.07,.25-.18,.31l-12.11,6.99c-.06,.03-.12,.05-.18,.05Zm-11.4-7.35l11.4,6.58,11.4-6.58-11.4-6.58-11.4,6.58Z" />
                            </g>
                            <g>
                                <polygon className="hiw-step1-33" points="361.1 374.87 352.23 379.99 343.36 374.87 352.23 369.74 361.1 374.87" />
                                <path className="hiw-step1-7" d="M352.23,380.35c-.06,0-.12-.02-.18-.05l-8.87-5.12c-.11-.06-.18-.18-.18-.31s.07-.25,.18-.31l8.87-5.12c.11-.06,.25-.06,.36,0l8.87,5.12c.11,.06,.18,.18,.18,.31s-.07,.25-.18,.31l-8.87,5.12c-.06,.03-.12,.05-.18,.05Zm-8.16-5.48l8.16,4.71,8.16-4.71-8.16-4.71-8.16,4.71Z" />
                            </g>
                        </g>
                        <text className="hiw-step1-9" transform="translate(294.3 333.63)">
                            <tspan x="0" y="0">Bloc</tspan>
                            <tspan className="hiw-step1-53" x="29.02" y="0">k</tspan>
                            <tspan x="36.61" y="0">chain</tspan>
                            <tspan x="-7.48" y="16.8">D</tspan>
                            <tspan className="hiw-step1-59" x="2.81" y="16.8">ev</tspan>
                            <tspan x="18.04" y="16.8">elopment</tspan>
                        </text>
                    </g>
                    <g>
                        <g>
                            <g>
                                <rect className="hiw-step1-32" x="123.27" y="403.71" width="5.56" height="21.57" />
                                <path className="hiw-step1-7" d="M128.83,425.73h-5.56c-.25,0-.45-.2-.45-.45v-21.57c0-.25,.2-.45,.45-.45h5.56c.25,0,.45,.2,.45,.45v21.57c0,.25-.2,.45-.45,.45Zm-5.11-.9h4.66v-20.67h-4.66v20.67Z" />
                            </g>
                            <g>
                                <rect className="hiw-step1-32" x="111.77" y="412.97" width="5.56" height="12.31" />
                                <path className="hiw-step1-7" d="M117.33,425.73h-5.56c-.25,0-.45-.2-.45-.45v-12.31c0-.25,.2-.45,.45-.45h5.56c.25,0,.45,.2,.45,.45v12.31c0,.25-.2,.45-.45,.45Zm-5.11-.9h4.66v-11.41h-4.66v11.41Z" />
                            </g>
                            <g>
                                <g>
                                    <rect className="hiw-step1-32" x="134.78" y="384.59" width="5.56" height="40.69" />
                                    <path className="hiw-step1-7" d="M140.33,425.73h-5.56c-.25,0-.45-.2-.45-.45v-40.69c0-.25,.2-.45,.45-.45h5.56c.25,0,.45,.2,.45,.45v40.69c0,.25-.2,.45-.45,.45Zm-5.11-.9h4.66v-39.79h-4.66v39.79Z" />
                                </g>
                                <g>
                                    <rect className="hiw-step1-32" x="146.28" y="388.86" width="5.56" height="36.42" />
                                    <path className="hiw-step1-7" d="M151.84,425.73h-5.56c-.25,0-.45-.2-.45-.45v-36.42c0-.25,.2-.45,.45-.45h5.56c.25,0,.45,.2,.45,.45v36.42c0,.25-.2,.45-.45,.45Zm-5.11-.9h4.66v-35.52h-4.66v35.52Z" />
                                </g>
                            </g>
                            <g>
                                <rect className="hiw-step1-32" x="157.78" y="374.21" width="5.56" height="51.07" />
                                <path className="hiw-step1-7" d="M163.34,425.73h-5.56c-.25,0-.45-.2-.45-.45v-51.07c0-.25,.2-.45,.45-.45h5.56c.25,0,.45,.2,.45,.45v51.07c0,.25-.2,.45-.45,.45Zm-5.11-.9h4.66v-50.17h-4.66v50.17Z" />
                            </g>
                            <g>
                                <line className="hiw-step1-13" x1="108.8" y1="404.57" x2="120.3" y2="395.87" />
                                <path className="hiw-step1-7" d="M108.8,405.24c-.2,0-.41-.09-.54-.27-.22-.3-.17-.72,.13-.94l11.5-8.69c.3-.23,.72-.17,.94,.13s.17,.72-.13,.94l-11.5,8.69c-.12,.09-.26,.14-.41,.14Z" />
                            </g>
                            <g>
                                <line className="hiw-step1-12" x1="120.3" y1="395.87" x2="131.8" y2="374.88" />
                                <path className="hiw-step1-7" d="M120.3,396.55c-.11,0-.22-.03-.32-.08-.33-.18-.45-.59-.27-.91l11.5-21c.18-.33,.59-.45,.91-.27,.33,.18,.45,.59,.27,.91l-11.5,21c-.12,.22-.35,.35-.59,.35Z" />
                            </g>
                            <g>
                                <circle className="hiw-step1-38" cx="120.3" cy="395.87" r="3.15" />
                                <path className="hiw-step1-7" d="M120.3,399.7c-2.11,0-3.82-1.71-3.82-3.82s1.71-3.82,3.82-3.82,3.82,1.71,3.82,3.82-1.71,3.82-3.82,3.82Zm0-6.29c-1.36,0-2.47,1.11-2.47,2.47s1.11,2.47,2.47,2.47,2.47-1.11,2.47-2.47-1.11-2.47-2.47-2.47Z" />
                            </g>
                            <g>
                                <circle className="hiw-step1-49" cx="108.8" cy="404.57" r="3.15" />
                                <path className="hiw-step1-7" d="M108.8,408.39c-2.11,0-3.82-1.71-3.82-3.82s1.71-3.82,3.82-3.82,3.82,1.71,3.82,3.82-1.71,3.82-3.82,3.82Zm0-6.29c-1.36,0-2.47,1.11-2.47,2.47s1.11,2.47,2.47,2.47,2.47-1.11,2.47-2.47-1.11-2.47-2.47-2.47Z" />
                            </g>
                            <rect className="hiw-step1-7" x="136.88" y="370.49" width="1.35" height="11.93" transform="translate(-261.85 409.78) rotate(-74.7)" />
                            <g>
                                <circle className="hiw-step1-62" cx="131.8" cy="374.88" r="3.15" />
                                <path className="hiw-step1-7" d="M131.8,378.7c-2.11,0-3.82-1.71-3.82-3.82s1.71-3.82,3.82-3.82,3.82,1.71,3.82,3.82-1.71,3.82-3.82,3.82Zm0-6.29c-1.36,0-2.47,1.11-2.47,2.47s1.11,2.47,2.47,2.47,2.47-1.11,2.47-2.47-1.11-2.47-2.47-2.47Z" />
                            </g>
                            <rect className="hiw-step1-7" x="138.18" y="368.11" width="21.76" height="1.35" transform="translate(-242.79 300.38) rotate(-58.09)" />
                            <g>
                                <circle className="hiw-step1-71" cx="143.31" cy="378.02" r="3.15" />
                                <path className="hiw-step1-7" d="M143.31,381.85c-2.11,0-3.82-1.71-3.82-3.82s1.71-3.82,3.82-3.82,3.82,1.71,3.82,3.82-1.71,3.82-3.82,3.82Zm0-6.29c-1.36,0-2.47,1.11-2.47,2.47s1.11,2.47,2.47,2.47,2.47-1.11,2.47-2.47-1.11-2.47-2.47-2.47Z" />
                            </g>
                            <rect className="hiw-step1-7" x="159.89" y="356.42" width="1.35" height="13.7" transform="translate(-231.68 300.87) rotate(-57.12)" />
                            <g>
                                <circle className="hiw-step1-60" cx="154.81" cy="359.55" r="3.15" />
                                <path className="hiw-step1-7" d="M154.81,363.37c-2.11,0-3.82-1.71-3.82-3.82s1.71-3.82,3.82-3.82,3.82,1.71,3.82,3.82-1.71,3.82-3.82,3.82Zm0-6.29c-1.36,0-2.47,1.11-2.47,2.47s1.11,2.47,2.47,2.47,2.47-1.11,2.47-2.47-1.11-2.47-2.47-2.47Z" />
                            </g>
                            <g>
                                <circle className="hiw-step1-51" cx="166.31" cy="366.99" r="3.15" />
                                <path className="hiw-step1-7" d="M166.31,370.81c-2.11,0-3.82-1.71-3.82-3.82s1.71-3.82,3.82-3.82,3.82,1.71,3.82,3.82-1.71,3.82-3.82,3.82Zm0-6.29c-1.36,0-2.47,1.11-2.47,2.47s1.11,2.47,2.47,2.47,2.47-1.11,2.47-2.47-1.11-2.47-2.47-2.47Z" />
                            </g>
                        </g>
                        <text className="hiw-step1-9" transform="translate(89.64 342.03)">
                            <tspan x="0" y="0">Data Analytics</tspan>
                        </text>
                    </g>
                    <g>
                        <text className="hiw-step1-9" transform="translate(81.67 656.25)">
                            <tspan x="0" y="0">Intern</tspan>
                            <tspan className="hiw-step1-44" x="38.19" y="0">e</tspan>
                            <tspan x="45.91" y="0">t of Things</tspan>
                        </text>
                        <g>
                            <circle className="hiw-step1-25" cx="121.69" cy="727.56" r="4.81" />
                            <circle className="hiw-step1-20" cx="153.15" cy="729.53" r="4.81" />
                            <circle className="hiw-step1-22" cx="144.57" cy="720.69" r="4.81" />
                            <path className="hiw-step1-23" d="M139.37,713.05h-4.81c0,2.64,2.17,4.81,4.81,4.81s4.81-2.17,4.81-4.81h-4.81Z" />
                            <g>
                                <g>
                                    <g>
                                        <rect className="hiw-step1-7" x="152.89" y="729.53" width=".52" height="8.33" />
                                        <path className="hiw-step1-7" d="M152.96,729.72l-8.58-8.85s-.07-.11-.07-.18v-7.64h.52v7.53l8.5,8.77-.37,.36Z" />
                                    </g>
                                    <path className="hiw-step1-7" d="M143.01,734.23h-.52v-5.6c0-.07,.03-.14,.08-.18l4.75-4.75,.37,.37-4.67,4.67v5.49Z" />
                                    <g>
                                        <circle className="hiw-step1-75" cx="142.75" cy="734.23" r="1.82" />
                                        <path className="hiw-step1-7" d="M142.75,736.31c-1.15,0-2.08-.93-2.08-2.08s.93-2.08,2.08-2.08,2.08,.93,2.08,2.08-.93,2.08-2.08,2.08Zm0-3.64c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56,1.56-.7,1.56-1.56-.7-1.56-1.56-1.56Z" />
                                    </g>
                                    <g>
                                        <circle className="hiw-step1-66" cx="153.15" cy="737.87" r="1.82" />
                                        <path className="hiw-step1-7" d="M153.15,739.95c-1.15,0-2.08-.93-2.08-2.08s.93-2.08,2.08-2.08,2.08,.93,2.08,2.08-.93,2.08-2.08,2.08Zm0-3.64c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56,1.56-.7,1.56-1.56-.7-1.56-1.56-1.56Z" />
                                    </g>
                                </g>
                                <g>
                                    <path className="hiw-step1-7" d="M156.87,724.75l-7.29-7.29s-.08-.11-.08-.18v-4.23h.52v4.12l7.21,7.21-.37,.37Z" />
                                    <g>
                                        <circle className="hiw-step1-36" cx="157.06" cy="724.57" r="1.82" />
                                        <path className="hiw-step1-7" d="M157.06,726.65c-1.15,0-2.08-.93-2.08-2.08s.93-2.08,2.08-2.08,2.08,.93,2.08,2.08-.93,2.08-2.08,2.08Zm0-3.64c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56,1.56-.7,1.56-1.56-.7-1.56-1.56-1.56Z" />
                                    </g>
                                </g>
                                <g>
                                    <path className="hiw-step1-7" d="M121.88,727.75l-.37-.37,7.21-7.21v-7.12h.52v7.23c0,.07-.03,.14-.08,.18l-7.29,7.29Z" />
                                    <g>
                                        <circle className="hiw-step1-69" cx="121.69" cy="727.56" r="1.82" />
                                        <path className="hiw-step1-7" d="M121.69,729.64c-1.15,0-2.08-.93-2.08-2.08s.93-2.08,2.08-2.08,2.08,.93,2.08,2.08-.93,2.08-2.08,2.08Zm0-3.64c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56,1.56-.7,1.56-1.56-.7-1.56-1.56-1.56Z" />
                                    </g>
                                </g>
                                <g>
                                    <rect className="hiw-step1-7" x="139.11" y="713.05" width=".52" height="12.93" />
                                    <g>
                                        <circle className="hiw-step1-70" cx="139.37" cy="725.98" r="1.82" />
                                        <path className="hiw-step1-7" d="M139.37,728.06c-1.15,0-2.08-.93-2.08-2.08s.93-2.08,2.08-2.08,2.08,.93,2.08,2.08-.93,2.08-2.08,2.08Zm0-3.64c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56,1.56-.7,1.56-1.56-.7-1.56-1.56-1.56Z" />
                                    </g>
                                </g>
                                <g>
                                    <path className="hiw-step1-7" d="M130.3,732.2h-.52l.1-9.25c0-.07,.03-.14,.09-.19l3.94-3.57v-6.14h.52v6.25c0,.07-.03,.14-.09,.19l-3.95,3.57-.1,9.13Z" />
                                    <g>
                                        <circle className="hiw-step1-47" cx="130.04" cy="732.61" r="1.82" />
                                        <path className="hiw-step1-7" d="M130.04,734.69c-1.15,0-2.08-.93-2.08-2.08s.93-2.08,2.08-2.08,2.08,.93,2.08,2.08-.93,2.08-2.08,2.08Zm0-3.64c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56,1.56-.7,1.56-1.56-.7-1.56-1.56-1.56Z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <path className="hiw-step1-10" d="M163.95,687.64c.06-.56,.1-1.13,.1-1.71,0-8.39-6.8-15.2-15.2-15.2-6.97,0-12.84,4.7-14.63,11.1-1.89-1.14-4.11-1.8-6.48-1.8-6.93,0-12.55,5.62-12.55,12.55,0,1.58,.29,3.08,.82,4.47h-4.52c-4.42,0-8,3.58-8,8h0c0,4.42,3.58,8,8,8h51.75c7.02,0,12.72-5.69,12.72-12.72s-5.31-12.33-12.01-12.7Z" />
                                <path className="hiw-step1-7" d="M163.24,713.83h-51.75c-4.84,0-8.78-3.94-8.78-8.78s3.94-8.78,8.78-8.78h3.44c-.35-1.2-.52-2.44-.52-3.69,0-7.35,5.98-13.33,13.33-13.33,2.1,0,4.16,.49,6.02,1.43,2.21-6.37,8.28-10.73,15.09-10.73,8.81,0,15.98,7.17,15.98,15.98,0,.33-.01,.66-.03,1,6.76,.77,11.94,6.52,11.94,13.41,0,7.44-6.06,13.5-13.5,13.5Zm-51.75-16c-3.98,0-7.22,3.24-7.22,7.22s3.24,7.22,7.22,7.22h51.75c6.58,0,11.94-5.36,11.94-11.94s-4.95-11.57-11.27-11.92c-.21-.01-.41-.11-.55-.27-.14-.16-.2-.38-.18-.59,.06-.55,.09-1.1,.09-1.62,0-7.95-6.47-14.42-14.42-14.42-6.44,0-12.15,4.33-13.88,10.53-.07,.23-.24,.43-.46,.51-.23,.09-.48,.07-.69-.06-1.83-1.1-3.93-1.69-6.07-1.69-6.49,0-11.77,5.28-11.77,11.77,0,1.44,.26,2.85,.77,4.19,.09,.24,.06,.51-.09,.72-.15,.21-.39,.34-.64,.34h-4.52Z" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <text className="hiw-step1-9" transform="translate(288.99 656.25)">
                            <tspan x="0" y="0">N</tspan>
                            <tspan className="hiw-step1-44" x="10.04" y="0">o</tspan>
                            <tspan x="18.06" y="0">t Su</tspan>
                            <tspan className="hiw-step1-43" x="43.51" y="0">r</tspan>
                            <tspan className="hiw-step1-67" x="48.87" y="0">e </tspan>
                            <tspan className="hiw-step1-72" x="60.62" y="0">Y</tspan>
                            <tspan className="hiw-step1-44" x="69.37" y="0">e</tspan>
                            <tspan x="77.08" y="0">t</tspan>
                        </text>
                        <g>
                            <g>
                                <circle className="hiw-step1-41" cx="305.47" cy="732.45" r="2.58" />
                                <path className="hiw-step1-7" d="M305.47,735.76c-1.82,0-3.31-1.48-3.31-3.31s1.48-3.31,3.31-3.31,3.31,1.48,3.31,3.31-1.48,3.31-3.31,3.31Zm0-5.17c-1.03,0-1.86,.83-1.86,1.86s.83,1.86,1.86,1.86,1.86-.83,1.86-1.86-.83-1.86-1.86-1.86Z" />
                            </g>
                            <g>
                                <circle className="hiw-step1-68" cx="298.72" cy="737.35" r="1.88" />
                                <path className="hiw-step1-7" d="M298.72,739.95c-1.44,0-2.6-1.17-2.6-2.6s1.17-2.6,2.6-2.6,2.6,1.17,2.6,2.6-1.17,2.6-2.6,2.6Zm0-3.76c-.64,0-1.16,.52-1.16,1.16s.52,1.15,1.16,1.15,1.15-.52,1.15-1.15-.52-1.16-1.15-1.16Z" />
                            </g>
                            <g>
                                <path className="hiw-step1-10" d="M356.76,689.25c.07-.62,.11-1.24,.11-1.88,0-9.22-7.48-16.7-16.7-16.7-7.99,0-14.67,5.62-16.31,13.12-2.62-1.83-5.8-2.9-9.23-2.9-8.91,0-16.14,7.23-16.14,16.14,0,.88,.07,1.75,.21,2.59-4.66,.22-8.37,4.05-8.37,8.77h0c0,4.85,3.93,8.79,8.79,8.79h2.46c1,6.63,6.73,11.71,13.64,11.71,4.16,0,7.89-1.84,10.42-4.76,3.06,3.51,7.57,5.74,12.59,5.74,7.84,0,14.42-5.4,16.21-12.69h1.55c7.72,0,13.97-6.26,13.97-13.97s-5.84-13.55-13.19-13.95Z" />
                                <path className="hiw-step1-7" d="M338.22,730.59c-4.76,0-9.31-1.95-12.59-5.39-2.73,2.81-6.48,4.41-10.41,4.41-6.91,0-12.92-4.99-14.24-11.71h-1.85c-5.25,0-9.51-4.27-9.51-9.51,0-4.82,3.58-8.81,8.28-9.42-.07-.65-.11-1.3-.11-1.94,0-9.3,7.56-16.86,16.86-16.86,3.12,0,6.13,.85,8.78,2.46,2.1-7.41,8.99-12.68,16.77-12.68,9.61,0,17.42,7.82,17.42,17.42,0,.4-.01,.8-.04,1.22,7.42,.79,13.13,7.07,13.13,14.61,0,8.1-6.59,14.7-14.7,14.7h-.99c-2.11,7.5-8.92,12.69-16.77,12.69Zm-12.59-7.18c.21,0,.41,.09,.55,.25,3.04,3.49,7.43,5.49,12.05,5.49,7.37,0,13.75-4.99,15.51-12.14,.08-.32,.37-.55,.7-.55h1.55c7.31,0,13.25-5.94,13.25-13.25s-5.49-12.84-12.51-13.23c-.2-.01-.38-.1-.51-.26-.13-.15-.19-.35-.17-.55,.07-.61,.1-1.22,.1-1.8,0-8.81-7.17-15.98-15.98-15.98-7.45,0-14.02,5.28-15.6,12.55-.05,.24-.22,.43-.44,.52-.23,.09-.48,.06-.68-.08-2.6-1.81-5.64-2.77-8.82-2.77-8.5,0-15.41,6.91-15.41,15.41,0,.82,.07,1.65,.2,2.48,.03,.2-.02,.41-.15,.57-.13,.16-.32,.26-.53,.27-4.31,.2-7.68,3.74-7.68,8.05s3.62,8.06,8.06,8.06h2.46c.36,0,.66,.26,.72,.62,.96,6.32,6.51,11.09,12.92,11.09,3.78,0,7.38-1.64,9.87-4.51,.14-.16,.34-.25,.55-.25h0Z" />
                            </g>
                            <path className="hiw-step1-7" d="M329.94,690.02c1.39,0,2.62,.26,3.68,.79s1.89,1.28,2.49,2.27c.6,.98,.9,2.15,.9,3.5,0,1.52-.33,2.76-.98,3.71-.66,.95-1.55,1.65-2.7,2.11s-2.44,.68-3.9,.68l-.09,2.85h-2.73l-.16-5.01h.95c.91,0,1.75-.06,2.52-.17,.77-.12,1.45-.32,2.03-.62,.58-.3,1.04-.73,1.36-1.32s.49-1.33,.49-2.24c0-.8-.15-1.49-.46-2.06-.31-.57-.74-1.01-1.3-1.32-.56-.31-1.25-.46-2.08-.46-1.23,0-2.2,.32-2.93,.95s-1.09,1.55-1.09,2.76h-2.95c-.02-1.29,.26-2.42,.84-3.38,.58-.96,1.39-1.71,2.44-2.25,1.05-.54,2.27-.81,3.66-.81Zm-1.84,22.61c-.59,0-1.08-.2-1.48-.6-.39-.4-.59-.89-.59-1.46s.19-1.09,.59-1.49c.39-.4,.88-.6,1.48-.6s1.05,.2,1.44,.6c.39,.4,.59,.9,.59,1.49s-.2,1.06-.59,1.46c-.39,.4-.87,.6-1.44,.6Z" />
                        </g>
                    </g>
                    <g>
                        <text className="hiw-step1-9" transform="translate(287.49 490.74)">
                            <tspan x="0" y="0">eComme</tspan>
                            <tspan className="hiw-step1-43" x="58.95" y="0">r</tspan>
                            <tspan x="64.32" y="0">ce/</tspan>
                            <tspan x="2.23" y="16.8">Mar</tspan>
                            <tspan className="hiw-step1-53" x="27.72" y="16.8">k</tspan>
                            <tspan className="hiw-step1-44" x="35.31" y="16.8">e</tspan>
                            <tspan x="43.02" y="16.8">tplace</tspan>
                        </text>
                        <g>
                            <g>
                                <rect className="hiw-step1-10" x="302.64" y="532.9" width="54.99" height="58.02" />
                                <path className="hiw-step1-7" d="M357.95,591.24h-55.63v-58.66h55.63v58.66Zm-54.99-.64h54.35v-57.37h-54.35v57.37Z" />
                            </g>
                            <g>
                                <g>
                                    <path className="hiw-step1-32" d="M300.8,543.84h7.33c0,4.02-3.31,7.33-7.33,7.33s-7.33-3.31-7.33-7.33h7.33Z" />
                                    <path className="hiw-step1-7" d="M300.8,551.82c-4.4,0-7.98-3.58-7.98-7.98,0-.35,.29-.64,.64-.64h14.67c.35,0,.64,.29,.64,.64,0,4.4-3.58,7.98-7.98,7.98Zm-6.66-7.33c.32,3.39,3.19,6.05,6.66,6.05s6.34-2.66,6.66-6.05h-13.32Z" />
                                </g>
                                <g>
                                    <path className="hiw-step1-32" d="M315.47,543.84h7.33c0,4.02-3.31,7.33-7.33,7.33s-7.33-3.31-7.33-7.33h7.33Z" />
                                    <path className="hiw-step1-7" d="M315.47,551.82c-4.4,0-7.98-3.58-7.98-7.98,0-.35,.29-.64,.64-.64h14.67c.35,0,.64,.29,.64,.64,0,4.4-3.58,7.98-7.98,7.98Zm-6.66-7.33c.32,3.39,3.19,6.05,6.66,6.05s6.34-2.66,6.66-6.05h-13.32Z" />
                                </g>
                                <g>
                                    <path className="hiw-step1-32" d="M330.14,543.84h7.33c0,4.02-3.31,7.33-7.33,7.33s-7.33-3.31-7.33-7.33h7.33Z" />
                                    <path className="hiw-step1-7" d="M330.14,551.82c-4.4,0-7.98-3.58-7.98-7.98,0-.35,.29-.64,.64-.64h14.67c.35,0,.64,.29,.64,.64,0,4.4-3.58,7.98-7.98,7.98Zm-6.66-7.33c.32,3.39,3.19,6.05,6.66,6.05s6.34-2.66,6.66-6.05h-13.32Z" />
                                </g>
                                <g>
                                    <path className="hiw-step1-32" d="M344.81,543.84h7.33c0,4.02-3.31,7.33-7.33,7.33s-7.33-3.31-7.33-7.33h7.33Z" />
                                    <path className="hiw-step1-7" d="M344.81,551.82c-4.4,0-7.98-3.58-7.98-7.98,0-.35,.29-.64,.64-.64h14.67c.35,0,.64,.29,.64,.64,0,4.4-3.58,7.98-7.98,7.98Zm-6.66-7.33c.32,3.39,3.19,6.05,6.66,6.05s6.34-2.66,6.66-6.05h-13.32Z" />
                                </g>
                                <g>
                                    <path className="hiw-step1-32" d="M359.48,543.84h7.33c0,4.02-3.31,7.33-7.33,7.33s-7.33-3.31-7.33-7.33h7.33Z" />
                                    <path className="hiw-step1-7" d="M359.48,551.82c-4.4,0-7.98-3.58-7.98-7.98,0-.35,.29-.64,.64-.64h14.67c.35,0,.64,.29,.64,.64,0,4.4-3.58,7.98-7.98,7.98Zm-6.66-7.33c.32,3.39,3.19,6.05,6.66,6.05s6.34-2.66,6.66-6.05h-13.32Z" />
                                </g>
                                <g>
                                    <polygon className="hiw-step1-32" points="313.65 528.97 302.65 528.97 293.47 543.84 308.14 543.84 313.65 528.97" />
                                    <path className="hiw-step1-7" d="M308.14,544.49h-14.67c-.23,0-.45-.13-.56-.33-.11-.2-.11-.45,.01-.65l9.19-14.87c.12-.19,.32-.3,.55-.3h11c.21,0,.41,.1,.53,.28,.12,.17,.15,.39,.07,.59l-5.51,14.87c-.09,.25-.33,.42-.6,.42Zm-13.52-1.28h13.07l5.04-13.59h-9.71l-8.39,13.59Z" />
                                </g>
                                <g>
                                    <polygon className="hiw-step1-32" points="324.65 528.97 313.65 528.97 308.14 543.84 322.81 543.84 324.65 528.97" />
                                    <path className="hiw-step1-7" d="M322.81,544.49h-14.67c-.21,0-.41-.1-.53-.28-.12-.17-.15-.39-.07-.59l5.51-14.87c.09-.25,.33-.42,.6-.42h11c.18,0,.36,.08,.48,.22,.12,.14,.18,.32,.16,.5l-1.84,14.87c-.04,.32-.31,.56-.64,.56Zm-13.75-1.28h13.18l1.68-13.59h-9.82l-5.04,13.59Z" />
                                </g>
                                <g>
                                    <polygon className="hiw-step1-32" points="335.64 528.97 324.65 528.97 322.81 543.84 337.48 543.84 335.64 528.97" />
                                    <path className="hiw-step1-7" d="M337.48,544.49h-14.67c-.18,0-.36-.08-.48-.22-.12-.14-.18-.32-.16-.5l1.84-14.87c.04-.32,.31-.56,.64-.56h11c.32,0,.6,.24,.64,.56l1.84,14.87c.02,.18-.03,.37-.16,.5-.12,.14-.3,.22-.48,.22Zm-13.94-1.28h13.22l-1.68-13.59h-9.86l-1.68,13.59Z" />
                                </g>
                                <g>
                                    <polygon className="hiw-step1-32" points="346.64 528.97 335.64 528.97 337.48 543.84 352.15 543.84 346.64 528.97" />
                                    <path className="hiw-step1-7" d="M352.15,544.49h-14.67c-.32,0-.6-.24-.64-.56l-1.84-14.87c-.02-.18,.03-.37,.16-.5,.12-.14,.3-.22,.48-.22h11c.27,0,.51,.17,.6,.42l5.51,14.87c.07,.2,.05,.42-.07,.59-.12,.17-.32,.28-.53,.28Zm-14.1-1.28h13.18l-5.03-13.59h-9.82l1.68,13.59Z" />
                                </g>
                                <g>
                                    <polygon className="hiw-step1-32" points="357.63 528.97 346.64 528.97 352.15 543.84 366.82 543.84 357.63 528.97" />
                                    <path className="hiw-step1-7" d="M366.82,544.49h-14.67c-.27,0-.51-.17-.6-.42l-5.51-14.87c-.07-.2-.05-.42,.07-.59,.12-.17,.32-.28,.53-.28h11c.22,0,.43,.12,.55,.3l9.19,14.87c.12,.2,.13,.45,.01,.65-.11,.2-.33,.33-.56,.33Zm-14.22-1.28h13.07l-8.39-13.59h-9.71l5.03,13.59Z" />
                                </g>
                            </g>
                            <g>
                                <rect className="hiw-step1-10" x="302.64" y="521.56" width="54.99" height="7.42" />
                                <path className="hiw-step1-7" d="M357.95,529.3h-55.63v-8.06h55.63v8.06Zm-54.99-.64h54.35v-6.77h-54.35v6.77Z" />
                            </g>
                            <g>
                                <rect className="hiw-step1-32" x="333.05" y="558.35" width="16.14" height="32.57" />
                                <path className="hiw-step1-7" d="M349.51,591.24h-16.78v-33.21h16.78v33.21Zm-16.14-.64h15.5v-31.93h-15.5v31.93Z" />
                            </g>
                            <g>
                                <rect className="hiw-step1-55" x="310.93" y="558.35" width="13.53" height="12.07" />
                                <path className="hiw-step1-7" d="M325.43,571.38h-15.46v-13.99h15.46v13.99Zm-13.53-1.93h11.6v-10.14h-11.6v10.14Z" />
                            </g>
                        </g>
                    </g>
                    <g id="box">
                        <rect id="highlightbox-9" className="hiw-step1-4" x="247.84" y="466.89" width="164.6" height="136.3" rx="10.96" ry="10.96" />
                        <circle id="gesture" className="hiw-step1-5" cx="277.1" cy="571.38" r="19.02" />
                    </g>
                </g>
            </g>
        </SVG>
    )
}

export default AnimatedStep1