import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";

import Flexbox from "../Flexbox";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";

const MatchIconAnimated = ({
  fillPerson1 = "#5da8f6",
  strokePerson1 = "#ffffff",
  fillPerson2 = "#ffffff",
  strokePerson2 = "#80b7ff",
  arrowFill = "#386aff"
}) => {
  const animationPlayed = useRef(false);
  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    },
    true
  );
  useEffect(() => {
    anime.set(".person", {
      opacity: 0,
    });
    anime.set(".arrow", {
      opacity: 0,
    });
    var tl = anime.timeline({
      easing: "easeOutExpo",
      duration: 1500,
      autoplay: false,
    });
    // Add children
    tl.add({
      targets: ".circle",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1200,
      delay: function (el, i) {
        return i * 250;
      },
    }).add({
      targets: [".person", ".arrow"],
      opacity: 1,
      duration: 1000,
    });
    if (isVisible) tl.play();
  }, [isVisible]);
  return (
    <Flexbox refID={containerRef} relative alignItems="end" height="80px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 212.51 100"
        height="80px"
        width="100%"
        style={{ objectFit: "contain" }}
      >
        <g id="a" />
        <g id="b">
          <g id="c">
            <g>
              <path
                className="f person"
                fill={fillPerson1}
                stroke={strokePerson1}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3px"
                d="M137.87,71.01v18.42c7.14,4.48,15.59,7.07,24.64,7.07s17.95-2.73,25.21-7.43v-18.06c0-8.45-6.84-15.29-15.29-15.29h-19.27c-8.44,0-15.29,6.84-15.29,15.29Z"
              />
              <circle
                className="f person"
                fill={fillPerson1}
                stroke={strokePerson1}
                strokeLinecap="round"
                cx="162.8"
                strokeWidth="3px"
                cy="43.36"
                r="16.42"
              />
              <g>
                <polygon
                  className="d arrow"
                  fill={arrowFill}
                  points="83.15 57.78 98.53 66.66 98.53 48.9 83.15 57.78"
                />
              </g>
              <path
                className="h person"
                fill={ fillPerson2}
                stroke={strokePerson2}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3px"
                d="M74.64,71.01v18.42c-7.14,4.48-15.59,7.07-24.64,7.07s-17.95-2.73-25.21-7.43v-18.06c0-8.45,6.84-15.29,15.29-15.29h19.27c8.44,0,15.29,6.84,15.29,15.29Z"
              />
              <path
                className="g circle"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="7px"
                stroke={arrowFill}
                fill="none"
                d="M90.71,72.47C86.88,79.39 81.33,85.24 74.64,89.43C67.5,93.91 59.29,96.5 50,96.5C40.71,96.5 32.05,93.77 24.79,89.07C11.98,80.8 3.5,66.39 3.5,50C3.5,24.31 24.32,3.5 50,3.5C73.03,3.5 92.15,20.24 95.85,42.22L117.01,42.22"
              />
              <circle
                className="e person"
                fill={ fillPerson2}
                stroke={strokePerson2}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3px"
                cx="49.71"
                cy="43.36"
                r="16.42"
              />
              <polygon
                className="d arrow"
                fill={arrowFill}
                points="129.36 42.22 113.98 33.34 113.98 51.1 129.36 42.22"
              />
              <g>
                <path
                  className="g circle"
                  stroke={arrowFill}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="7px"
                  fill="none"
                  d="M121.80,27.53C125.63,20.61 131.18,14.76 137.87,10.57C145.01,6.09 153.22,3.5 162.51,3.5C171.8,3.5 180.46,6.23 187.72,10.93C200.53,19.2 209.01,33.61 209.01,50C209.01,75.69 188.19,96.5 162.51,96.5C139.48,96.5 120.36,79.76 116.66,57.78L95.5,57.78"
                />
                <polygon
                  className="d arrow"
                  fill={arrowFill}
                  points="83.15 57.78 98.53 66.66 98.53 48.9 83.15 57.78"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Flexbox>
  );
};

export default MatchIconAnimated;
