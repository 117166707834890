import React, { useEffect, useState, useRef } from 'react'
import Flexbox from '../Flexbox'
import styled from 'styled-components'
import { H2, List, ListItem, Icon, Text, ComponentWrapper } from './Section.styles'


import Button from '../Button'
import FadeIn from '../FadeIn'

const P = styled.p`
line-height: ${({ lineHeight }) => lineHeight};
/* font-size: 30px; */
`;

const Section = ({ listContentTextProps, listClassName, itemProps, subTextStyling, button, subtext = [], title, maxWidth = "700px", listContent, justifyContent, flexGrow, flexWrap, itemsPerRow = 2, itemsPerRowMobile = 1, listContainerProps, listItemMargin = 10, gap = "20px", listItemInnerGap = "10px", listParagraphColor, id, props }) => {
    console.log("justifyContent", justifyContent)
    const flexBasis = useRef(listContent.length)
    return (
        <Flexbox position="relative" id={id} column width="100%" alignText="center" padding="50px" {...props}>
            <Flexbox column maxWidth={maxWidth} >
                <FadeIn>
                    <H2 >{title}</H2>
                </FadeIn>

                {subtext.filter(it => it.position === 'start').map((it, index) =>
                    <FadeIn key={index} {...subTextStyling} >
                        <Text key={index} {...subTextStyling} {...it}>{it.text}</Text>
                    </FadeIn>
                )}

                <List gap={listItemMargin + 'px'}
                    col={`repeat(${itemsPerRow},1fr)`}
                    mobileCol={`repeat(${itemsPerRowMobile},1fr)`}
                    justifyContent={justifyContent} wrap={flexWrap} className={listClassName} maxWidth={maxWidth} {...listContainerProps}>
                    {
                        listContent.map((it, idx) =>
                            <ListItem key={idx} flexGrow={flexGrow}
                                flexBasis={`calc(${(100 / itemsPerRow) + '%'} - ${(itemsPerRow) * listItemMargin}px)`}

                                gap={listItemInnerGap} listParagraphColor={listParagraphColor} {...itemProps} {...it['props']}>
                                <FadeIn relative column alignItems="center" threshold={0.7}>
                                    {it.img && <Icon src={it.img} />}
                                    {it['useComponent'] && <ComponentWrapper>{it['useComponent']}</ComponentWrapper>}
                                    {it['heading'] && <h4>{it['heading']}</h4>}
                                    {it['text'] && <P style={{ color: it['textColor'] }} {...listContentTextProps}>{it.text}</P>}
                                </FadeIn>
                            </ListItem>
                        )
                    }
                </List>
                {subtext.filter(it => it.position === 'end').map((it, index) =>
                    <FadeIn key={index} {...subTextStyling}>
                        <Text key={index}>{it.text}</Text>
                    </FadeIn>
                )}
                {button && <Button text={button['text']} width="200px" {...button} />}
            </Flexbox>
            {/* <MatchIconAnimated /> */}
        </Flexbox >
    )
}

export default Section