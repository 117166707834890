import React, { useState, useEffect } from 'react'
import InputField from '../InputField'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import PageContentContainer from '../PageContentContainer'

const Checkout = () => {
    const [state, setState] = useState({
        success: false,
        didSend: false,
        currentBudget: null,
        paymentFieldValue: 0
    })
    return (
        <PageContentContainer background="var(--formBackground)" justifyContent="center">
            <GridContainer >
                <h1>Checkout</h1>
                <InputField
                    border="none; border-bottom: 1px solid #fff"
                    name="price"
                    labelTextAlign="left"
                    labelFontSize="14px"
                    value={state['paymentFieldValue']}
                    display="flex"
                    focusOutline="none"
                    outline="none"
                    padding="0 20px 0 40px"
                    focusBorder="none; border-bottom: 2px solid #fff"
                    flexDirection="column"
                    background="rgba(255,255,255,0.1)"
                    onChange={(value) => {
                        setState(prev => ({ ...prev, paymentFieldValue: value }))
                    }}
                    width="100%"></InputField>
            </GridContainer>

        </PageContentContainer>
    )
}

export default Checkout