import React from 'react'
import PageContentContainer from './PageContentContainer'
import Flexbox from './Flexbox'
import machineLearningMarketing4 from '../images/machineLearningMarketing4.svg'
import quickAndEasy from '../images/quickAndEasy.svg'
import chess from '../images/summit.svg'
import silverPlatter from '../images/silverPlatter.svg'
import sparkInterest from '../images/sparkInterest.svg'
import { RLink } from './PrivacyPolicy'
import { H2 } from './Section/Section.styles'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Button from './ButtonHome'
import ResponsiveImage from './ResponsiveImage'
import summit1x from '../images/summit2.png'
import summit2x from '../images/summit2@2x.png'
import summit3x from '../images/summit2@3x.png'
import summit4x from '../images/summit2@4x.png'
import digging from '../images/diggingThroughReviews.png'
import digging2 from '../images/diggingThroughReviews@2x.png'
import digging3 from '../images/diggingThroughReviews@3x.png'
import digging4 from '../images/diggingThroughReviews@4x.png'
import deliverMatch from '../images/deliverMatchingSoftwareDevelopers.png'
import deliverMatch2 from '../images/deliverMatchingSoftwareDevelopers@2x.png'
import deliverMatch3 from '../images/deliverMatchingSoftwareDevelopers@3x.png'
import deliverMatch4 from '../images/deliverMatchingSoftwareDevelopers@4x.png'
import hiddenGem from '../images/hiddenGem.png'
import hiddenGem2 from '../images/hiddenGem@2x.png'
import hiddenGem3 from '../images/hiddenGem@3x.png'
import hiddenGem4 from '../images/hiddenGem@4x.png'
import timeFlies from '../images/timeFlies.svg'

const H2B = styled.h2`
font-size: 2rem;
text-transform: uppercase;
font-weight: bold;
letter-spacing: -.03em;
line-height: 1.2em;
`;

const H1 = styled.h1`
line-height: 1.2em;
text-transform: uppercase;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
        align-self: center;
    }
`;

const P = styled.p`
margin: 1em 0;
font-weight: 300;
font-size: 1.2rem;
line-height: 1.4em;
`;

const Img = styled.img`
max-width: 450px;
flex-shrink: 1;
width: 100%;
height: auto;
max-height: 90%;
object-fit: contain;
object-position: left;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        max-height: 300px;
        align-self: flex-start;
    }
`;

const GridContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: min-content;
gap: 50px;
width: 90%;
/* max-width: 1200px; */
justify-content: space-around;
align-items: center;
justify-items: center;
>:nth-child(2n){
    justify-self: end;
}
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2,min-content);

    }
`;


const About = ({ title = "About", color = "#fff !important", margin = "calc(var(--navBarHeight) * -1) 0 0", hideButton = false }) => {
    const navigate = useNavigate()
    const props = {
        flexDirection: 'column',
        lineHeight: "1.3em",
        flexBasis: "50%",
        alignItems: "start",
        // flexGrow: '1',
    }
    const props2 = {
        flexWrapMobile: 'wrap'
    }
    return (
        <PageContentContainer
            column
            color={color}
            display="flex"
            position="relative"
            alignText="start"
            margin={margin}
            width="100%"
            height="100%"
            background="linear-gradient(var(--darkBlue),#00478a)"
            // background="linear-gradient(var(--darkBlue),var(--deepBlue))"
            // background="#ffffff"
            justifyContent="start" alignItems="center" flexDirection="row" padding="calc(var(--navBarHeight) + 30px) 0 0">
            <Flexbox column padding="0 50px 50px" mobilePadding="0 5% 50px" gap="5px">
                <h1 style={{ margin: '0', fontSize: '2.2rem' }}>{title}</h1>
                <h2 style={{ fontSize: '1.3rem', margin: '0', fontWeight: '300', textAlign: 'center', maxWidth: '700px', color: 'var(--grey1)' }}>
                    We combine our data-driven matching algorithm and All-Star network of developers to make launching your project easy.
                </h2>
                <ResponsiveImage
                    height="auto"
                    width="100%"
                    flexShrink="1"
                    objectPosition="left"
                    objectFit="contain"
                    maxWidth="608px"
                    margin="20px 0 0"
                    src={deliverMatch}
                    srcSet={[
                        deliverMatch,
                        deliverMatch2,
                        deliverMatch3,
                        deliverMatch4,
                    ]}
                />
            </Flexbox>
            <GridContainer
                // background="#f2f2f2" 
                padding="50px" mobilePadding="30px  5% 50px" justifyContent="space-between" gap="50px" {...props2}>
                <Flexbox column lineHeight="1.3em" {...props} flexGrow="1">
                    <H1>Connecting you with the best development company for your needs</H1>
                    <p>
                        Only a handful of software development services could be said to be at the very top of the field.  While there are countless companies who make these claims, it's only true for a very small number.
                        <br /><br />
                        How do you find them?
                        <br /><br />
                        Most people turn to review sites, but there's a problem.  The only major review site has THOUSANDS of developers with 5 stars.  How can you possibly know which one is the best for your unique situation?<br /><br />
                        That's where we come in.<br /><br />Best Software Devs cuts through the overwhelming numbers to custom match you with the best top-rated developer for your specific needs.

                        <br /><br />We don't just make this possible, we make it quick and easy.
                    </p>
                </Flexbox>
                <ResponsiveImage
                    height="auto"
                    width="100%"
                    flexShrink="1"
                    maxWidth="450px"
                    src={summit1x}
                    srcSet={[
                        summit1x,
                        summit2x,
                        summit3x,
                        summit4x,
                    ]} />
            </GridContainer>
            <GridContainer padding="50px 50px 50px 0" width="100%" mobilePadding="30px 5% 50px 0" justifyContent="space-between" {...props2}>
                {/* <Img src={silverPlatter} /> */}
                <ResponsiveImage
                    height="auto"
                    maxHeight="90%"
                    // objectPosition="left"
                    objectFit="contain"
                    maxHeightMobile="300px"
                    // alignSelfMobile="flex-start"
                    width="100%"
                    flexShrink="1"
                    maxWidth="450px"
                    src={digging}
                    srcSet={[
                        digging,
                        digging2,
                        digging3,
                        digging4,
                    ]} />
                <Flexbox column lineHeight="1.3em"  {...props} 
                // margin="0 0 0 50px" 
                // mobileMargin="0 0 0 5%"
                >

                    <H1>no need to dig through thousands of reviews</H1><p>All developers in our network are top-rated on the #1 review site and have impressive portfolios.  Some work with multi-billion dollar corporations, some specialize in building software for startups.
                        <br /><br />
                        They all have a history of success and a wealth of happy clients.
                        <br /><br />
                        But your needs are unique. <br /><br />You may need a company who specializes in ecommerce web projects and rapid development speed, or a budget friendly mobile developer who makes beautiful user interfaces.  Maybe you need an expert for a specific tech stack, who can build software ready to scale up to a million daily users.
                        <br /><br />
                        By answering just a few simple questions on our form, we'll match you with one developer who is not only top-rated, but the perfect match for your exact needs.
                    </p>
                </Flexbox>
            </GridContainer>
            <Flexbox
                // background="#f2f2f2" 
                padding="50px" mobilePadding="30px 5%" justifyContent="space-between" gap="50px" {...props2} flexWrapMobile="wrap-reverse">
                <Flexbox {...props}  mobileFlexBasis="100%">
                    <H1 id="how-onboarding-works" >Launch your project quickly</H1>
                    <p>
                        We all know time flies.
                        <br /><br />
                        Getting your project launched before competitors can catch up is absolutely critical.<br /><br />Unfortunately, most people are forced to spend far too much time looking for the right developer.  In some cases, searching for a developer can take longer than building your project.
                        <br /><br />
                        No more!<br /><br />
                        We make finding the best developer for your unique needs a quick and easy process, accelerating your launch date.

                    </p>
                </Flexbox>
                <img src={timeFlies} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', width: '35%', minWidth: '200px', margin: '0 auto' }} />
            </Flexbox>
            <Flexbox padding="50px 50px 50px 0" width="100%" mobilePadding="30px 5% 50px 0" justifyContent="space-between" {...props2}>
                {/* <img src={sparkInterest} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', minWidth: '200px', margin: '0 auto' }} /> */}
                <ResponsiveImage
                    height="auto"
                    width="100%"
                    flexShrink="1"
                    objectPosition="left"
                    objectFit="contain"
                    maxWidth="450px"
                    maxHeight="90%"
                    maxHeightMobile="300px"
                    alignSelfMobile="flex-start"
                    margin="20px 0 0"
                    src={hiddenGem}
                    srcSet={[
                        hiddenGem,
                        hiddenGem2,
                        hiddenGem3,
                        hiddenGem4,
                    ]}
                />
                <Flexbox  mobileFlexBasis="100%" column lineHeight="1.3em" flexBasis="50%" {...props} margin="0 0 0 50px" mobileMargin="0 0 0 5%">
                    <H1 style={{ lineHeight: '1.2em' }}>HOW DOES YOUR MATCHING ALGORITHM WORK?</H1>
                    <p style={{ maxWidth: '700px' }}>
                        There may be 6k top-rated devs, but only one hidden gem that's just right for you. <br /><br />Start by entering basic info about your project in our form.  Then, we list a few items and ask which are most important to you, for example development speed or budget friendliness. <br /><br />By analyzing your project type, requirements, and priorities, we match you with the one developer in our system who best meets all your criteria.<br /><br />
                        The developer will then reach out for a free project evaluation.<br /><br /> They'll be able to tell you about development time and cost, and provide you with new ideas and recommendations.</p>
                    {
                        !hideButton && <Button text="Evaluate My Project" boxShadow="0 0 5px rgba(0,0,0.5)" width="100%" maxWidth="400px" onClick={() => navigate('/form', { state: { form_entry: 'About Page - Get Started Button' } })}
                        />}
                </Flexbox>
            </Flexbox>
        </PageContentContainer>
    )
}

export default About