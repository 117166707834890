import React from 'react'
import InputField from '../InputField'
import Flexbox from '../Flexbox'
import Dropdown from '../Dropdown'
const PhoneNumber = () => {
    // value, onclick, innerText
    return (
        <Flexbox background="var(--formBackground)" height="100vh" margin="-50px 0 0">
            <Dropdown />
            <InputField
                maxWidth="500px"
                // onKeyDown={name === 'phoneNumber' ? onKeyDown(`${name}`) : undefined}
                // onKeyUp={name === 'phoneNumber' ? onKeyUp : undefined}
                uppercaseOff={true}
                border="none; border-bottom: 1px solid #fff"
                // border="1px solid #000" 
                labelTextAlign="left"
                labelFontSize="14px"
                focusOutline="none" padding="0 40px 0 20px"
                outline="none"
                focusBorder="none; border-bottom: 2px solid #fff"
                flexDirection="column"
                background="rgba(255,255,255,0.1)"
            />
        </Flexbox>
    )
}

export default PhoneNumber