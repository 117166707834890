import React, { useEffect, useState } from 'react'
import PageContentContainer from './PageContentContainer'
import Flexbox from './Flexbox'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import styled from 'styled-components'
import anime from 'animejs'
import reactimg from '../images/react.svg'
import node from '../images/node.svg'
import mongodb from '../images/mongodb2.svg'
import angular from '../images/angular.svg'
import android from '../images/android.svg'
import ios from '../images/iOS.svg'
import enterprise from '../images/enterpriseSoftwareDevelopment.svg'
import mobileApp from '../images/mobileApp.svg'
import design from '../images/no-design-work-needed.svg'
import security from '../images/security.svg'
import php from '../images/php.svg'
import wordpress from '../images/wordpress.svg'
import mysql from '../images/mysql.svg'
import twitter from '../images/twitter.svg'
import freshworks from '../images/freshworksLogo.svg'
import crmIntegration from '../images/crmIntegration.svg'
import webApp from '../images/webApp.svg'
// import seo from '../images/search.svg'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'
import machineLearning from '../images/artificial-intelligence.svg'
const Nuc2 = styled.div`
    width: ${({ size }) => size * .9 + 'px'};
    height: ${({ size }) => size * .9 + 'px'};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: ${({ color }) => color};
    background: ${({ color }) => `radial-gradient(hsla(${color}, 100%, 50%, 0%),hsla(${color}, 100%, 50%, 0%) 50%,hsla(${color}, 100%, 50%, 70%) 80%,hsla(${color}, 100%, 50%, 100%) 85%)`};
    border-radius: 50%;
    flex-shrink: 0;
    opacity: 0;
    img {
        position: relative;
        height: 100%;
        width: 100%;
    }
    /* background: radial-gradient(rgba(var(--formBlue2Raw), 0),rgba(var(--formBlue2Raw), 1)); */
`;

const Nucleodide = styled.div`
    position: relative;
    animation: 2.1s cubic-bezier(0.42 , 0, 0.58, 1);
    width: ${({ size }) => `${size * 2}px`};
    height: ${({ size }) => `${size * 5}px`};
    display: inline-block;
    /* background: #ff0000; */
    &:before, &:after
    {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: ${({ size }) => `${size}px`};
        height: ${({ size }) => `${size}px`};
        margin-left: ${({ marginLeft }) => marginLeft};
        background: ${({ color }) => color};
        border-radius: 50%;
    }
    &:after{
        margin-left: ${({ marginLeft2 }) => marginLeft2};
    }
    /* span{
        position: absolute;
        top: 7px;
        bottom: -15px;
        left: 50%;
        width: 1px;
        z-index: -2;
    } */
`;

const Line = styled.div`
position: relative;
flex-grow: 1;
height: 2px;
background: linear-gradient(90deg,rgba(var(--formBlue2Raw), 0),rgba(var(--formBlue2Raw), 1), rgba(var(--formBlue2Raw), 0));
margin: 0 20px;
/* transform: scaleX(0); */
`;

const GridContainer2 = styled.div`
    display: grid;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-items: center;
    width: ${({ width }) => width};
    /* border: 1px solid #00ff00; */
    position: ${({ position = 'relative' }) => position};
    flex-shrink: 0;
`;

const SectionContainer = styled.div`
display: flex;
flex-direction: column;
align-items: start;
margin: 0 50px;
height: 100vh;
justify-content: center;
flex-shrink: 0;
scroll-snap-align: start;
`;

const Section = ({ id, title, details, technologies, setVisibleKey }) => {
    const [containerRef, isVisible] = useIntersectionObserver(
        {
            root: null,
            rootMargin: "-50px 0px 0px 0px",
            threshold: 0.5, // half of item height
        },
        false
    );
    useEffect(() => {
        if (isVisible) {
            anime.set(".nucleotide img", { opacity: 0 })
            anime.set(".container .technologies", { opacity: 0 })
            setVisibleKey(id)
        } else {
            // anime.set(".nucleotide img", { opacity: 0 })
            // anime.set(".container .technologies", { opacity: 0 })
        }
    }, [isVisible])
    return (
        <SectionContainer id={id} ref={containerRef} column alignItems="start" margin="0 50px" height="100vh" justifyContent="center" flexShrink="0" scrollSnapAlign="start">
            <h2 style={{ fontSize: '42px', margin: '0 0 20px', fontFamily: 'Poiret One', textAlign: 'left' }}>{title}</h2>
            <Flexbox fontSize="33px" alignText="left" fontWeight="200" >{details}</Flexbox>
        </SectionContainer>)
}

const Helix = () => {
    const helix = Array(15).fill(0)
    const rowOffset = 4
    const height = window.innerHeight / 15//70
    const waveFunction = (helixLength, index) => {
        return Math.round(Math.abs(2 * 250 * Math.sin(Math.PI * 1.45 * (index) / helixLength - Math.PI / 6)))
        // return Math.round(Math.abs(2 * 380 * Math.sin((helixLength - index) / helixLength * (Math.PI * 1.6) )))
    }

    const imgGroup = [
        { img: reactimg, tech: 'React' },
        { img: node, tech: 'Node' },
        { img: mongodb, tech: 'MongoDB' },
    ]
    useEffect(() => {
        anime.set(".container", { width: 0 })
        anime({
            targets: '.nucleotide',
            opacity: 1,
            easing: 'linear',
            duration: 500,
            delay: function (el, i, l) {
                return i * 500 / helix.length;
            },
        })
        anime({
            targets: '.container',
            duration: 500,
            startDelay: 1000,
            easing: 'linear',
            width: function (el, i, l) {
                return waveFunction(l, i);
            },
            delay: function (el, i, l) {
                return i * 500 / helix.length;
            },
        })

        anime({
            targets: 'line',
            scaleX: '100%',
            easing: 'linear',
            duration: 500,
            delay: function (el, i, l) {
                return i * 200 / helix.length;
            },
        })
    }, [])

    const data = {
        "case-1": {
            id: "case-1",
            title: "eCommerce",
            details: "Customized a Shopify site to allow for region-specific product sales. Developed a product recommendation quiz to provide visitors with personalized results.",
            technologies: [
                {
                    img: reactimg,
                    name: "React"
                },
                {
                    img: node,
                    name: "Node"
                },
                {
                    img: mongodb,
                    name: "MongoDB"
                },
            ]
        },
        "case-2": {
            id: "case-2",
            title: "Online Sports Betting Platform",
            details: "Created a betting mobile and web platform that employs a social element for a unique, engaging experience that allows users to create and join leagues and compete against other members.",
            technologies: [
                {
                    img: mongodb,
                    name: "MongoDB"
                },
                {
                    img: angular,
                    name: "Angular"
                },
                {
                    img: android,
                    name: "Android"
                },
                {
                    img: ios,
                    name: "iOS"
                },
            ]
        },
        "case-3": {
            id: "case-3",
            title: "Drivers Management Platform for Car Dealerships",
            details: "Created a mobile application for an auto dealership to dispatch shuttles for customers getting auto services. Built an AI component for optimizing routing, implemented live tracking for inbound shuttles to ensure expedited customer service on arrival. Included chat features, push-notifications, and predictive pricing model to reduce costs and wait-times when ride-sharing services show to be a better choivce than shuttle or valet service options. Included web development so customers could access the information without having to download the mobile app. Created the option for custom branding for different dealerships.",
            technologies: [
                {
                    img: android,
                    name: "Android"
                },
                {
                    img: ios,
                    name: "iOS"
                },
                {
                    name: "Navigation, Route Planning & Geolocation"
                },
                {
                    name: 'Start-up'
                },
                {
                    img: machineLearning,
                    name: "Machine Learning"
                },
                {
                    img: webApp,
                    name: "Web"
                },
            ]
        },
        "case-4": {
            id: "case-4",
            title: "Banking / Financial Mobile App",
            details: "Created a fully-featured mobile banking application with the ability to apply for a loan, add new credit accounts by uploading photo ID, and monitor and initiate payments and transfers.",
            technologies: [

                {
                    img: enterprise,
                    name: "Enterprise"
                },
                {
                    img: mobileApp,
                    name: "App Development"
                },
                {
                    img: design,
                    name: "UI/UX"
                },
                {
                    img: security,
                    name: "Security Driven"
                },
                {
                    img: android,
                    name: "Android"
                },
                {
                    img: ios,
                    name: "iOS"
                },
            ]
        },
        "case-5": {
            id: "case-5",
            title: "Rebuilt A Popular Custom Wordpress Plugin",
            details: "Modernized a popular booking plugin for Wordpress from the ground up and expanded admin control to allow for admin-level rescheduling, service levels, schedule-blocking, and customer notifications.",
            technologies: [

                {
                    img: wordpress,
                    name: "Wordpress"
                },
                {
                    img: reactimg,
                    name: "React"
                },
                {
                    img: php,
                    name: "PHP"
                },
            ]
        },
        "case-6": {
            id: "case-6",
            title: "Social Media Automation Tool",
            details: "Created an easy-to-use tool to create and schedule lists of tweets to auto-publish to Twitter at user-specified intervals to help increase traffic even when not logged-in to Twitter.",
            technologies: [

                {
                    img: twitter,
                    name: "Twitter API"
                },
                {
                    img: php,
                    name: "SEO Tool Development"
                },
                {
                    img: mysql,
                    name: "MySQL"
                },
                {
                    img: php,
                    name: "PHP"
                },
                {
                    img: php,
                    name: "JQuery"
                },
                {
                    img: php,
                    name: "Ajax"
                },

            ]
        },
        "case-7": {
            id: "case-7",
            title: "Automating Workflows & Customer SMS Feedback Integration with CRM",
            details: "Automated customer satisfaction surveys and integrated customer SMS responses into CRM.",
            technologies: [

                {
                    img: wordpress,
                    name: "Automation"
                },
                {
                    img: crmIntegration,
                    name: "CRM Integration"
                },
                {
                    img: freshworks,
                    name: "Freshdesk & Freshsales"
                },
            ]
        },
        "case-9": {
            id: "case-9",
            title: "Online Notary Platform",
            details: "Developed an intuitive, revolutionary online notarization platform.",
            technologies: [

                {
                    img: reactimg,
                    name: "React"
                },
                {
                    img: node,
                    name: "Node"
                },
                {
                    img: mysql,
                    name: "MySQL"
                },
                {
                    img: mysql,
                    name: "Zoom"
                },
                {
                    img: mysql,
                    name: "Adobe Sign"
                },
            ]
        },
        "case-10": {
            id: "case-10",
            title: "eCommerce",
            details: "Developed custom wordpress site to allow users to purchase customized clothing.",
            technologies: [

                {
                    img: reactimg,
                    name: "Woocommerce"
                },
                {
                    img: wordpress,
                    name: "Wordpress"
                },
                {
                    img: mysql,
                    name: "Multi-Carrier Shipping Software"
                },
                {
                    img: mysql,
                    name: "QuickBooks"
                },
            ]
        },
    }
    const [visibleKey, setVisibleKey] = useState(Object.keys(data)[0])
    const { technologies } = data[visibleKey]

    useEffect(() => {
        anime.set(".nucleotide img", { opacity: 0 })
        anime.set(".container .technologies", { opacity: 0 })
        // anime.set(".nucleotide img", { opacity: 0 })
        // anime.set(".container .technologies", { opacity: 0 })
        // anime({
        //     targets: ['.nucleotide img', '.nucleotide .technologies'],
        //     opacity: 1,
        //     easing: 'linear',
        //     duration: 1000,
        //     delay: function (el, i, l) {
        //         return i * 200 / helix.length;
        //     },
        // })
        let tl = anime.timeline({
            duration: 1000,
            autoplay: true
        })
        tl.add({
            targets: ['.nucleotide img'],
            opacity: 1,
            easing: 'linear',
            duration: 1000,
            delay: function (el, i, l) {
                return i * 200 / l;
            },
        }).add({
            targets: ['.container .technologies'],
            opacity: 1,
            easing: 'linear',
            duration: 1000,
            delay: function (el, i, l) {
                return i * 200 / l;
            },
        }, "-=1000")
    }, [visibleKey])

    return (
        <PageContentContainer
            minHeight="100vh"
            margin="calc(var(--navBarHeight) * -1) 0 0"
            height="100%"
            padding="0 50px"
            color="#fff"
            scrollBehavior="smooth"
            scrollSnapType="y mandatory"
            background="#000"
        // background="linear-gradient(var(--darkBlue),var(--deepBlue))"
        >
            <GridContainer2 column position="absolute" height="100%" width={Math.round(Math.abs(2 * 250 * Math.cos(0))) + 'px'} justifyItems="center">
                {
                    helix.map((it, index) => {
                        let width = waveFunction(helix.length, index)
                        return (
                            <>
                                <GridItem

                                    // border="1px solid #ff0000" 
                                    className="container" align="center" display="flex" justifyContent="center" height={`${height}px`}
                                    // width={`${width > height ? width : height}px`} 
                                    col="1 / span 1" row={index + 1}>
                                    <Nuc2
                                        size={height}
                                        color={(360 - 128) / helix.length * (helix.length - index) + 128}
                                        // color={`hsl(${360 / helix.length * (helix.length - index)}, 100%, 50%)`}
                                        className="nucleotide">
                                        {
                                            (index > rowOffset && index < ((rowOffset + 1) + technologies.length) && technologies[index - (rowOffset + 1)]['img']) && <img src={technologies[index - (rowOffset + 1)]['img']} />
                                        }
                                    </Nuc2>
                                    {
                                        width > height &&
                                        <>
                                            <Flexbox width="100%" height="100%" justifyContent="center">
                                                <Line className="line" />
                                                {
                                                    (index > rowOffset && index < ((rowOffset + 1) + technologies.length)) &&
                                                    <>
                                                        <Flexbox fontWeight="300" className="technologies">{technologies[index - (rowOffset + 1)]['name']}</Flexbox>
                                                        <Line className="line" />
                                                    </>
                                                }
                                                {index === 4 &&
                                                    <>
                                                        <Flexbox position="relative" color="#01fecf">
                                                            Project DNA
                                                        </Flexbox>
                                                        <Line className="line" />
                                                    </>
                                                }
                                            </Flexbox>
                                            <Nuc2
                                                size={height}
                                                // color={`hsl(${360 / helix.length * (helix.length - index)}, 100%, 50%)`}
                                                // color={360 / helix.length * (helix.length - index)}
                                                color={(360 - 128) / helix.length * (helix.length - index) + 128}
                                                className="nucleotide">
                                                {
                                                    (index > rowOffset && index < ((rowOffset + 1) + technologies.length) && technologies[index - (rowOffset + 1)]['img']) && <img src={technologies[index - (rowOffset + 1)]['img']} />
                                                }
                                            </Nuc2>
                                        </>
                                    }
                                </GridItem>
                            </>
                        )
                    })
                }

            </GridContainer2>
            <Flexbox row scrollSnapAlign="start" scrollSnapType="y mandatory" >
                <Flexbox width={Math.round(Math.abs(2 * 250 * Math.cos(0))) + 'px'} flexShrink="0"></Flexbox>
                <Flexbox column alignItems="start" margin="0 50px" height="100vh" width="100%" overflow="hidden">
                    <Flexbox column scrollSnapType="y mandatory" overflow="auto scroll" padding="0 30px 0 0" boxSizing="content-box" width="100%" height="100%">
                        {
                            Object.keys(data).map(it => {
                                return (<Section {...data[it]} setVisibleKey={setVisibleKey} />)
                            })
                        }
                    </Flexbox>
                </Flexbox>
            </Flexbox>


        </PageContentContainer>
    )
}

export default Helix