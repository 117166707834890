const fixSvgElementCoordinates = (
  className,
  xFactor = 2,
  yFactor = 2,
  xNb = null,
  yNb = null
) => {
  let elements;
  elements = document.querySelectorAll(className);
  let updatedBBoxArray = [];
  Array.from(elements).forEach((element) => {
    let BBox = element.getBBox();
    let BBoxX2 = xFactor == 0 ? 0 : xNb ? xNb : BBox["width"] / xFactor;
    let BBoxY2 = yFactor == 0 ? 0 : yNb ? yNb : BBox["height"] / yFactor;
    let BBoxX = xNb ? xNb : BBox["x"] + BBoxX2;
    let BBoxY = yNb ? yNb : BBox["y"] + BBoxY2;
    BBox = BBoxX + "px" + " " + BBoxY + "px";
    setSvgElementCoords(element, BBox);
    // element.style.transformOrigin = BBox;
    updatedBBoxArray.push(BBox);
  });
  return updatedBBoxArray;
};

const setSvgElementCoords = (element, bboxString) => {
  element.style.transformOrigin = bboxString;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
const formatPhoneNumber = (phoneNumber) => {
  let number = phoneNumber
    .slice(0, 10)
    .split("")
    .reduce((a, b, index) => {
      if (index % 3 === 0 && index < 7 && index > 0) a = a + "-" + b;
      else a = a + b;
      return a;
    }, "");

  return number;
};

const debounce = (callback, wait) => {
  console.log("debouncing", wait);
  let timeout;
  return function (...args) {
    console.log("did debounce", timeout);
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      console.log("exec::::::");
      await callback(...args);
    }, wait);
  };
};

class IconSelectionOption {
  /**
   *
   * @param {string} displayName
   * @param {*} img
   * @param {*} id
   * @param {*} text
   * @param {*} imgProps
   * @param {*} subcategories
   */
  constructor(
    displayName,
    img = null,
    id = null,
    text = null,
    imgProps,
    subcategories,
    subtext = null
  ) {
    this.id = id || displayName.replace(/ /g, "-").toLowerCase();
    this.img = img;
    this.text = text || displayName;
    this.displayName = displayName;
    this.subcategories = subcategories;
    this.imgProps = imgProps;
    this.subtext = subtext;
    // this.formattedValue = formattedValue || displayName
  }
}

class IconSelectionOptionOb {
  /**
   *
   * @param {string} displayName
   * @param {*} img
   * @param {*} id
   * @param {*} text
   * @param {*} imgProps
   * @param {*} subcategories
   */
  constructor({
    displayName,
    img = null,
    id = null,
    text = null,
    imgProps,
    subcategories,
    formattedValue,
    subtext = null,
    showInputFieldOnClick = false,
    inputFieldValue = null,
    placeholder = null
  }) {
    this.id = id || displayName.replace(/ /g, "-").toLowerCase();
    this.img = img;
    this.text = text || displayName;
    this.displayName = displayName;
    this.subcategories = subcategories;
    this.imgProps = imgProps;
    this.formattedValue = formattedValue || displayName;
    this.subtext = subtext;
    this.showInputFieldOnClick = showInputFieldOnClick
    if (showInputFieldOnClick && inputFieldValue !== null) {
      this.inputFieldValue = inputFieldValue
    }
    if (placeholder && showInputFieldOnClick)
      this.placeholder = placeholder
  }
}

const newEnum = (arrayOfIconOptions) => {
  return Object.fromEntries(
    arrayOfIconOptions.map((it, idx) => {
      let _it;
      if (typeof it === "string") {
        _it = new IconSelectionOption(it);
      } else if (typeof it === "object") {
        if (
          ["IconSelectionOption", "IconSelectionOptionOb"].includes(
            it.constructor.name
          )
        ) {
          _it = it;
        } else _it = new IconSelectionOptionOb(it);
      } else if (Array.isArray(it)) _it = new IconSelectionOption(...it);
      return [_it.id, _it];
    })
  );
};

export {
  fixSvgElementCoordinates,
  toTitleCase,
  formatPhoneNumber,
  debounce,
  setSvgElementCoords,
  IconSelectionOptionOb,
  IconSelectionOption,
  newEnum,
};
