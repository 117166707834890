import React from "react";
import styled from "styled-components";
import Flexbox from "../Flexbox";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
// import
const OrderedList = styled.ul`
  /* color: #fff; */
  list-style: none;
  padding: 0;
  /* max-width: 700px; */
  align-self: center;
  width: 95%;
  max-width: 1270px;
  display: ${({ display = "grid" }) => display};
  flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
  flex-wrap: wrap;
  gap: 50px 20px;
  grid-template-columns: ${({ gridCols = "repeat(2, 1fr)" }) => gridCols};
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  /* flex-wrap: wrap;
  max-height: 400px; */
  h3 {
    text-align: justify;
    margin: 0;
  }
  & > :not(:last-child) {
    /* margin: 0 0 20vh; */
    /* margin: 0 0 70px; */
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
    & > :not(:last-child) {
      /* margin: 0 0 50px; */
      /* margin: 0 0 50px; */
    }
  }
  @media only screen and (max-width: 880px),
    screen and (max-device-width: 880px) {
      grid-template-columns: 1fr;
      flex-direction: column;
    & > :not(:last-child) {
    }
  }
  /* margin: 100px 0; */
`;

const H1 = styled.h1`
  /* opacity: 0;
transform: translateY(20px);
transition: all 0.4s ease;

&.visible{
  opacity: 1;
  transform: translateY(0);
} */
`;

const Span = styled.h2`
  z-index: 1;
  position: relative;
  height: min-content;
  width: ${({ width = "100%" }) => width};
  white-space: ${({ whiteSpace = "wrap" }) => whiteSpace};
  align-self: center;
  margin: 5px 0;
  /* margin-top: -0.45em; */
  font-size: 1.5rem;
  line-height: 1.1em;
  transition: all 0.2s ease;
  font-family: 'Playfair Display', serif;
  letter-spacing: -.03em;
  transform: translateY(20px);
  opacity: 0;
  &.visible {
    transform: translateY(0); 
    opacity: 1;
  } 
  ::after {
    content: "";
    font-size: inherit;
    position: absolute;
  transition: all .2s ease;
    left: 0;
    bottom: 0em;
    /* width: 0; */
    width: 100%;
    height: 2px;
    /* border-radius: 0.1em; */
    /* opacity: 0; */
    opacity: 1;
    background-color: transparent;
    background: ${({ bgd = "linear-gradient(90deg,var(--listUnderLineBgdHighlight),transparent 50%,transparent 50%)" }) => bgd};
    background-position: 100% 0%;
    background-size: 200% 100%;
    z-index: -1;
  }
  &.visible::after {
    width: 100%;
    background-position: 0% 0%;
  transition: all 0.2s ease;

    /* background-color: var(--formBlue1); */
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
      /* white-space: normal; */
      /* width: 100%; */
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ flexBasis = '100%' }) => flexBasis};;
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-grow: 1;
  height: 100%;
  /* width: 44%;
 */
  /* max-width: 300px; */
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  border-radius: 10px;
  padding: 35px 20px 20px;
  grid-template-areas:
    "icon heading"
    ". text";
  column-gap: 20px;
  align-items: center;
  position: relative;
  box-shadow: var(--defaultTileBoxShadow);
  background: rgba(255,255,255,0.1);

  /* text-shadow: 0 0 1px var(--formBlue1); */
  h3 {
    transition: all 0.2s ease;
    font-size: 1rem;
    transform: translateY(20px);
    opacity: 0;
  }
  &.visible {
    h3 {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 40px min-content;
    grid-gap: 15px;
    grid-template-areas:
      "icon"
      "heading"
      "text";
    justify-items: center;
    text-align: center !important;
    h3 {
      text-align: center !important;
      font-size: 16px;
    }
    h2 {
      font-size: 1.5rem;
      white-space: wrap;
    }
  }
`;

const Marker = styled.div`
  display: flex;
  background: transparent;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  /* height: 70px;
  width: 70px;
  padding: 8px; */
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 50%;
  transition: all 0.5s ease;
  top: 0;
  transform: translateY(-50%);
  position: absolute;
  background: var(--formBackground);
  /* border: 1px solid var(--formBlue1); */

  &.visible {
    /* box-shadow: 0 0 12px #ffffff; */
    box-shadow: -5px -5px 12px rgba(168, 245, 255, 0.25),
      6px 6px 15px rgba(0, 29, 194, 0.6),
      inset 5px 5px 5px rgba(255, 255, 255, 0.25), inset -5px -5px 5px rgba(0, 29, 194, 0.3);
    /* box-shadow: 0 0 5px var(--formBlue1); */
  }
  img {
    position: relative;
    height: 90%;
    width: 90%;
    filter: ${'var(--primary)' === '#fff' ? undefined : 'var(--asSeenOnFilter)'}; 
  }
  
`;
const ListComponent = ({ img, heading, text, index, whiteSpace, bgd, width, textWidth, subtext }) => {
  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "-50px 0px 0px 0px",
      // rootMargin: "10px",
      threshold: 0.2, // half of item height
    },
    true
  );
  return (
    <ListItem className={isVisible ? "visible" : undefined}>
      <Marker className={isVisible ? "visible" : undefined}>
        {img ? <img src={img} /> : <h5 style={{ fontFamily: 'Playfair Display', fontSize: '1.9rem', lineHeight: '.7rem', margin: '0', marginBottom: '0.37rem' }}>{index}</h5>}
      </Marker>
      <Span
        bgd={bgd}
        width={textWidth}
        ref={containerRef}
        whiteSpace={whiteSpace}
        gridArea="heading"
        className={isVisible ? "visible" : undefined}
      >
        {heading}
      </Span>
      <h3 style={{ fontWeight: "300", gridArea: "text" }}>{text}</h3>
      <h4 style={{ fontWeight: "300", gridArea: "text", marginTop: '20px' }}>{subtext}</h4>
    </ListItem>
  );
};

const PageList = () => {
  const listItems2Props = {
    flexBasis: '33%',
    bgd: 'none', whiteSpace: 'normal', textWidth: '100%'
  }
  const listItems = [
    {
      heading: "eCommerce",
      text: <>Customized a Shopify site to allow for region-specific product sales. Developed a product recommendation quiz to provide visitors with personalized results.</>,
      subtext: 'Custom Dev | Liquid | Shopify'
    },
    {
      heading: "Web Platform",
      subtext: 'React | Adobe Sign | Zoom | Node | MySQL',
      text: "Developed an intuitive, revolutionary online notarization platform.",
    },
    {
      heading: "Automating Workflows & Customer SMS Feedback Integration with CRM",
      text: "Automated customer satisfaction surveys and integrated customer SMS responses into CRM.",
      subtext: "Freshdesk | Freshsales | Automation | CRM Integration"
    },
    {
      heading: "eCommerce",
      text: "Developed custom wordpress site to allow users to purchase customized clothing.",
      subtext: "Woocommerce | Wordpress | QuickBooks | Multi-Carrier Shipping Software"
    },
    {
      heading: "Rebuilt A Popular Custom Wordpress Plugin",
      text: "Modernized a popular booking plugin for Wordpress from the ground up and expanded admin control to allow for admin-level rescheduling, service levels, schedule-blocking, and customer notifications.",
      subtext: "Wordpress | React | PHP"
    },
    {
      heading: "Social Media Automation Tool",
      text: "Created an easy-to-use tool to create and schedule lists of tweets to auto-publish to Twitter at user-specified intervals to help increase traffic even when not logged-in to Twitter.",
      subtext: "Twitter API | MySQL | JQuery | Ajax | PHP | SEO Tool Development"
    },
    {
      heading: "Banking / Financial Mobile App",
      text: "Created a fully-featured mobile banking application with the ability to apply for a loan, add new credit accounts by uploading photo ID, and monitor and initiate payments and transfers.",
      subtext: "Enterprise | iOS | Android | App Development | UI/UX | Security Driven"
    },
    {
      heading: "Drivers Management Platform for Car Dealerships",
      text: "Created a mobile application for an auto dealership to dispatch shuttles for customers getting auto services. Built an AI component for optimizing routing, implemented live tracking for inbound shuttles to ensure expedited customer service on arrival. Included chat features, push-notifications, and predictive pricing model to reduce costs and wait-times when ride-sharing services show to be a better choivce than shuttle or valet service options. Included web development so customers could access the information without having to download the mobile app. Created the option for custom branding for different dealerships.",
      subtext: "Start-Up | iOS | Android | Web | Machine Learning | Navigation & Geolocation | Route Planning | Push-Notifications | SaaS"
    },
    {
      heading: "Online Sports Betting Platform",
      text: "Created a betting mobile and web platform that employs a social element for a unique, engaging experience that allows users to create and join leagues and compete against other members.",
      subtext: "iOS | Android | Web | Push-Notifications | Private & Public User Chats | Scalable | High-Traffic | MongoDB | Laravel | Angular | Swift | MailChimp"
    },
  ];

  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px 0px -40% 0px",
      threshold: 0.5, // half of item height
    },
    true
  );

  return (
    <Flexbox
      column
      alignItems="center"
      width="100%"
      // color="#fff"
      padding="0 50px 50px"
      mobilePadding="0"
      fontWeight="400"
    // background="var(--formBackground)"
    >

      {/* <h2 style={{ fontFamily: "'Playfair Display', serif" }}>Case Results</h2> */}
      <OrderedList>
        {listItems.map((item, index) => (
          <ListComponent key={index} {...item} index={index + 1} />
        ))}
      </OrderedList>
      {/* <h2 style={{ fontFamily: "'Playfair Display', serif" }}>Additional Highlights</h2>
      <OrderedList
        gridCols="repeat(4,1fr)"
      // display="flex" flexDirection="row"
      >
        {listItems2.map((item, index) => (
          <ListComponent key={index} {...item} index={index + 1} {...listItems2Props} />
        ))}
      </OrderedList> */}
    </Flexbox>
  );
};

export default PageList;
