import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom'


// ONLY EDIT BELOW HERE //
const DescriptionAndKeywords = ({ pathname }) => {

    if (pathname.includes('blockchain-development'))
        return (
            <Helmet prioritizeSeoTags>
                <title>Find Best Blockchain Development Company for Your Needs</title>
                <meta
                    name="description"
                    content="We connect you with a top developer for web and mobile blockchain projects, including web3, crypto, and NFT, based on your exact needs. Ethereum smart contracts."
                />
                <meta
                    name="keywords"
                    content="Blockchain Development Company, Blockchain Development, Blockchain Development Firm, Web3 Development Company, Blockchain App Development Company, Blockchain Development Service, Blockchain Mobile Development Service, Blockchain App Development Service, Blockchain Web Development Company, crypto development company, Ethereum development company, NFT marketplace development company, NFT token development company, NFT development company, smart contract development company" />
            </Helmet>
        )
    if (pathname.includes('mobile-app-development'))
        return (
            <Helmet prioritizeSeoTags>
                <title>Find Best Mobile App Development Company For Your Needs</title>
                <meta
                    name="description"
                    content="Match with a top mobile app development company based on your exact requirements and goals. Top rated mobile app dev services for iOS and Android projects."
                />
                <meta
                    name="keywords"
                    content="Mobile Development Company, Mobile Development, Mobile app develpment company, Mobile app Development services, App Development Company, Web and mobile Development Service, Mobile Development firms, App Development Service" />
            </Helmet>
        )
    if (pathname.includes('web-development'))
        return (
            <Helmet prioritizeSeoTags>
                <title>Find the Best Web Development Company for Your Exact Needs</title>
                <meta
                    name="description"
                    content="Match with a top web development company based on your exact requirements and goals. Top rated web development services. Match in 60 seconds with best web dev."
                />
                <meta
                    name="keywords"
                    content="Web Development, Web Development Company, Web and Mobile Development Company, Web App Development Company, Web Development Service, Web Development Firms, Web App Development Service, Web developer, web developers, hire, hire web development" />
            </Helmet>
        )
    if (pathname.includes('ai-development'))
        return (
            <Helmet prioritizeSeoTags>
                <title>Find Best AI Development Company - AI Web and Mobile Devs</title>
                <meta
                    name="description"
                    content="Top AI development services for mobile app and web projects. LLMs, GPT-3, ChatGPT, computer vision, GAN, predictive modeling. Match based on your exact needs."
                />
                <meta
                    name="keywords"
                    content="AI Development Company, AI Web Development Company, AI Mobile Development Company, AI App Development Company, AI Development Service, LLMs, large language models, gpt-3, gpt3, gpt, chatgpt, chat gpt, computer vision, deep learning, dnn, cnn, predictive modeling, GAN, gan art, chatbot, chat bot" />
            </Helmet>
        )
    if (pathname.includes('virtual-reality-development'))
        return (
            <Helmet prioritizeSeoTags>
                <title>VR </title>
                <meta
                    name="description"
                    content="We connect you with a top developer for web or mobile blockchain projects, including web3, crypto, and NFT."
                />
                <meta
                    name="keywords"
                    content="Lead Generation, Software Development Company, Lead Gen, Web Development, Web Development Company, Mobile Development Company, App Development Company, Web Development Service, Mobile Development Service, App Development Service, Buy Leads, Exclusive Leads, Real-Time Leads, Realtime Leads" />
            </Helmet>
        )
    if (pathname.includes('augmented-reality-development'))
        return (
            <Helmet prioritizeSeoTags>
                <title>Find Best Blockchain Development Company for Your Needs</title>
                <meta
                    name="description"
                    content="We connect you with a top developer for web and mobile blockchain projects, including web3, crypto, and NFT, based on your exact needs. Ethereum smart contracts."
                />
                <meta
                    name="keywords"
                    content="Blockchain Development Company, Blockchain Development, Blockchain Development Firm, Web3 Development Company, Blockchain App Development Company, Blockchain Development Service, Blockchain Mobile Development Service, Blockchain App Development Service, Blockchain Web Development Company, crypto development company, Ethereum development company, NFT marketplace development company, NFT token development company, NFT development company, smart contract development company" />
            </Helmet>
        )
    else
        return (
            <Helmet prioritizeSeoTags>
                <meta
                    name="description"
                    content="We match you with the best software development company for your exact needs. Web and mobile app development services, plus AI, blockchain, AR/VR, IoT, and more"
                />
                <meta
                    name="keywords"
                    content="Software Development Company, software development, software developer, Web Development, Web Development Company, Mobile Development Company, App Development Company, mobile app development company, Web Development Service, Mobile Development Service, App Development Service, AI, ML, artificial intelligence, machine learning, blockchain, web3, AR, VR, AR/VR, augmented reality, virtual reality, IoT" />
            </Helmet>
        )

}
// ONLY EDIT ABOVE HERE //
const DynamicMetaData = () => {
    const { pathname } = useLocation()
    return (
        <>
            <DescriptionAndKeywords pathname={pathname} />
            <Helmet prioritizeSeoTags
                // %s is a replacement string and will inherit whatever value you set for <title>Find Blockchain Developer Services</title>
                titleTemplate="Best Software Devs | %s" // non default.

                // this is the default title for the actual home page blahblah.com/
                defaultTitle="Best Software Dev Firms: Web, Mobile, AI, Blockchain, AR/VR"
                
                >




                <link rel="canonical" href="https://bestsoftwaredevs.com" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />

                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link
                    href="https://fonts.googleapis.com/css2?family=Albert+Sans:wght@100;300;400;500;600;700;900&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Noto+Serif+Oriya&display=swap"
                    rel="stylesheet"
                />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>

        </>
    )
}

export default DynamicMetaData