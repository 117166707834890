import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import anime from 'animejs'


import { fixSvgElementCoordinates, setSvgElementCoords } from '../../../utils'

const SVG = styled.svg`
height: 100%;
max-width: 345px;
width: 31vw;
position: relative;
.hiw2-1{fill:url(#hiw2-linear-gradient-2);}
.hiw2-2{stroke:url(#hiw2-linear-gradient-3);}
.hiw2-2,.hiw2-3,.hiw2-4,.hiw2-5,.hiw2-6,.hiw2-7{stroke-miterlimit:10;}
.hiw2-2,.hiw2-4,.hiw2-8,.hiw2-5,.hiw2-6{fill:none;}
.hiw2-2,.hiw2-5{stroke-width:5px;}
.hiw2-3{fill:url(#hiw2-linear-gradient-16);}
.hiw2-3,.hiw2-4,.hiw2-6,.hiw2-7{stroke:#fff;stroke-linecap:round;}
.hiw2-3,.hiw2-7{stroke-width:2px;}
.hiw2-9{fill:#fff;}
.hiw2-10{fill:url(#hiw2-linear-gradient-17);}
.hiw2-11{fill:url(#hiw2-radial-gradient);}
.hiw2-11,.hiw2-12{opacity:.75;}
.hiw2-13{fill:url(#hiw2-linear-gradient-15);}
.hiw2-14{opacity:.9;}
.hiw2-15{fill:url(#hiw2-linear-gradient-28);}
.hiw2-16{fill:url(#hiw2-linear-gradient-14);}
.hiw2-17{fill:url(#hiw2-linear-gradient-20);}
.hiw2-18{fill:url(#hiw2-linear-gradient-6);}
.hiw2-19{fill:url(#hiw2-linear-gradient-11);}
.hiw2-20{opacity:.8;}
.hiw2-21{fill:url(#hiw2-linear-gradient-10);}
.hiw2-22{fill:url(#hiw2-linear-gradient-31);}
.hiw2-4{opacity:.25;stroke-width:4px;}
.hiw2-23{fill:url(#hiw2-linear-gradient-21);}
.hiw2-24{fill:url(#hiw2-linear-gradient-25);}
.hiw2-25{fill:lime;}
.hiw2-26{fill:url(#hiw2-linear-gradient-5);}
.hiw2-27{clip-path:url(#hiw2clippath);}
.hiw2-28{fill:url(#hiw2-linear-gradient-7);}
.hiw2-29{fill:#ccc;}
.hiw2-30{fill:url(#hiw2-linear-gradient-24);}
.hiw2-31{fill:rgba(255,255,255,.47);}
.hiw2-32{fill:url(#hiw2-linear-gradient-9);}
.hiw2-33{fill:url(#hiw2-linear-gradient-22);}
.hiw2-34{fill:url(#hiw2-linear-gradient);}
.hiw2-35{fill:url(#hiw2-linear-gradient-27);}
.hiw2-5{stroke:url(#hiw2-linear-gradient-18);}
.hiw2-36{fill:url(#hiw2-linear-gradient-29);}
.hiw2-37{fill:url(#hiw2-linear-gradient-30);}
.hiw2-38{fill:url(#hiw2-linear-gradient-23);}
.hiw2-39{fill:url(#hiw2-linear-gradient-19);}
.hiw2-40{fill:url(#hiw2-linear-gradient-4);}
.hiw2-41{fill:url(#hiw2-linear-gradient-8);}
.hiw2-6{stroke-width:3px;}
.hiw2-7{fill:#00d409;}
.hiw2-42{fill:url(#hiw2-linear-gradient-12);}
.hiw2-43{fill:url(#hiw2-linear-gradient-13);}
.hiw2-12{fill:url(#hiw2-radial-gradient);}
.hiw2-44{fill:url(#hiw2-linear-gradient-26);}
@media only screen and (max-width: 550px),
        screen and (max-device-width: 550px) {
            /* height: 30vh; */
            width: 100%;
            max-height: 50vh;
        }

`

const AnimatedStep2 = ({ className, componentId = "hiw2" }) => {

    // useEffect(() => {
    //     var hiw2path1 = anime.path('#travelPath')
    //     anime.set(`#glowingDot1`, {
    //         cx: 0,
    //         cy: 0,
    //     })
    //     fixSvgElementCoordinates('#glowingDot1')
    //     anime.set(`#numberGroup`, {
    //         translateY: '-120px'
    //     })
    //     anime.set(`#greenGlow`, {
    //         opacity: 0
    //     })
    //     anime.set(`#developerGroup`, {
    //         opacity: 0
    //     })
    //     anime.set(`#greenStack`, {
    //         opacity: 0
    //     })
    //     anime.set(`#whiteGlow2`, {
    //         opacity: 0
    //     })
    //     anime.set(`#whiteGlow`, {
    //         opacity: 0
    //     })
    //     // var tl = anime.timeline({
    //     //     easing: 'easeOutExpo',
    //     //     duration: 6000,
    //     //     autoplay: true,
    //     //     loop: true,
    //     // })
    //     // tl
            

    //     // tl.play()
    // }, [])
    return (
        <SVG id="Layer_2" className={className}  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 460.18 935.45">
            <defs>

                <linearGradient id="hiw2-linear-gradient" x1="-82.16" y1="780.32" x2="543.03" y2="155.13" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#386aff" />
                    <stop offset="1" stopColor="#1b86f6" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-2" x1="135.11" y1="516.25" x2="135.11" y2="298.72" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fff" stopOpacity=".2" />
                    <stop offset=".74" stopColor="#fff" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-3" x1="282.46" y1="852.51" x2="282.46" y2="595.72" gradientTransform="translate(568.17 44.44) rotate(45)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#8cc0f6" />
                    <stop offset=".26" stopColor="#1b86f6" />
                    <stop offset=".56" stopColor="#8cc0f6" />
                    <stop offset=".88" stopColor="#1b86f6" />
                    <stop offset="1" stopColor="#4bdb7f" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-4" x1="323.84" y1="612.61" x2="327.76" y2="523.86" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#557cdb" stopOpacity=".75" />
                    <stop offset=".97" stopColor="#fefefe" stopOpacity=".41" />
                    <stop offset="1" stopColor="#fff" stopOpacity=".4" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-5" x1="246.78" y1="1075.97" x2="404.73" y2="1075.97" gradientTransform="translate(0 1611.77) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fff" stopOpacity=".75" />
                    <stop offset="1" stopColor="#fff" stopOpacity=".15" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-6" x1="305.64" y1="613.72" x2="343.23" y2="515.46" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-7" x1="323.84" y1="592.02" x2="327.76" y2="503.26" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-8" x1="323.84" y1="577.31" x2="327.76" y2="488.55" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-9" y1="1111.28" y2="1111.28" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-10" x1="305.64" y1="578.42" x2="343.23" y2="480.16" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-11" x1="323.84" y1="556.71" x2="327.76" y2="467.96" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-12" x1="323.84" y1="536.22" x2="327.76" y2="447.46" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#41db72" stopOpacity=".75" />
                    <stop offset=".97" stopColor="#47fe69" stopOpacity=".41" />
                    <stop offset="1" stopColor="#fff" stopOpacity=".4" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-13" y1="1152.37" y2="1152.37" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-14" x1="305.64" y1="537.33" x2="343.23" y2="439.07" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#41bd6d" stopOpacity=".9" />
                    <stop offset="1" stopColor="#41ba6b" stopOpacity=".1" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-15" x1="323.84" y1="515.62" x2="327.76" y2="426.87" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#41db72" stopOpacity=".75" />
                    <stop offset=".97" stopColor="#47fe69" stopOpacity=".41" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-16" x1="304.7" y1="446.33" x2="347.79" y2="446.33" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#00a307" />
                    <stop offset=".5" stopColor="#00d409" stopOpacity=".7" />
                    <stop offset="1" stopColor="#00a307" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-17" x1="325.77" y1="517.51" x2="325.77" y2="299.97" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#4ada7e" stopOpacity=".7" />
                    <stop offset=".8" stopColor="#4ada7e" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-18" x1="323.37" y1="811.6" x2="323.37" y2="554.81" gradientTransform="translate(-49.43 44.44) rotate(135) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#8cc0f6" />
                    <stop offset=".56" stopColor="#8cc0f6" />
                    <stop offset="1" stopColor="#1b86f6" />
                </linearGradient>
                <linearGradient id="hiw2-linear-gradient-19" x1="133.17" y1="611.35" x2="137.1" y2="522.6" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-20" x1="56.12" y1="1077.23" x2="214.07" y2="1077.23" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-21" x1="114.98" y1="612.47" x2="152.56" y2="514.2" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-22" x1="133.17" y1="590.76" x2="137.1" y2="502" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-23" x1="133.17" y1="576.05" x2="137.1" y2="487.29" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-24" x1="56.12" y1="1112.54" x2="214.07" y2="1112.54" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-25" x1="114.98" y1="577.16" x2="152.56" y2="478.9" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-26" x1="133.17" y1="555.45" x2="137.1" y2="466.7" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-27" x1="133.17" y1="534.96" x2="137.1" y2="446.2" xlinkHref="#hiw2-linear-gradient-4" />
                <linearGradient id="hiw2-linear-gradient-28" x1="56.12" y1="1153.63" x2="214.07" y2="1153.63" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-29" x1="114.98" y1="536.07" x2="152.56" y2="437.81" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#hiw2-linear-gradient-5" />
                <linearGradient id="hiw2-linear-gradient-30" x1="133.17" y1="514.36" x2="137.1" y2="425.61" xlinkHref="#hiw2-linear-gradient-4" />
                <radialGradient 
                id="hiw2-radial-gradient" 
                cx=".5" 
                cy="0.5" 
                // fx="0" 
                // fy="0" 
                r=".5" 
                // gradientTransform="translate(467.15 142.72) scale(.36)" 
                gradientUnits="objectBoundingBox"
                
                >
                    <stop offset="0" stopColor="#f6f6f6" />
                    <stop offset="1" stopColor="#52a2f6" stopOpacity="0" />
                </radialGradient>
                <radialGradient id="hiw2-radial-gradient-2" cx="-503.88" cy="1300.26" fx="-503.88" fy="1300.26" r="62.83" xlinkHref="#hiw2-radial-gradient" />
                <linearGradient id="hiw2-linear-gradient-31" x1="137.32" y1="233.4" x2="275.56" y2="233.4" xlinkHref="#hiw2-linear-gradient" />
                <clipPath id="hiw2clippath" >
                    <circle className="hiw2-8" cx="229.61" cy="210.23" r="33.68" />
                </clipPath>
            </defs>
            <g id="Layer_1-2">
                <g>
                    <path className="hiw2-29" d="M394.02,9c31.52,0,57.16,25.53,57.16,56.9V869.56c0,31.37-25.64,56.89-57.16,56.89H66.15c-31.51,0-57.15-25.52-57.15-56.89V65.9c0-31.37,25.64-56.9,57.15-56.9H394.02M137.81,50.96c-4.67,0-9.07-1.78-12.36-5.02-3.27-3.21-5.07-7.48-5.07-12,0-7.9-6.49-14.32-14.47-14.32h-41.27c-26.03,0-47.2,20.85-47.2,46.48V869.36c0,25.63,21.17,46.48,47.2,46.48H396.24c26.03,0,47.2-20.85,47.2-46.48V66.1c0-25.63-21.17-46.48-47.2-46.48h-41.27c-3.86,0-7.48,1.48-10.19,4.16-2.76,2.71-4.28,6.32-4.28,10.16,0,9.38-7.82,17.02-17.43,17.02H137.81M394.02,3H66.15C31.27,3,3,31.16,3,65.9V869.56c0,34.74,28.27,62.89,63.15,62.89H394.02c34.88,0,63.16-28.15,63.16-62.89V65.9c0-34.74-28.28-62.9-63.16-62.9h0ZM137.81,56.96h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48V869.36c0,22.35-18.44,40.48-41.2,40.48H64.64c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h0Z" />
                    <path className="hiw2-29" d="M252.59,39.53h-44.56c-1.1,0-2,.9-2,2s.9,2,2,2h44.56c1.1,0,2-.9,2-2s-.9-2-2-2h0Z" />
                    <path className="hiw2-29" d="M283.85,36.21c-2.94,0-5.32,2.38-5.32,5.32s2.38,5.32,5.32,5.32,5.32-2.38,5.32-5.32-2.38-5.32-5.32-5.32h0Z" />
                    <path className="hiw2-34" d="M437.44,66.1V869.36c0,22.35-18.44,40.48-41.2,40.48H64.64c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48Z" />
                    <path id="whiteGlow" className="hiw2-1" d="M214.09,344.32v126.15c.08,11.73-7.63,23.48-23.13,32.43-30.85,17.81-80.86,17.81-111.71,0-15.5-8.95-23.21-20.7-23.13-32.43v-126.15c0-11.67,7.71-23.34,23.13-32.25,30.85-17.8,80.86-17.8,111.71,.01,15.42,8.9,23.13,20.57,23.13,32.24Z" />
                    <polyline className="hiw2-2" points="288.29 607.4 230.44 654.6 230.44 909.84" />
                    <line className="hiw2-4" x1="170.59" y1="898.91" x2="290.29" y2="898.91" />
                    <g>
                        <g>
                            <ellipse className="hiw2-40" cx="325.77" cy="568.9" rx="78.99" ry="45.6" />
                            <path className="hiw2-26" d="M404.73,567.7v-20.6c-.51-11.27-8.22-22.44-23.1-31.04-30.85-17.81-80.86-17.81-111.71,0-15.42,8.91-23.14,20.58-23.14,32.25v20.6c0-3.46,.68-6.91,2.04-10.3,3.21-8.04,10.24-15.68,21.1-21.95,30.85-17.81,80.86-17.81,111.71,0,10.85,6.27,17.88,13.91,21.09,21.95,1.2,2.99,1.87,6.04,2.01,9.1Z" />
                            <path className="hiw2-18" d="M404.73,549.5v20.6c-.51,11.27-8.22,22.44-23.1,31.04-30.85,17.81-80.86,17.81-111.71,0-15.42-8.91-23.14-20.58-23.14-32.25v-20.6c0,3.46,.68,6.91,2.04,10.3,3.21,8.04,10.24,15.68,21.1,21.95,30.85,17.81,80.86,17.81,111.71,0,10.85-6.27,17.88-13.91,21.09-21.95,1.2-2.99,1.87-6.04,2.01-9.1Z" />
                            <ellipse className="hiw2-28" cx="325.77" cy="548.3" rx="78.99" ry="45.6" />
                        </g>
                        <g>
                            <ellipse className="hiw2-41" cx="325.77" cy="533.59" rx="78.99" ry="45.6" />
                            <path className="hiw2-32" d="M404.73,532.39v-20.6c-.51-11.27-8.22-22.44-23.1-31.04-30.85-17.81-80.86-17.81-111.71,0-15.42,8.91-23.14,20.58-23.14,32.25v20.6c0-3.46,.68-6.91,2.04-10.3,3.21-8.04,10.24-15.68,21.1-21.95,30.85-17.81,80.86-17.81,111.71,0,10.85,6.27,17.88,13.91,21.09,21.95,1.2,2.99,1.87,6.04,2.01,9.1Z" />
                            <path className="hiw2-21" d="M404.73,514.2v20.6c-.51,11.27-8.22,22.44-23.1,31.04-30.85,17.81-80.86,17.81-111.71,0-15.42-8.91-23.14-20.58-23.14-32.25v-20.6c0,3.46,.68,6.91,2.04,10.3,3.21,8.04,10.24,15.68,21.1,21.95,30.85,17.81,80.86,17.81,111.71,0,10.85-6.27,17.88-13.91,21.09-21.95,1.2-2.99,1.87-6.04,2.01-9.1Z" />
                            <ellipse className="hiw2-19" cx="325.77" cy="513" rx="78.99" ry="45.6" />
                        </g>
                        <g id="greenStack">
                            <ellipse className="hiw2-42" cx="325.77" cy="492.5" rx="78.99" ry="45.6" />
                            <path className="hiw2-43" d="M404.73,491.3v-20.6c-.51-11.27-8.22-22.44-23.1-31.04-30.85-17.81-80.86-17.81-111.71,0-15.42,8.91-23.14,20.58-23.14,32.25v20.6c0-3.46,.68-6.91,2.04-10.3,3.21-8.04,10.24-15.68,21.1-21.95,30.85-17.81,80.86-17.81,111.71,0,10.85,6.27,17.88,13.91,21.09,21.95,1.2,2.99,1.87,6.04,2.01,9.1Z" />
                            <path className="hiw2-16" d="M404.73,473.11v20.6c-.51,11.27-8.22,22.44-23.1,31.04-30.85,17.81-80.86,17.81-111.71,0-15.42-8.91-23.14-20.58-23.14-32.25v-20.6c0,3.46,.68,6.91,2.04,10.3,3.21,8.04,10.24,15.68,21.1,21.95,30.85,17.81,80.86,17.81,111.71,0,10.85-6.27,17.88-13.91,21.09-21.95,1.2-2.99,1.87-6.04,2.01-9.1Z" />
                            <ellipse className="hiw2-13" cx="325.77" cy="471.91" rx="78.99" ry="45.6" />
                        </g>
                        <g id="whiteStack">
                            <path className="hiw2-26" d="M404.73,491.3v-20.6c-.51-11.27-8.22-22.44-23.1-31.04-30.85-17.81-80.86-17.81-111.71,0-15.42,8.91-23.14,20.58-23.14,32.25v20.6c0-3.46,.68-6.91,2.04-10.3,3.21-8.04,10.24-15.68,21.1-21.95,30.85-17.81,80.86-17.81,111.71,0,10.85,6.27,17.88,13.91,21.09,21.95,1.2,2.99,1.87,6.04,2.01,9.1Z" />
                            <path className="hiw2-18" d="M404.73,473.11v20.6c-.51,11.27-8.22,22.44-23.1,31.04-30.85,17.81-80.86,17.81-111.71,0-15.42-8.91-23.14-20.58-23.14-32.25v-20.6c0,3.46,.68,6.91,2.04,10.3,3.21,8.04,10.24,15.68,21.1,21.95,30.85,17.81,80.86,17.81,111.71,0,10.85-6.27,17.88-13.91,21.09-21.95,1.2-2.99,1.87-6.04,2.01-9.1Z" />
                            <ellipse className="hiw2-28" cx="325.77" cy="492.5" rx="78.99" ry="45.6" />
                            <ellipse className="hiw2-37" cx="325.77" cy="471.91" rx="78.99" ry="45.6" />
                        </g>
                        <g id="developerGroup" className="hiw2-14">
                            <g id="personRight">
                                <path className="hiw2-3" d="M347.79,441.89v15.61c-6.27,4.06-13.76,6.43-21.78,6.43s-15.13-2.24-21.31-6.12v-15.92c0-6.98,5.42-12.69,12.27-13.17,2.49,2.17,5.73,3.46,9.28,3.46s6.79-1.3,9.28-3.46c6.86,.48,12.26,6.18,12.26,13.17Z" />
                                <path className="hiw2-7" d="M340.44,418c0,4.29-1.9,8.13-4.91,10.73-2.49,2.17-5.73,3.46-9.28,3.46s-6.8-1.3-9.28-3.46c-3.01-2.6-4.91-6.44-4.91-10.73,0-7.84,6.35-14.19,14.19-14.19s14.19,6.35,14.19,14.19Z" />
                            </g>
                            <g>
                                <path className="hiw2-7" d="M319.89,469.31v15.61c-6.27,4.06-13.76,6.43-21.78,6.43s-15.13-2.24-21.31-6.12v-15.92c0-6.98,5.42-12.69,12.27-13.17,2.49,2.17,5.73,3.46,9.28,3.46s6.79-1.3,9.28-3.46c6.86,.48,12.26,6.18,12.26,13.17Z" />
                                <path className="hiw2-7" d="M312.54,445.41c0,4.29-1.9,8.13-4.91,10.73-2.49,2.17-5.73,3.46-9.28,3.46s-6.8-1.3-9.28-3.46c-3.01-2.6-4.91-6.44-4.91-10.73,0-7.84,6.35-14.19,14.19-14.19s14.19,6.35,14.19,14.19Z" />
                                <path className="hiw2-7" d="M376.26,469.31v15.61c-6.27,4.06-13.76,6.43-21.78,6.43s-15.13-2.24-21.31-6.12v-15.92c0-6.98,5.42-12.69,12.27-13.17,2.49,2.17,5.73,3.46,9.28,3.46s6.79-1.3,9.28-3.46c6.86,.48,12.26,6.18,12.26,13.17Z" />
                                <path className="hiw2-7" d="M368.91,445.41c0,4.29-1.9,8.13-4.91,10.73-2.49,2.17-5.73,3.46-9.28,3.46s-6.8-1.3-9.28-3.46c-3.01-2.6-4.91-6.44-4.91-10.73,0-7.84,6.35-14.19,14.19-14.19s14.19,6.35,14.19,14.19Z" />
                            </g>
                        </g>
                        <path id="greenGlow" className="hiw2-10" d="M404.76,345.57v126.15c.08,11.73-7.63,23.48-23.13,32.43-30.85,17.81-80.86,17.81-111.71,0-15.5-8.95-23.21-20.7-23.13-32.43v-126.15c0-11.67,7.71-23.34,23.13-32.25,30.85-17.8,80.86-17.8,111.71,.01,15.42,8.9,23.13,20.57,23.13,32.24Z" />
                        <path id="whiteGlow2" className="hiw2-1"  d="M404.76,345.57v126.15c.08,11.73-7.63,23.48-23.13,32.43-30.85,17.81-80.86,17.81-111.71,0-15.5-8.95-23.21-20.7-23.13-32.43v-126.15c0-11.67,7.71-23.34,23.13-32.25,30.85-17.8,80.86-17.8,111.71,.01,15.42,8.9,23.13,20.57,23.13,32.24Z" />
                    </g>
                    {/* <polyline id="travelPath" className="hiw2-5" points="230.44 909.84 230.44 654.6 172.59 607.4" /> */}
                    <path id="travelPath" className="hiw2-5"
                        d="M230.44,909.84,230.44,654.6,172.59,607.4" />
                    <g>
                        <g>
                            <ellipse className="hiw2-39" cx="135.11" cy="567.64" rx="78.99" ry="45.6" />
                            <path className="hiw2-17" d="M214.07,566.44v-20.6c-.51-11.27-8.22-22.44-23.1-31.04-30.85-17.81-80.86-17.81-111.71,0-15.42,8.91-23.14,20.58-23.14,32.25v20.6c0-3.46,.68-6.91,2.04-10.3,3.21-8.04,10.24-15.68,21.1-21.95,30.85-17.81,80.86-17.81,111.71,0,10.85,6.27,17.88,13.91,21.09,21.95,1.2,2.99,1.87,6.04,2.01,9.1Z" />
                            <path className="hiw2-23" d="M214.07,548.24v20.6c-.51,11.27-8.22,22.44-23.1,31.04-30.85,17.81-80.86,17.81-111.71,0-15.42-8.91-23.14-20.58-23.14-32.25v-20.6c0,3.46,.68,6.91,2.04,10.3,3.21,8.04,10.24,15.68,21.1,21.95,30.85,17.81,80.86,17.81,111.71,0,10.85-6.27,17.88-13.91,21.09-21.95,1.2-2.99,1.87-6.04,2.01-9.1Z" />
                            <ellipse className="hiw2-33" cx="135.11" cy="547.05" rx="78.99" ry="45.6" />
                        </g>
                        <g>
                            <ellipse className="hiw2-38" cx="135.11" cy="532.33" rx="78.99" ry="45.6" />
                            <path className="hiw2-30" d="M214.07,531.13v-20.6c-.51-11.27-8.22-22.44-23.1-31.04-30.85-17.81-80.86-17.81-111.71,0-15.42,8.91-23.14,20.58-23.14,32.25v20.6c0-3.46,.68-6.91,2.04-10.3,3.21-8.04,10.24-15.68,21.1-21.95,30.85-17.81,80.86-17.81,111.71,0,10.85,6.27,17.88,13.91,21.09,21.95,1.2,2.99,1.87,6.04,2.01,9.1Z" />
                            <path className="hiw2-24" d="M214.07,512.94v20.6c-.51,11.27-8.22,22.44-23.1,31.04-30.85,17.81-80.86,17.81-111.71,0-15.42-8.91-23.14-20.58-23.14-32.25v-20.6c0,3.46,.68,6.91,2.04,10.3,3.21,8.04,10.24,15.68,21.1,21.95,30.85,17.81,80.86,17.81,111.71,0,10.85-6.27,17.88-13.91,21.09-21.95,1.2-2.99,1.87-6.04,2.01-9.1Z" />
                            <ellipse className="hiw2-44" cx="135.11" cy="511.74" rx="78.99" ry="45.6" />
                        </g>
                        <g>
                            <ellipse className="hiw2-35" cx="135.11" cy="491.24" rx="78.99" ry="45.6" />
                            <path className="hiw2-15" d="M214.07,490.04v-20.6c-.51-11.27-8.22-22.44-23.1-31.04-30.85-17.81-80.86-17.81-111.71,0-15.42,8.91-23.14,20.58-23.14,32.25v20.6c0-3.46,.68-6.91,2.04-10.3,3.21-8.04,10.24-15.68,21.1-21.95,30.85-17.81,80.86-17.81,111.71,0,10.85,6.27,17.88,13.91,21.09,21.95,1.2,2.99,1.87,6.04,2.01,9.1Z" />
                            <path className="hiw2-36" d="M214.07,471.85v20.6c-.51,11.27-8.22,22.44-23.1,31.04-30.85,17.81-80.86,17.81-111.71,0-15.42-8.91-23.14-20.58-23.14-32.25v-20.6c0,3.46,.68,6.91,2.04,10.3,3.21,8.04,10.24,15.68,21.1,21.95,30.85,17.81,80.86,17.81,111.71,0,10.85-6.27,17.88-13.91,21.09-21.95,1.2-2.99,1.87-6.04,2.01-9.1Z" />
                            <ellipse className="hiw2-37" cx="135.11" cy="470.65" rx="78.99" ry="45.6" />
                        </g>
                    </g>
                    <circle id="glowingDot1" className="hiw2-11" cx="230.44" cy="909.84" r="17.47" />
                    <circle id="glowingDot2" className="hiw2-12" cx="286.59" cy="608.64" r="22.51" />
                    <g id="numbersMask">
                        <g className="hiw2-27">
                            <g id="numberGroup">
                                <path className="hiw2-25" d="M226.8,244.85h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M203.69,273.8h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M235.32,208.7h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M229.99,277.92h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M252.48,239.98h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M208.28,215.28c0-1.46,.12-2.8,.37-4.01,.25-1.21,.66-2.26,1.22-3.14,.56-.88,1.33-1.56,2.29-2.04,.96-.48,2.15-.72,3.55-.72s2.64,.24,3.59,.72c.95,.48,1.71,1.16,2.28,2.04,.56,.88,.97,1.92,1.21,3.14,.24,1.21,.36,2.55,.36,4.01s-.12,2.83-.36,4.05c-.24,1.22-.64,2.27-1.21,3.14-.56,.87-1.32,1.54-2.28,2.01-.95,.47-2.15,.71-3.59,.71s-2.59-.24-3.55-.71c-.96-.47-1.72-1.14-2.29-2.01-.56-.87-.97-1.91-1.22-3.14-.25-1.22-.37-2.57-.37-4.05Zm11.07,0c0-.89-.03-1.73-.1-2.53-.07-.79-.22-1.51-.46-2.15-.24-.64-.6-1.14-1.08-1.5s-1.15-.54-2-.54-1.49,.18-1.97,.54c-.48,.36-.84,.86-1.08,1.5-.24,.64-.39,1.36-.46,2.15-.07,.8-.1,1.64-.1,2.53s.03,1.79,.1,2.61c.06,.81,.22,1.53,.46,2.14s.6,1.09,1.08,1.44c.48,.35,1.14,.53,1.97,.53s1.52-.18,2-.53,.84-.83,1.08-1.44c.24-.61,.39-1.32,.46-2.14,.07-.81,.1-1.68,.1-2.61Z" />
                                <path className="hiw2-25" d="M197.03,205.18h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M214.09,184.96c0-1.46,.12-2.8,.37-4.01,.25-1.21,.66-2.26,1.22-3.14,.56-.88,1.33-1.56,2.29-2.04,.96-.48,2.15-.72,3.55-.72s2.64,.24,3.59,.72c.95,.48,1.71,1.16,2.28,2.04,.56,.88,.97,1.92,1.21,3.14,.24,1.21,.36,2.55,.36,4.01s-.12,2.83-.36,4.05c-.24,1.22-.64,2.27-1.21,3.14-.56,.87-1.32,1.54-2.28,2.01-.95,.47-2.15,.71-3.59,.71s-2.59-.24-3.55-.71c-.96-.47-1.72-1.14-2.29-2.01-.56-.87-.97-1.91-1.22-3.14-.25-1.22-.37-2.57-.37-4.05Zm11.07,0c0-.89-.03-1.73-.1-2.53-.07-.79-.22-1.51-.46-2.15-.24-.64-.6-1.14-1.08-1.5s-1.15-.54-2-.54-1.49,.18-1.97,.54c-.48,.36-.84,.86-1.08,1.5-.24,.64-.39,1.36-.46,2.15-.07,.8-.1,1.64-.1,2.53s.03,1.79,.1,2.61c.06,.81,.22,1.53,.46,2.14s.6,1.09,1.08,1.44c.48,.35,1.14,.53,1.97,.53s1.52-.18,2-.53,.84-.83,1.08-1.44c.24-.61,.39-1.32,.46-2.14,.07-.81,.1-1.68,.1-2.61Z" />
                                <path className="hiw2-25" d="M238.74,159.5c0-1.46,.12-2.8,.37-4.01,.25-1.21,.66-2.26,1.22-3.14,.56-.88,1.33-1.56,2.29-2.04,.96-.48,2.15-.72,3.55-.72s2.64,.24,3.59,.72c.95,.48,1.71,1.16,2.28,2.04,.56,.88,.97,1.92,1.21,3.14,.24,1.21,.36,2.55,.36,4.01s-.12,2.83-.36,4.05c-.24,1.22-.64,2.27-1.21,3.14-.56,.87-1.32,1.54-2.28,2.01-.95,.47-2.15,.71-3.59,.71s-2.59-.24-3.55-.71c-.96-.47-1.72-1.14-2.29-2.01-.56-.87-.97-1.91-1.22-3.14-.25-1.22-.37-2.57-.37-4.05Zm11.07,0c0-.89-.03-1.73-.1-2.53-.07-.79-.22-1.51-.46-2.15-.24-.64-.6-1.14-1.08-1.5s-1.15-.54-2-.54-1.49,.18-1.97,.54c-.48,.36-.84,.86-1.08,1.5-.24,.64-.39,1.36-.46,2.15-.07,.8-.1,1.64-.1,2.53s.03,1.79,.1,2.61c.06,.81,.22,1.53,.46,2.14s.6,1.09,1.08,1.44c.48,.35,1.14,.53,1.97,.53s1.52-.18,2-.53,.84-.83,1.08-1.44c.24-.61,.39-1.32,.46-2.14,.07-.81,.1-1.68,.1-2.61Z" />
                                <path className="hiw2-25" d="M243.76,178.39h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M203.68,155.07h-2.64v-3.14h6.38v19.42h-3.75v-16.29Z" />
                                <path className="hiw2-25" d="M248.79,215.28c0-1.46,.12-2.8,.37-4.01,.25-1.21,.66-2.26,1.22-3.14,.56-.88,1.33-1.56,2.29-2.04,.96-.48,2.15-.72,3.55-.72s2.64,.24,3.59,.72c.95,.48,1.71,1.16,2.28,2.04,.56,.88,.97,1.92,1.21,3.14,.24,1.21,.36,2.55,.36,4.01s-.12,2.83-.36,4.05c-.24,1.22-.64,2.27-1.21,3.14-.56,.87-1.32,1.54-2.28,2.01-.95,.47-2.15,.71-3.59,.71s-2.59-.24-3.55-.71c-.96-.47-1.72-1.14-2.29-2.01-.56-.87-.97-1.91-1.22-3.14-.25-1.22-.37-2.57-.37-4.05Zm11.07,0c0-.89-.03-1.73-.1-2.53-.07-.79-.22-1.51-.46-2.15-.24-.64-.6-1.14-1.08-1.5s-1.15-.54-2-.54-1.49,.18-1.97,.54c-.48,.36-.84,.86-1.08,1.5-.24,.64-.39,1.36-.46,2.15-.07,.8-.1,1.64-.1,2.53s.03,1.79,.1,2.61c.06,.81,.22,1.53,.46,2.14s.6,1.09,1.08,1.44c.48,.35,1.14,.53,1.97,.53s1.52-.18,2-.53,.84-.83,1.08-1.44c.24-.61,.39-1.32,.46-2.14,.07-.81,.1-1.68,.1-2.61Z" />
                                <path className="hiw2-25" d="M245.57,275.53c0-1.46,.12-2.8,.37-4.01,.25-1.21,.66-2.26,1.22-3.14,.56-.88,1.33-1.56,2.29-2.04,.96-.48,2.15-.72,3.55-.72s2.64,.24,3.59,.72c.95,.48,1.71,1.16,2.28,2.04,.56,.88,.97,1.92,1.21,3.14,.24,1.21,.36,2.55,.36,4.01s-.12,2.83-.36,4.05c-.24,1.22-.64,2.27-1.21,3.14-.56,.87-1.32,1.54-2.28,2.01-.95,.47-2.15,.71-3.59,.71s-2.59-.24-3.55-.71c-.96-.47-1.72-1.14-2.29-2.01-.56-.87-.97-1.91-1.22-3.14-.25-1.22-.37-2.57-.37-4.05Zm11.07,0c0-.89-.03-1.73-.1-2.53-.07-.79-.22-1.51-.46-2.15-.24-.64-.6-1.14-1.08-1.5s-1.15-.54-2-.54-1.49,.18-1.97,.54c-.48,.36-.84,.86-1.08,1.5-.24,.64-.39,1.36-.46,2.15-.07,.8-.1,1.64-.1,2.53s.03,1.79,.1,2.61c.06,.81,.22,1.53,.46,2.14s.6,1.09,1.08,1.44c.48,.35,1.14,.53,1.97,.53s1.52-.18,2-.53,.84-.83,1.08-1.44c.24-.61,.39-1.32,.46-2.14,.07-.81,.1-1.68,.1-2.61Z" />
                                <path className="hiw2-25" d="M196.8,249.09c0-1.46,.12-2.8,.37-4.01,.25-1.21,.66-2.26,1.22-3.14,.56-.88,1.33-1.56,2.29-2.04,.96-.48,2.15-.72,3.55-.72s2.64,.24,3.59,.72c.95,.48,1.71,1.16,2.28,2.04,.56,.88,.97,1.92,1.21,3.14,.24,1.21,.36,2.55,.36,4.01s-.12,2.83-.36,4.05c-.24,1.22-.64,2.27-1.21,3.14-.56,.87-1.32,1.54-2.28,2.01-.95,.47-2.15,.71-3.59,.71s-2.59-.24-3.55-.71c-.96-.47-1.72-1.14-2.29-2.01-.56-.87-.97-1.91-1.22-3.14-.25-1.22-.37-2.57-.37-4.05Zm11.07,0c0-.89-.03-1.73-.1-2.53-.07-.79-.22-1.51-.46-2.15-.24-.64-.6-1.14-1.08-1.5s-1.15-.54-2-.54-1.49,.18-1.97,.54c-.48,.36-.84,.86-1.08,1.5-.24,.64-.39,1.36-.46,2.15-.07,.8-.1,1.64-.1,2.53s.03,1.79,.1,2.61c.06,.81,.22,1.53,.46,2.14s.6,1.09,1.08,1.44c.48,.35,1.14,.53,1.97,.53s1.52-.18,2-.53,.84-.83,1.08-1.44c.24-.61,.39-1.32,.46-2.14,.07-.81,.1-1.68,.1-2.61Z" />
                            </g>
                        </g>
                    </g>
                    <g >
                        <g id="lens" >
                        <path className="hiw2-31" d="M253.43,234.04c-11.45,11.43-29.04,12.92-42.09,4.46-1.97-1.25-3.83-2.74-5.53-4.46-1.71-1.71-3.21-3.57-4.48-5.53-8.46-13.05-6.96-30.67,4.47-42.11,13.16-13.14,34.5-13.14,47.64,0,13.16,13.16,13.14,34.48,0,47.64Z" />
                        <path className="hiw2-6" d="M213.05,193.67c-3.63,3.63-5.97,8.36-6.65,13.45" />
                        <path className="hiw2-6" d="M246.18,193.67c-5.41-5.41-12.9-7.6-19.99-6.57" />
                        </g>
                        <g>
                            <path className="hiw2-22" d="M262.1,177.75c-17.96-17.95-47.04-17.96-64.99-.02-16.23,16.24-17.77,41.61-4.6,59.59l-11.2,11.21-3.14-3.14-40.86,40.86,16.26,16.27,40.86-40.86-3.12-3.13,11.2-11.21c17.96,13.17,43.33,11.65,59.59-4.6,17.95-17.95,17.93-47.04,0-64.97Zm-8.67,56.29c-11.45,11.43-29.04,12.92-42.09,4.46-1.97-1.25-3.83-2.74-5.53-4.46-1.71-1.71-3.21-3.57-4.48-5.53-8.46-13.05-6.96-30.67,4.47-42.11,13.16-13.14,34.5-13.14,47.64,0,13.16,13.16,13.14,34.48,0,47.64Z" />
                            <g className="hiw2-20">
                                <path className="hiw2-9" d="M229.59,166.28c11.74,0,22.79,4.58,31.1,12.88,17.13,17.13,17.13,45.01,0,62.14-8.3,8.3-19.33,12.87-31.06,12.87-9.41,0-18.38-2.93-25.93-8.47l-1.38-1.02-1.21,1.21-11.2,11.21-1.41,1.41,1.41,1.41,1.71,1.72-38.03,38.03-13.44-13.44,38.03-38.03,1.73,1.73,1.41,1.41,1.41-1.42,11.2-11.21,1.21-1.21-1.01-1.38c-6.13-8.37-9.1-18.71-8.35-29.12,.76-10.51,5.29-20.41,12.74-27.87,8.3-8.3,19.33-12.87,31.07-12.87m.04,79.62c9.52,0,18.47-3.71,25.21-10.44,13.9-13.92,13.9-36.56,0-50.46-6.73-6.73-15.69-10.44-25.23-10.44s-18.5,3.71-25.24,10.44c-11.82,11.84-13.81,30.6-4.73,44.61,1.35,2.09,2.95,4.06,4.74,5.85,1.78,1.79,3.76,3.38,5.86,4.73,5.77,3.74,12.47,5.72,19.38,5.72m-.04-81.62c-11.76,0-23.51,4.48-32.48,13.45-16.23,16.24-17.77,41.61-4.6,59.59l-11.2,11.21-3.14-3.14-40.86,40.86,16.26,16.27,40.86-40.86-3.12-3.13,11.2-11.21c8.05,5.9,17.58,8.85,27.11,8.85,11.75,0,23.5-4.48,32.47-13.46,17.95-17.95,17.93-47.04,0-64.97-8.98-8.98-20.75-13.47-32.51-13.47h0Zm.04,79.62c-6.37,0-12.74-1.8-18.29-5.4-1.97-1.25-3.83-2.74-5.53-4.46-1.71-1.71-3.21-3.57-4.48-5.53-8.46-13.05-6.96-30.67,4.47-42.11,6.58-6.57,15.2-9.86,23.83-9.86s17.25,3.29,23.82,9.86c13.16,13.16,13.14,34.48,0,47.64-6.58,6.57-15.19,9.86-23.8,9.86h0Z" />
                            </g>
                        </g>

                    </g>

                </g>
            </g>
        </SVG>
    )
}

export default AnimatedStep2