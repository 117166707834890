import React from 'react'

import PageContentContainer from './PageContentContainer'
import { useNavigate } from 'react-router-dom'
import Section from './Section'
import Button from './Button'
import ButtonHome from './ButtonHome'
import Flexbox from './Flexbox'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import HowItWorks from './HowItWorks'
import Rocket from './Rocket'
import knightsHelmet from '../images/knightshelmet.jpg'

import scalable from '../images/scalable-from-the-startup.svg'
import freeEval from '../images/free-initial-project-evaluation.svg'
import strategize from '../images/strategize-development-process.svg'
import noGhosting from '../images/no-ghosting-from-freelancers.svg'
import cheaper from '../images/outsourcing-software-is-cheaper-2.svg'
import experiencedDevelopers from '../images/emerging-and-developing-technologies.svg'

import rocket1 from '../images/rocket-1.svg'
import rocket2 from '../images/rocket-2.svg'

import MatchIconAnimated from './MatchIconAnimated'
import betterThanReviewSites from '../images/betterThanReviews.svg'
import betterThanReviewSites2 from '../images/betterThanReviewSites.svg'
import betterThanReviewSitesWhite from '../images/betterThanReviewSitesWhite.svg'
import screenedDevelopers from '../images/screenedDevelopers3.svg'
import screenedDevelopersWhite from '../images/screenedDevelopersWhite.svg'
import usDevelopers from '../images/usDevelopmentCompanies3.svg'
import usDevelopersWhite from '../images/global.svg'
import Footer from './Footer'
import styled from 'styled-components'
const H1 = styled.h1`
    @media only screen and (max-width: 550px){
        font-size: 1.3rem;
        text-align: center;
    }
    
`;

const H3 = styled.h3`
@media only screen and (max-width: 550px){
        text-align: center;
    }
`

const Home = () => {
    const navigate = useNavigate()
    const listContent = [
        { color: '#000', heading: 'Builds the right foundation that scales up as your business grows.', img: scalable },
        { heading: 'Provides a free initial project evaluation and price quote.', img: freeEval },
        { heading: 'Strategizes with you to ensure an efficient development process.', img: strategize },
        { heading: <>More reliable than freelancers.<br />(No ghosting)</>, img: noGhosting },
        { heading: 'Often faster and less expensive than in-house teams.', img: cheaper },
        { heading: 'Proficient with established and emerging technologies.', img: experiencedDevelopers },
    ]
    const content = {
        title: "Why We Love Our Developers",
        props: {
            color: '#fff',
            // background: 'var(--formBackgroundAngled)',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center'
        },
        justifyContent: 'center',
        flexWrap: 'wrap',
        listItemMargin: 30,
        flexGrow: 0,
        maxWidth: "1200px",
        subTextStyling: {
            color: '#fff',
            maxWidth: '700px'
        },
        subtext:
            [

                {
                    text:
                    "When it comes time to make your vision come to life, nothing is more critical than choosing the right web development service.  How do you find a reliable and trustworthy company you can hire without the risk?",
                    position: 'end',
                    color: '#fff'
                },

                {
                    text: <>That's where we come in.  We ask you a few questions to understand your project, goals and requirements.  This detailed info allows us to match you with one company who is the best fit for your unique needs.</>,
                    position: 'end',
                    color: '#fff'


                }, {
                    text: <>Take less than 1 min to tell us about your project in our "Get Started" form and the top matching team will reach out for a free project evaluation.</>,
                    position: 'end',
                    color: '#fff'
                },
                {

                },
            ],
        listContent,
    }

    const whyChooseUs = {
        title: 'We Make Outsourcing Easy',
        props: {
            wrap: 'wrap', gap: '20px',
            justifyContent: 'center',
            color: '#fff'
        },
        listParagraphColor: "#fff",
        listItemMargin: 30,
        flexWrap: 'wrap',
        listClassName: 'noWrapOnMobile',
        listContent: [
            {
                heading: 'Only top-rated developers',
                background: "var(--defaultButtonBackgroundHover)",
                text: 'Every company in our network is one of the top rated developers on the #1 review site. All have 4.8+ star ratings and 30+ reviews.',
                img: betterThanReviewSitesWhite
            },
            {
                heading: 'Match based on your goals',
                text: "We match you to the one company that fits best with your requirements and business priorities, like budget, speed or scalability.",
                useComponent: <MatchIconAnimated arrowFill="#fff" />
            },
            {
                heading: 'More than just great reviews',
                img: screenedDevelopersWhite,
                imgProps: {
                    maxHeight: '150px',
                    left: '-17px'
                },
                text: "Reviews are not enough. All our devs must have exceptional portfolios, including beautiful interface design."
            },
            {
                heading: 'Local or Worldwide',
                img: usDevelopersWhite,
                imgProps: {
                    maxHeight: '150px'
                },
                text: "Our dev companies in the US, Europe and Asia are easy to research, get great results, and deliver unbeatable value."
            },
        ],
    }
    const belowHowItWorks = {
        title: 'Tell Me More...',
        props: {
            // background: 'var(--formBackgroundAngled)', 
            color: 'var(--formColor)',
            wrap: 'wrap', gap: '20px'
        },
        listParagraphColor: "#fff",
        listItemMargin: 30,
        flexWrap: 'wrap',
        listClassName: 'noWrapOnMobile',
        listContent: [
            { heading: 'All major platforms and devices', background: "var(--defaultButtonBackgroundHover)", text: 'We can connect you with a top developer for a web project, as well as mobile, AR/VR, IoT, robotics, smartwatches or other devices.' },
            { heading: 'Experts for any tech stack', text: "Our network includes experts for all major front and back-end web development technologies.", },
            { heading: 'Beautiful and intuitive UIs', text: "The best web developers create interfaces that users love and understand intuitively, without confusion or friction." },
            { heading: 'Fast and cost-effective', text: "Experienced and vetted web development companies, including some specializing in high-speed or budget-friendly options." },
        ],
    }
    return (
        <PageContentContainer
        column
        display="flex"
        // marginTop="0"
        background="var(--formBackground90)"
        position="relative"
        margin="calc(var(--navBarHeight) * -1) 0 0"
        width="100%"
        height="100%"
        justifyContent="start" alignItems="start" flexDirection="row" padding="0 0 "
        >
            <GridContainer
                // background="var(--formBackground)" 
                // background="linear-gradient(var(--formBlue1), var(--formBlue2))"
                // background="linear-gradient(-45deg, #fff, rgba(27, 134, 246, 1))"
                // color="#fff"
                width="100%"
                gridTempMobile={{ columns: '1fr', rows: 'repeat(2, min-content)' }}

                background="linear-gradient(var(--darkBlue),var(--deepBlue))"
                gridTemp={{ rows: 'repeat(1, min-content)', columns: '2fr 1fr' }}
                gap="20px"
                color="#fff"
                
                paddingMobile="var(--navBarHeight) 5% 20px"
                padding="100px 50px 50px" style={{ textAlign: 'left' }}>
                {/* <h1 style={{margin: '0'}}>Couldn't find your freelancelot?</h1> */}
                <Flexbox column alignItems="start" justifyContent="center">

                <H1>Our web development companies combine world-class skill with unbeatable value.</H1>

                    <H3>Let's find the best matching developer for your specific needs.</H3>
                    <ButtonHome
                        width="100%"
                        maxWidth="250px"
                        maxWidthMobile="100%"
                        border="none" borderHover="none" colorHover="#fff" text="Get Started"
                        boxShadow="rgba(0, 0, 0, 0.5) 0px 0px 7px"
                        onClick={() => navigate('/form')} background="var(--formBackgroundAngled)" bgdHover="var(--formBackgroundAngled2)" />
                </Flexbox >
                <GridItem row="1 / span 1" alignSelf="center" col="2" mobileCol="1">
                    <Rocket />
                </GridItem>
            </GridContainer>
            <Flexbox
                column
                width="100%"
                background="linear-gradient(var(--deepBlue),var(--formBlue1))"
                position="relative" >
                <Section {...whyChooseUs} itemsPerRow={2} />
                <HowItWorks />
                <Section {...belowHowItWorks} itemsPerRow={2} />
                <Section {...content} itemsPerRow={3} />
            </Flexbox>
        </PageContentContainer>
    )
}

export default Home