import React from 'react'
import styled from 'styled-components'

const ButtonStyle = styled.button`
  /* all: unset; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin};
  height: ${(props) => (props.height ? props.height : "var(--buttonHeight)")};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : "var(--buttonFontSize)"};
  color: ${({ color }) => color || "var(--defaultButtonColor)"};
  width: ${({ width }) => width || "var(--defaultButtonWidth)"};
  max-width: ${({ maxWidth }) => maxWidth};
  text-transform: ${({ textTransform }) =>
    textTransform || "var(-defaultButtonTextTransform)"};
  cursor: ${({ cursor = "pointer" }) => cursor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: none;
  border-radius: ${({ borderRadius }) =>
    borderRadius || "var(--defaultButtonBorderRadius)"};
  /* background: ${({ background }) =>
    background || "var(--defaultButtonBackground)"}; */
  font-weight: ${({ fontWeight }) =>
    fontWeight || "var(--defaultButtonFontWeight)"};
  padding: ${({ padding }) => padding || "var(--defaultButtonPadding)"};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft || "var(--defaultButtonPadding)"};
  padding-right: ${({ paddingRight }) =>
    paddingRight || "var(--defaultButtonPadding)"};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing || "var(--defaultButtonLetterSpacing)"};
  background-position: ${({ bgdPosition }) => bgdPosition};
  background-size: ${({ bgdSize }) => bgdSize};
  white-space: nowrap;
  opacity: ${(props) => (props.setOpacity ? props.setOpacity : "")};
  transition: all 0.3s ease-in;
  background: linear-gradient(
      90deg,
      var(--formBlue1),
      var(--formBlue2) 50%,
      var(--formBlue1) 100%
    );
    background-size: 240% 100%;
    :hover {
    /* color: var(--formBlue2); */
    /* width: 102%; */
    /* opacity: 1; */
    background-position: 100% 0%;
    /* background-color: var(--formBlue1); */
  }
  @media only screen and (max-width: 550px){
        max-width: ${({ maxWidthMobile }) => maxWidthMobile};
    }
`;

const ButtonHome = ({onClick, text, ...props}) => {
  return (
    <ButtonStyle {...props} onClick={onClick}>{text}</ButtonStyle>
  )
}

export default ButtonHome