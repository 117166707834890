import React from 'react'

import PageContentContainer from './PageContentContainer'
import { useNavigate } from 'react-router-dom'
import Section from './Section'
import Button from './Button'
import ButtonHome from './ButtonHome'
import Flexbox from './Flexbox'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import HowItWorks from './HowItWorks'
import Rocket from './Rocket'
import knightsHelmet from '../images/knightshelmet.jpg'

import scalable from '../images/scalable-from-the-startup.svg'
import freeEval from '../images/free-initial-project-evaluation.svg'
import strategize from '../images/strategize-development-process.svg'
import noGhosting from '../images/no-ghosting-from-freelancers.svg'
import cheaper from '../images/outsourcing-software-is-cheaper-2.svg'
import experiencedDevelopers from '../images/emerging-and-developing-technologies.svg'

import rocket1 from '../images/rocket-1.svg'
import rocket2 from '../images/rocket-2.svg'

import MatchIconAnimated from './MatchIconAnimated'
import betterThanReviewSites from '../images/betterThanReviews.svg'
import betterThanReviewSites2 from '../images/betterThanReviewSites.svg'
import betterThanReviewSitesWhite from '../images/betterThanReviewSitesWhite.svg'
import screenedDevelopers from '../images/screenedDevelopers3.svg'
import screenedDevelopersWhite from '../images/screenedDevelopersWhite.svg'
import usDevelopers from '../images/usDevelopmentCompanies3.svg'
import usDevelopersWhite from '../images/usDevelopmentCompaniesWhite.svg'

import Footer from './Footer'

const Home = () => {
    const navigate = useNavigate()
    const listContent = [
        { color: '#000', heading: 'Builds the right foundation that scales up as your business grows.', img: scalable },
        { heading: 'Provides a free initial project evaluation and price quote.', img: freeEval },
        { heading: 'Strategizes with you to ensure an efficient development process.', img: strategize },
        { heading: <>More reliable than freelancers.<br />(No ghosting)</>, img: noGhosting },
        { heading: 'Often faster and less expensive than in-house teams.', img: cheaper },
        { heading: 'Proficient with established and emerging technologies.', img: experiencedDevelopers },
    ]
    const content = {
        title: "Why We Love Our Developers",
        props: {
            color: '#fff',
            // background: 'var(--formBackgroundAngled)',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center'
        },
        justifyContent: 'center',
        flexWrap: 'wrap',
        listItemMargin: 30,
        flexGrow: 0,
        maxWidth: "1200px",
        subTextStyling: {
            color: '#fff',
            maxWidth: '800px'
        },
        subtext:
            [

                {
                    text:
                        "When it comes time to make your vision come to life, nothing is more critical than choosing the right development service.",
                    position: 'end',
                    color: '#fff'
                },
                {
                    text:
                        "Ukraine is famous for its elite developers, but how do you find a reliable and trustworthy company you can hire without the risks that often come with offshore development?",
                    position: 'end',
                    color: '#fff'
                },
                {
                    text: <>That's where we come in.  We ask a few questions to understand your project, goals and requirements.  This detailed info allows us to match you with one company who is the best fit for you, and is also guaranteed to be top-rated on the largest developer review platform.</>,
                    position: 'end',
                    color: '#fff'


                }, {
                    text: <>Simply take less than 1 minute to tell us about your project in our "Get Started" form. The top matching team will reach out for a free evaluation.</>,
                    position: 'end',
                    color: '#fff'


                },
            ],
        listContent,
    }

    const whyChooseUs = {
        title: 'We Make Outsourcing Easy',
        props: {
            wrap: 'wrap', gap: '20px',
            justifyContent: 'center',
            color: '#ffffff'
        },
        listParagraphColor: "#fff",
        listItemMargin: 30,
        flexWrap: 'wrap',
        listClassName: 'noWrapOnMobile',
        listContent: [
            {
                heading: 'Only top rated developers',
                background: "var(--defaultButtonBackgroundHover)",
                text: 'Every single development company in our network is one of the highest rated on the #1 review site. All have 4.8+ star ratings and 20+ reviews.',
                img: betterThanReviewSitesWhite
            },
            {
                heading: 'Match based on your goals',
                text: "We match you to the one company that fits best with your tech requirements and business priorities, like budget, speed or scalability.",
                useComponent: <MatchIconAnimated arrowFill="#fff" />
            },
            {
                heading: 'More than just great reviews',
                img: screenedDevelopersWhite,
                imgProps: {
                    maxHeight: '150px',
                    left: '-17px'
                },
                text: "Reviews are not enough. All our devs must have exceptional portfolios, including beautiful interface design."
            },
            {
                heading: 'Better value than US companies',
                img: usDevelopersWhite,
                imgProps: {
                    maxHeight: '150px'
                },
                text: "Our Ukraine developers are easy to research, get great results, and deliver value that can't be matched in the US."
            },
        ],
    }
    const belowHowItWorks = {
        title: 'Tell Me More...',
        props: {
            // background: 'var(--formBackgroundAngled)', 
            // color: 'var(--formColor)', 
            wrap: 'wrap', gap: '20px',
            color: '#ffffff'
        },
        listParagraphColor: "#fff",
        listItemMargin: 30,
        flexWrap: 'wrap',
        listClassName: 'noWrapOnMobile',
        listContent: [
            { heading: 'All major platforms and devices', background: "var(--defaultButtonBackgroundHover)", text: 'We match you with a top developer for web and mobile projects, including emerging tech like AR/VR, blockchain, and machine learning.' },
            { heading: 'Experts for any tech stack', text: "We have experts for virtually all front and back-end technologies, from the most established to the up and coming.", },
            { heading: 'Beautiful and intuitive UIs', text: "Our devs specialize in creating interfaces that users love and understand intuitively, without confusion or friction." },
            { heading: 'Software built to scale up', text: "Hire developers who build software that scales. No need to start over from scratch once your project achieves success." },
        ],
    }
    return (
        <PageContentContainer
            column
            display="flex"
            // marginTop="0"
            background="var(--formBackground90)"
            position="relative"
            margin="calc(var(--navBarHeight) * -1) 0 0"
            width="100%"
            height="100%"
            justifyContent="start" alignItems="start" flexDirection="row" padding="0 0 "
        >
            <GridContainer
                width="100%"
                gridTempMobile={{ columns: '1fr', rows: 'repeat(2, min-content)' }}
                gridTemp={{ rows: 'repeat(1, min-content)', columns: '2fr 1fr' }}
                gap="20px"
                position="relative"
                background="linear-gradient(var(--darkBlue),var(--deepBlue))"
                paddingMobile="var(--navBarHeight) 5% 5%"
                color="#fff"
                padding="var(--navBarHeight) 50px 50px" style={{ textAlign: 'left' }}>
                {/* <h1 style={{margin: '0'}}>Couldn't find your freelancelot?</h1> */}
                <Flexbox column alignItems="start" justifyContent="center">


                    <h1>Ukraine software development companies combine world-class skill with unbeatable value.</h1>


                    <h3>Let's find the best matching developer for your specific needs.</h3>
                    <ButtonHome
                        width="100%"
                        maxWidth="250px"
                        maxWidthMobile="100%"
                        border="none" borderHover="none" colorHover="#fff" text="Get Started"
                        boxShadow="rgba(0, 0, 0, 0.5) 0px 0px 7px"
                        onClick={() => navigate('/form')} background="var(--formBackgroundAngled)" bgdHover="var(--formBackgroundAngled2)" />
                </Flexbox >
                <GridItem row="1 / span 1" alignSelf="center" col="2" mobileCol="1">
                    <Rocket />
                </GridItem>
            </GridContainer>
            <Flexbox column
                width="100%"
                background="linear-gradient(var(--deepBlue),var(--formBlue1))"
                position="relative" >
                <Section {...whyChooseUs} itemsPerRow={2} />
                <HowItWorks />
                <Section {...belowHowItWorks} itemsPerRow={2} />
                <Section {...content} itemsPerRow={3} />
            </Flexbox>
        </PageContentContainer>
    )
}

export default Home