import React from 'react'
import Flexbox from '../Flexbox'
import Footer from '../Footer'
import styled from 'styled-components'
import { StyledLink, RLink, MailLink } from '../PrivacyPolicy'

const DNSMI = () => {
    return (
        <Flexbox className="" column justifyContent="start" height="calc(100vh - var(--navBarHeight))" overflow="auto" alignText="left">
            <h1>Do not sell my information</h1>
            <p style={{ padding: '0 50px' }}>
                We value your privacy rights. You can obtain specific information about the types of personally identifiable information that companies with whom they have an established business relationship have shared with third parties for direct marketing purposes during the preceding calendar year. If you would like to request a list of the third parties to which we have disclosed certain personally identifiable information, please contact us at <StyledLink>{process.env.REACT_APP_PRIVACY_POLICY_EMAIL}</StyledLink>. Our data security representative will process your request.
                
                <br /><br />Please review our <RLink to="/privacy-policy">Privacy and Cookie Policy</RLink> for complete details regarding our data collection and processing, including all data we collect and/or share. <br /><br />
                
                Right to Know: You have the right to request that we disclose to you the personal information that we collect, use, disclose, and sell. To submit a verifiable consumer request to know, you may email us at the email address above with “CCPA Right to Know” in the subject line and in the body of your message. In certain circumstances, we may not be able to provide specific pieces of personal information (e.g., if such disclosure creates a substantial, articulable, and unreasonable risk to the security of such personal information or our business systems).
                <br /><br />
                Right to Request Deletion: You have the right to request that we delete any of your personal information that we collected from you and maintained, subject to certain exceptions. In certain instances where we are not required to comply with your request for deletion, we will let you know. To submit a verifiable consumer request to delete, you may e-mail us at the email address above with “CCPA Right to Delete” in the subject line and in the body of your message.
                <br /><br />
                Right to Opt Out: You have the right to opt-out of the sale of your personal information. Please see the section of our Privacy Policy titled <RLink to="/privacy-policy#selling-or-sharing-data-with-third-parties">Selling or Sharing Data with Third Parties</RLink> for further details. To submit a verifiable consumer request to opt-out, you may e-mail us at the email address above with “CCPA Right to Delete” in the subject line and in the body of your message. Once we receive your request, we will not sell your personal information, unless an exclusion applies.
                <br /><br />
                Right to Non-Discrimination: Should you exercise any of your rights under the CCPA, you have the right not to receive discriminatory treatment by us.
                <br /><br />
                We reserve the right to verify your identity before we process any of the aforementioned requests relating to your personal information. Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information.
            </p>
        </Flexbox>
    )
}

export default DNSMI