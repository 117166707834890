import styled from 'styled-components'

export const Wrapper = styled.div`
display: ${({ display }) => display};
grid-row: ${({ row }) => row};
grid-column: ${({ col }) => col};
grid-area: ${({ gridArea }) => gridArea};
justify-content: ${({ justify }) => justify};
align-items: ${({ align }) => align};
align-self: ${({ alignSelf }) => alignSelf};
justify-self: ${({ justifySelf }) => justifySelf};
justify-content: ${({ justifyContent }) => justifyContent};
width: ${({ width }) => width};
height: ${({ height }) => height};
background: ${({ background }) => background};
user-select: ${({ userSelect }) => userSelect};
left: ${props => props.left ? props.left : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
bottom: ${props => props.bottom ? props.bottom : ''};
position: ${props => props.position ? props.position : ''};
transition: ${props => props.transition ? props.transition : ''};
border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
border: ${props => props.border ? props.border : ''};
border-right: ${props => props.borderRight ? props.borderRight : ''};
border-radius: ${props => props.borderRadius ? props.borderRadius : ''};
padding: ${props => props.padding ? props.padding : ''};
gap: ${props => props.gap ? props.gap : ''};
transform: ${props => props.transform ? props.transform : ''};
opacity: ${({ opacity }) => opacity};
flex-direction: ${({ flexDirection }) => flexDirection};
scroll-snap-type: ${({ scrollSnapType }) => scrollSnapType};
scroll-snap-align: ${({ scrollSnapAlign }) => scrollSnapAlign};
color: ${({ color }) => color};
box-shadow: ${({ boxShadow }) => boxShadow};
max-height: ${({ maxHeight }) => maxHeight};
min-width: ${({ minWidth }) => minWidth};
min-height: ${({ minHeight }) => minHeight};
max-width: ${({ maxWidth }) => maxWidth};
font-size: ${({ fontSize }) => fontSize};
z-index: ${({zIndex}) => zIndex};
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            width: ${({ mobileWidth }) => mobileWidth};
            height: ${({ mobileHeight }) => mobileHeight};
            flex-basis: ${({ mobileFlexBasis }) => mobileFlexBasis};
            max-height: ${({ mobileMaxHeight }) => mobileMaxHeight};
            max-width: ${({ mobileMaxWidth }) => mobileMaxWidth};
            grid-column: ${({ mobileCol }) => mobileCol};
            grid-row: ${({ mobileRow }) => mobileRow};
            justify-content: ${({ justifyContentMobile }) => justifyContentMobile};
            display: ${({displayMobile}) => displayMobile};
            
        &:hover {
            background: ${({ background = 'unset' }) => background};
            color: ${({ color = 'inherit' }) => color};
        }
        &:hover:not(.selected) {
            background: ${({ background = 'unset' }) => background};
            color: ${({ color = 'inherit' }) => color};
        }
        }
`;