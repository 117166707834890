import React from 'react'
import Airplane from '../Airplane'
import Flexbox from '../Flexbox'

const Success = () => {
    return (
        <Flexbox column color="var(--formColor)" height="100vh" alignItems="center" justifyContent="center" margin="calc(var(--navBarHeight) * -1) 0 0" background="var(--formBackground)">
            <Airplane/>
        <h1>The team will contact you shortly!</h1>
        <p>Form submission successfully received.<br></br> </p>

    </Flexbox>
    )
}

export default Success