import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import styled from 'styled-components'
import Flexbox from './Flexbox'
import CheckmarkValidate from './CheckmarkValidate'
const PhoneInput2 = ({
    showValidationCheckmark, value,
    onCountryChange = () => { },
    setValue = () => { },
    ...props
}) => {
    // const [state, setState] = useState('')
    // console.log("state", state, isPossiblePhoneNumber(state), isValidPhoneNumber(state))
    //    console.log( getCountryCallingCode(value))
    return (
        <Flexbox width="100%">
            <PhoneInput
                international
                addInternationalOption={false}
                countryCallingCodeEditable={false}
                defaultCountry="US"
                countrySelectProps={{ unicodeFlags: true }}
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                // inputComponent={InputField}
                onCountryChange={onCountryChange}
            ></PhoneInput>
            {showValidationCheckmark && <CheckmarkValidate {...props} />}
        </Flexbox>
    )
}

export default PhoneInput2