import React from 'react'

import { CheckboxStyle } from './Checkbox.styles'

const Checkbox = ({ checked, onCheck, text }) => {
    return (
        <CheckboxStyle><input type="checkbox" name="checkbox" id="checkbox" onChange={(e) => onCheck(e)} checked={checked} /><span></span><label htmlFor="checkbox">{text}</label></CheckboxStyle>
    )
}

export default Checkbox