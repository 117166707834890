import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import firebase from 'firebase/app'
// import fire from './firebase'
// CSS
import { GlobalStyle } from './GlobalStyle';

// Components
import Checkout from './components/Checkout'

import Navigation from './components/Navigation'
import Form from './components/Form'
import Rocket from './components/Rocket'
import Error from './components/Error'
import Success from './components/Success'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import PrivacyPolicy from './components/PrivacyPolicy';
import DNSMI from './components/DNSMI';
import TermsAndConditions from './components/TermsAndConditions';
import ScrollToTop from "./components/scrollToTop";
import Airplane from './components/Airplane';
import About from './components/About'
import Helix from './components/Helix'
import Helix2 from './components/Helix2'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DynamicMetaData from './components/DynamicMetaData'

// New Home Components Go Here
import Home from './components/Home'
import Results from './components/Results'
import HomeBlockchain from './components/HomeBlockchain'
import HomeWebDevelopment from './components/HomeWebDevelopment'
import HomeMobileApp from './components/HomeMobileApp'
import HomeAI from './components/HomeAI'
import HomeAR from './components/HomeAR'
import HomeVR from './components/HomeVR'
import HomeUkraine from './components/HomeUkraine'
import HomeOffshore from './components/HomeOffshore'
import HomeGeneral from './components/HomeGeneral'
// import Firebase from './components/Firebase';
import PhoneNumber from './components/PhoneNumber';
// import HomePageName from './components/HomePageName'
function App() {
  // useEffect(()=> {
  //   console.log("firebase: ", firebase)
  // },[])
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <DynamicMetaData />
          <ScrollToTop />
          <Navigation />
          {/* <Firebase /> */}
          <Routes>
            {/* <Route path="/" exact element={< Home />} /> */}
            <Route path="/" exact element={< HomeGeneral />} />
            <Route path="/portfolios" exact element={< Helix2 />} />
            {/* 
             */}
            {/* <Route path="/results" exact element={< Results />} /> */}
            <Route path="/about" exact element={< About />} />
            <Route path="/homeOld" exact element={< Home />} />
            <Route path="/phone" exact element={< PhoneNumber />} />
            <Route path="/ukraine" exact element={< HomeUkraine />} />
            <Route path="/blockchain-development-company" exact element={< HomeBlockchain />} />
            <Route path="/mobile-app-development-company" exact element={< HomeMobileApp />} />
            <Route path="/web-development-company" exact element={< HomeWebDevelopment />} />
            <Route path="/ai-development-company" exact element={< HomeAI />} />
            <Route path="/virtual-reality-development-company" exact element={< HomeVR />} />
            <Route path="/augmented-reality-development-company" exact element={< HomeAR />} />
            {/* <Route path="/URLYOUWANTFORHOMEPAGENAME" exact element={< HomePageName />} /> */}
            <Route path="/rocket" exact element={< Rocket />} />
            <Route path="/form" exact element={< Form />} />
            <Route path="/privacy-policy" exact element={< PrivacyPolicy />} />
            <Route path="/do-not-sell-my-info" exact element={< DNSMI />} />
            <Route path="/terms" exact element={< TermsAndConditions />} />
            <Route path="/contact" exact element={< ContactForm />} />
            <Route path="/success" exact element={< Success />} />
            <Route path="/error" exact element={< Error />} />
            <Route path="/sent" exact element={<Airplane />} />
            <Route path="/checkout" exact element={<Checkout />} />
          </Routes>
          <Footer />
          <GlobalStyle />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
